import React, {Dispatch} from 'react';
import {addNewIdForChildNodes, addNodeToActiveNodeWithAutoSelect} from "../../../../service/NodeService";
import {Modal} from "bootstrap";
import {hideAddElementModal} from "../../../../service/ModalService";
import {PageNode} from "../../../../types/PageNode";
import {AnyAction} from "@reduxjs/toolkit";
import DivRowNodeFactory from "../../../../service/Node/Factory/DivRowNodeFactory";

interface StaticTagsProps {
        activeNode: PageNode,
        dispatch: Dispatch<AnyAction>
}

const StaticTags: React.FC<StaticTagsProps> = ({activeNode,dispatch}) => {
    return (
        <>
            {/*<div className=" ms-2 btn"*/}
            {/*     onClick={() => {*/}
            {/*         addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {*/}
            {/*             tagName: 'a',*/}
            {/*             className: "",*/}
            {/*             isLink: true,*/}
            {/*             editSettings: {*/}
            {/*                 contentTab: {*/}
            {/*                     textInput: true,*/}
            {/*                     destinationLink: true,*/}
            {/*                 }*/}
            {/*             }*/}
            {/*         });*/}
            {/*         Modal.getOrCreateInstance('#edit-block-modal').show();*/}
            {/*         hideAddElementModal();*/}
            {/*     }}*/}

            {/*     data-bs-target="#edit-text-modal" data-bs-toggle="modal">Link*/}
            {/*</div>*/}
            {/*<div className=" ms-2 btn"*/}
            {/*     onClick={() => {*/}
            {/*         addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {*/}
            {/*             tagName: 'div',*/}
            {/*             className: "",*/}
            {/*             editSettings: {*/}
            {/*                 isCarousel: true*/}
            {/*             }*/}
            {/*         });*/}
            {/*         Modal.getOrCreateInstance('#edit-block-modal').show();*/}
            {/*         hideAddElementModal();*/}
            {/*     }}*/}
            {/*     data-bs-target="#edit-text-modal" data-bs-toggle="modal">Carousel*/}
            {/*</div>*/}
            {/*<div className=" ms-2 btn"*/}
            {/*     onClick={() => {*/}
            {/*         addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {*/}
            {/*             tagName: 'div',*/}
            {/*             className: "",*/}
            {/*             editSettings: {*/}
            {/*                 isSocialLinks: true*/}
            {/*             }*/}
            {/*         });*/}
            {/*         Modal.getOrCreateInstance('#edit-block-modal').show();*/}
            {/*         hideAddElementModal();*/}
            {/*     }}*/}
            {/*     data-bs-target="#edit-text-modal" data-bs-toggle="modal">Social Links*/}
            {/*</div>*/}
            {/*<div className=" ms-2 btn"*/}
            {/*     onClick={() => {*/}
            {/*         addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {*/}
            {/*             tagName: 'div',*/}
            {/*             className: "",*/}
            {/*             editSettings: {*/}
            {/*                 isSocialTextLinks: true*/}
            {/*             }*/}
            {/*         });*/}
            {/*         Modal.getOrCreateInstance('#edit-block-modal').show();*/}
            {/*         hideAddElementModal();*/}
            {/*     }}*/}
            {/*     data-bs-target="#edit-text-modal" data-bs-toggle="modal">Text Links*/}
            {/*</div>*/}
            {/*<div className=" ms-2 btn"*/}
            {/*     onClick={() => {*/}
            {/*         addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {*/}
            {/*             tagName: 'a',*/}
            {/*             className: "d-block",*/}
            {/*             editSettings: {*/}
            {/*                 contentTab: {*/}
            {/*                     textInput: false,*/}
            {/*                     destinationLink: true,*/}
            {/*                 }*/}
            {/*             }*/}
            {/*         });*/}
            {/*         Modal.getOrCreateInstance('#edit-block-modal').show();*/}
            {/*         hideAddElementModal();*/}
            {/*     }}*/}

            {/*     data-bs-target="#edit-text-modal" data-bs-toggle="modal">Link block*/}
            {/*</div>*/}
            {/*<div className=" ms-2 btn"*/}
            {/*     onClick={() => {*/}
            {/*         addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {*/}
            {/*             tagName: 'i',*/}
            {/*             className: "bi"*/}
            {/*         });*/}
            {/*         Modal.getOrCreateInstance('#edit-block-modal').show();*/}
            {/*         hideAddElementModal();*/}
            {/*     }}*/}

            {/*     data-bs-target="#edit-text-modal" data-bs-toggle="modal">Icon*/}
            {/*</div>*/}
            {/*<div className=" ms-2 btn"*/}
            {/*     onClick={() => {*/}
            {/*         addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {*/}
            {/*             tagName: 'div',*/}
            {/*             className: ""*/}
            {/*         });*/}
            {/*         Modal.getOrCreateInstance('#edit-block-modal').show();*/}
            {/*         hideAddElementModal();*/}
            {/*     }}*/}

            {/*     data-bs-target="#edit-text-modal" data-bs-toggle="modal">Div*/}
            {/*</div>*/}
            <div className=" ms-2 btn"
                 onClick={() => {
                     let node: PageNode = DivRowNodeFactory.getNode(activeNode);
                     addNewIdForChildNodes(node)
                     addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, node);
                     Modal.getOrCreateInstance('#edit-block-modal').show();
                     hideAddElementModal();
                 }}

                 data-bs-target="#edit-text-modal" data-bs-toggle="modal">Div(ROW)
            </div>
            {/*<div className=" ms-2 btn"*/}
            {/*     onClick={() => {*/}
            {/*         addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {*/}
            {/*             tagName: 'span',*/}
            {/*             className: ""*/}
            {/*         });*/}
            {/*         Modal.getOrCreateInstance('#edit-block-modal').show();*/}
            {/*         hideAddElementModal();*/}
            {/*     }}*/}
            {/*     data-bs-target="#edit-text-modal" data-bs-toggle="modal">Span*/}
            {/*</div>*/}
            {/*<h5 className={'mt-3'}>Heading</h5>*/}
            {/*<div className=" ms-2 btn"*/}
            {/*     onClick={() => {*/}
            {/*         addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {*/}
            {/*             tagName: 'h1',*/}
            {/*             className: "text"*/}
            {/*         });*/}
            {/*         Modal.getOrCreateInstance('#edit-block-modal').show();*/}
            {/*         hideAddElementModal();*/}
            {/*     }}*/}
            {/*     data-bs-target="#edit-text-modal" data-bs-toggle="modal">H1*/}
            {/*</div>*/}
            {/*<div className=" ms-2 btn"*/}
            {/*     onClick={() => {*/}
            {/*         addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {*/}
            {/*             tagName: 'h2',*/}
            {/*             className: "text"*/}
            {/*         });*/}
            {/*         Modal.getOrCreateInstance('#edit-block-modal').show();*/}
            {/*         hideAddElementModal();*/}
            {/*     }}*/}
            {/*     data-bs-target="#edit-text-modal" data-bs-toggle="modal">H2*/}
            {/*</div>*/}
            {/*<div className=" ms-2 btn"*/}
            {/*     onClick={() => {*/}
            {/*         addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {*/}
            {/*             tagName: 'h3',*/}
            {/*             className: "text"*/}
            {/*         });*/}
            {/*         Modal.getOrCreateInstance('#edit-block-modal').show();*/}
            {/*         hideAddElementModal();*/}
            {/*     }}*/}
            {/*     data-bs-target="#edit-text-modal" data-bs-toggle="modal">H3*/}
            {/*</div>*/}
            {/*<div className=" ms-2 btn"*/}
            {/*     onClick={() => {*/}
            {/*         addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {*/}
            {/*             tagName: 'h4',*/}
            {/*             className: "text"*/}
            {/*         });*/}
            {/*         Modal.getOrCreateInstance('#edit-block-modal').show();*/}
            {/*         hideAddElementModal();*/}
            {/*     }}*/}
            {/*     data-bs-target="#edit-text-modal" data-bs-toggle="modal">H4*/}
            {/*</div>*/}
            {/*<div className=" ms-2 btn"*/}
            {/*     onClick={() => {*/}
            {/*         addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {*/}
            {/*             tagName: 'h5',*/}
            {/*             className: "text"*/}
            {/*         });*/}
            {/*         Modal.getOrCreateInstance('#edit-block-modal').show();*/}
            {/*         hideAddElementModal();*/}
            {/*     }}*/}
            {/*     data-bs-target="#edit-text-modal" data-bs-toggle="modal">H5*/}
            {/*</div>*/}
            {/*<div className=" ms-2 btn"*/}
            {/*     onClick={() => {*/}
            {/*         addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {*/}
            {/*             tagName: 'h6',*/}
            {/*             className: "text"*/}
            {/*         });*/}
            {/*         Modal.getOrCreateInstance('#edit-block-modal').show();*/}
            {/*         hideAddElementModal();*/}
            {/*     }}*/}
            {/*     data-bs-target="#edit-text-modal" data-bs-toggle="modal">H6*/}
            {/*</div>*/}
        </>
    );
};

export default StaticTags;