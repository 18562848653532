import React from 'react';

const StructureItem = ({id, structureColImg, nodeState, changeFormFunction}) => {
    const borderStyle = {border: '1px solid #3A83F6', background: '#F2F7FF'}
    const svgColor = {filter: 'invert(81%) sepia(7%) saturate(1094%) hue-rotate(190deg) brightness(324%) contrast(97%)'}
    let object = {target: {name: 'structure', value: id}};
    return (
        <div className={"col-6 col-sm-4 p-0"}>
            {nodeState.structure !== id ? (
                <div className={'bg-light rounded-1 text-center m-2'}
                     onClick={() => changeFormFunction(object)}>
                    <img className={'w-100 py-2 mt-3 mb-3 p-2'} src={structureColImg} alt=''/>
                </div>
            ) : (
                <div className={'bg-light rounded-1 text-center m-2'} style={borderStyle}>
                    <img className={'w-100 py-2 mt-3 mb-3 p-2'} src={structureColImg} style={svgColor} alt=''/>
                </div>)}
        </div>)
}

export default StructureItem