import React from "react";
import {changeActiveModalNode} from "../../reducers/node-modal";
import {Modal} from "bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";

export const BlockButton: React.FC = () => {
    const dispatch = useDispatch()
    const nodes = useSelector((state: RootState) => state.nodes.present.value);

    return (
        <div className='secondary-block-button d-flex justify-content-between align-items-center gap-1 px-2'
             onClick={() => dispatch(changeActiveModalNode(null))}>
            <div className="w-100"></div>
            <span className="mx-2 bg-primary p-1 rounded" role="button"
                  aria-expanded="false"
                  onClick={
                      (event) => {
                          event.stopPropagation()
                          dispatch(changeActiveModalNode(nodes[0]));
                          Modal.getOrCreateInstance('#container-to-add-modal').show();
                      }
                  }
            >
                <i className="bi bi-plus-lg text-white d-flex align-items-center"></i>
            </span>
            <div className="w-100"></div>
        </div>
    );
};