import React, {useState} from "react";
import SelectColor from "./SelectColor";
import {CustomStyleField} from "../../../types/CustomStyleField";
import EditModalSizeInput from "../ui/input/EditModalSizeInput";
import {MobileStyleRadioButton} from "../ui/MobileStyleRadioButton";
import {PageNode} from "../../../types/PageNode";
import {
    ChangeNodeFunction,
    ChangeStyleFunction,
    DeleteCustomStyleByNameFunction
} from "../../../service/editModalService";

export interface IconsSettingsProps {
    editableNode: PageNode,
    changeNodeFunction: ChangeNodeFunction,
    deleteStyleByName: DeleteCustomStyleByNameFunction,
    changeStyleFunction: ChangeStyleFunction,
    isMobileViewDimension: boolean,

}

const IconSettings: React.FC<IconsSettingsProps> = ({
                                                         editableNode,
                                                         changeNodeFunction,
                                                         deleteStyleByName,
                                                         changeStyleFunction,
                                                         isMobileViewDimension
                                                     }) => {
    const [showBlock, setShowBLock] = useState("")

    let isMobileStyleIcon: boolean = false
    if (isMobileViewDimension) {
        isMobileStyleIcon = editableNode.isMobileStyleIcon
    }

    return (<>
        <div className="d-flex">
            <h5 className={'mt-3 mb-3 me-auto'}>Icons Settings</h5>
            <div className="mt-3 mb-3">
                <MobileStyleRadioButton currentStatus={isMobileStyleIcon}
                                        onChange={(state) => {
                                            editableNode.isMobileStyleIcon = state;
                                            changeNodeFunction(editableNode)
                                        }}/>
            </div>
        </div>
        <div className="row mb-2">
            <div className="col-md-6">
                <EditModalSizeInput
                    deleteStyleByName={deleteStyleByName}
                    changeStyleFunction={changeStyleFunction}
                    editableNode={editableNode}
                    optionValue={CustomStyleField.FontSize}
                    labelName={"Icon Size"}
                    isMobileStyle={isMobileStyleIcon}
                />
            </div>
            <div className="col-md-6">
                <SelectColor editableNode={editableNode}
                             changeStyleFunction={changeStyleFunction}
                             deleteStyleByName={deleteStyleByName}
                             isMobileStyle={isMobileStyleIcon} labelName={'Icon Color'} setShowBLock={setShowBLock}
                             showBlock={showBlock} styleName={CustomStyleField.Color}/>
            </div>
        </div>
    </>)
}

export default IconSettings;