// const TMP => () => {}
import React from "react";
import {ChangeNodeFunction} from "../../../../service/editModalService";
import {PageNode} from "../../../../types/PageNode";

interface StylesProps {
    editableNode: PageNode,
    changeFunction: ChangeNodeFunction
}

const Styles: React.FC<StylesProps> = ({editableNode, changeFunction}) => {
    const convertStylesStringToObject = (stringStyles: string): object => stringStyles ? stringStyles
        .split(';')
        .reduce((acc, style) => {
            const colonPosition = style.indexOf(':')

            if (colonPosition === -1) {
                return acc
            }

            const
                camelCaseProperty = style
                    .substr(0, colonPosition)
                    .trim()
                    .replace(/^-ms-/, 'ms-')
                    .replace(/-./g, c => c.substr(1).toUpperCase()),
                value = style.substr(colonPosition + 1).trim()

            return value ? {...acc, [camelCaseProperty]: value} : acc
        }, {}) : {}

    return <>
        <div className="form-floating mb-3">
            <input id={'edit-block-modal-style-input'} onChange={(e) => {
                const updatedNode = { ...editableNode };
                updatedNode.styleString = e.target.value;
                updatedNode.style = convertStylesStringToObject(e.target.value);
                changeFunction(updatedNode)
            }} type='text' placeholder={'Set custom styles, use as separator ; symbol'}
                   value={editableNode.styleString ?? ''}
                   className={'form-control'}/>
            <label htmlFor="edit-block-modal-style-input" className="form-label text-background-mobile">CSS Styles (; -
                separator)</label>
        </div>
    </>

}

export default Styles