import {getNewNode} from "../../NodeService";
import {DivColNode, DivRowNode, NodeType, PageNode} from "../../../types/PageNode";

export default class DivColNodeFactory {
    public static getNode = (parentNode: DivRowNode) => {
        let node: DivColNode = {
            ...getNewNode(NodeType.DivColNode, parentNode, {
                "tagName": "div",
                "className": "block",
                'breadCrumbTitle': 'Container',
            }), ...{colSize: 12, colSizeMobile: 12}
        }
        return node
    }
}