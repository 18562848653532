// It's need for template nodes. Because they have similar ids on second time
import {Modal} from "bootstrap";

export function hideAddElementModal() {
    Modal.getOrCreateInstance('#element-add-modal').hide();
}

export function showAddElementModal() {
    Modal.getOrCreateInstance('#element-add-modal').show();
}

// export function AddElementModal() {
//     Modal.getOrCreateInstance('element-add-modal').show();
// }
