import React, { useEffect, useState } from 'react'
import EditModalSizeInput from "../ui/input/EditModalSizeInput";
import {
    ChangeNodeFunction,
    ChangeStyleFunction, deleteCustomStyleByName,
    DeleteCustomStyleByNameFunction
} from "../../../service/editModalService";
import ContainerSize from "./ContainerSize";
import BorderSpacing from "./BorderSpacing";
import {CustomStyleField} from "../../../types/CustomStyleField";
import Spacing from "./Spacing";
import { AlignmentContainer } from './Aligment/AlignmentContainer';
import {PageNode} from "../../../types/PageNode";
import Opacity from "./Opacity";
import {InputTypes} from "../../../types/InputTypes";
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

export interface StyleSettingsProps {
    editableNode: PageNode,
    changeNodeFunction: ChangeNodeFunction,
    changeStyleFunction: ChangeStyleFunction,
    deleteStyleByName: DeleteCustomStyleByNameFunction,
    isMobileViewDimension: boolean,
    imageSrc: string
}

// Компонент с типизированными входными параметрами
const StyleSettings: React.FC<StyleSettingsProps> = ({
                                                         editableNode,
                                                         isMobileViewDimension,
                                                         changeNodeFunction,
                                                         changeStyleFunction,
                                                         deleteStyleByName,
                                                         imageSrc,
                                                     }) => {
    const activeNode = useSelector((state: RootState) => state.nodeModal.value) as PageNode;
    const [isMixedBorderRadius, setIsMixedBorderRarius] = useState(false); 

    let isMobileStyleContainerSize: boolean = false
    if (isMobileViewDimension) {
        isMobileStyleContainerSize = editableNode.isMobileStyleContainerSize
    }

    const updateMixedValues = () => {
        const isAnyRadius = !!(activeNode?.customStyle?.borderTopLeftRadius || activeNode?.customStyle?.borderTopRightRadius || activeNode?.customStyle?.borderBottomLeftRadius || activeNode?.customStyle?.borderBottomRightRadius);
        setIsMixedBorderRarius(editableNode.isMixedBorderRadius || isAnyRadius);
        if (isMobileStyleContainerSize) {
            setIsMixedBorderRarius(activeNode.isMobileMixedBorderRadius);
        }
    }

    useEffect(() => {
        if(!activeNode) return;
        updateMixedValues();
        const modalElement = document.getElementById('edit-block-modal');
        const handleClose = () => {
            updateMixedValues();
        };
    
        modalElement.addEventListener('hide.bs.modal', handleClose);
        return () => {
          modalElement.removeEventListener('hide.bs.modal', handleClose);
        };
        // eslint-disable-next-line
    }, [activeNode]);

    const changeBorderRadiusCustomStyle = (editedNode: PageNode, isMobileStyle:boolean, isMixed:boolean): PageNode => {
        let tmpNode = {...editableNode}

        if (isMobileStyle) {
            tmpNode.isMobileMixedBorderRadius = isMixed
        } else {
            tmpNode.isMixedBorderRadius =  isMixed
        }

        if (isMixed) {
            tmpNode = deleteCustomStyleByName(CustomStyleField.BorderRadius, tmpNode, isMobileStyle)
        } else {
            tmpNode = deleteCustomStyleByName(CustomStyleField.BorderTopRightRadius, tmpNode, isMobileStyle)
            tmpNode = deleteCustomStyleByName(CustomStyleField.BorderTopLeftRadius, tmpNode, isMobileStyle)
            tmpNode = deleteCustomStyleByName(CustomStyleField.BorderBottomRightRadius, tmpNode, isMobileStyle)
            tmpNode = deleteCustomStyleByName(CustomStyleField.BorderBottomLeftRadius, tmpNode, isMobileStyle)
        }

        changeNodeFunction(tmpNode)
        return tmpNode
    }

    return(
        <>
            <ContainerSize  isMobileStyleContainerSize={isMobileStyleContainerSize} deleteStyleByName={deleteStyleByName} changeNodeFunction={changeNodeFunction}
                           changeStyleFunction={changeStyleFunction}
                            editableNode={editableNode} imageSrc={imageSrc}


            />
            {/*{(editableNode.tagName === 'img') &&*/}
            {/*    <>*/}
            {/*        <div className="row mb-3">*/}
            {/*            <div className="col-md-6 mb-3">*/}
            {/*                <EditModalSizeInput*/}
            {/*                    deleteStyleByName={deleteStyleByName}*/}
            {/*                    changeStyleFunction={changeStyleFunction}*/}
            {/*                    editableNode={editableNode}*/}
            {/*                    optionValue={CustomStyleField.BorderRadius}*/}
            {/*                    labelName={"Border Radius (px)"}*/}
            {/*                    isMobileStyle={isMobileStyleContainerSize}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <BorderSpacing isMobileStyleContainerSize={isMobileStyleContainerSize}*/}
            {/*                       changeNodeFunction={changeNodeFunction}*/}
            {/*                       editableNode={editableNode} deleteStyleByName={deleteStyleByName}*/}
            {/*                       changeStyleFunction={changeStyleFunction}*/}
            {/*                       isMobileViewDimension={isMobileViewDimension}/>*/}
            {/*    </>*/}
            {/*}*/}

            <div className="row gx-3">
                {(editableNode.tagName === 'div') &&
                    <div className="col-md-6">
                        <AlignmentContainer isMobileEditMode={isMobileStyleContainerSize}
                                            editableNode={editableNode}
                                            changeNodeFunction={changeNodeFunction}/>
                    </div>
                }
                <div className="col-md-6 mb-3">
                    <EditModalSizeInput
                        deleteStyleByName={deleteStyleByName}
                        changeStyleFunction={changeStyleFunction}
                        editableNode={editableNode}
                        optionValue={CustomStyleField.BorderRadius}
                        labelName={"Corner Radius (px)"}
                        isMobileStyle={isMobileStyleContainerSize}
                        inputType={InputTypes.Mixed}
                        isMixedStyle={isMixedBorderRadius}
                        changeCustomFunction={changeBorderRadiusCustomStyle}
                        handleChangeIsMixedStyle={setIsMixedBorderRarius}
                    />
                </div>
                {isMixedBorderRadius &&
                    <>
                        <p className="fw-bold">Corner Radius</p>
                        <div className="col-6 mb-3">
                            <EditModalSizeInput
                                deleteStyleByName={deleteStyleByName}
                                changeStyleFunction={changeStyleFunction}
                                editableNode={editableNode}
                                optionValue={CustomStyleField.BorderTopLeftRadius}
                                labelName={"Top Left"}
                                isMobileStyle={isMobileStyleContainerSize}
                            />
                        </div>
                        <div className="col-6 mb-3">
                            <EditModalSizeInput
                                deleteStyleByName={deleteStyleByName}
                                changeStyleFunction={changeStyleFunction}
                                editableNode={editableNode}
                                optionValue={CustomStyleField.BorderTopRightRadius}
                                labelName={"Top Right"}
                                isMobileStyle={isMobileStyleContainerSize}
                            />
                        </div>
                        <div className="col-6 mb-3">
                            <EditModalSizeInput
                                deleteStyleByName={deleteStyleByName}
                                changeStyleFunction={changeStyleFunction}
                                editableNode={editableNode}
                                optionValue={CustomStyleField.BorderBottomLeftRadius}
                                labelName={"Bottom Left"}
                                isMobileStyle={isMobileStyleContainerSize}
                            />
                        </div>
                        <div className="col-6 mb-3">
                            <EditModalSizeInput
                                deleteStyleByName={deleteStyleByName}
                                changeStyleFunction={changeStyleFunction}
                                editableNode={editableNode}
                                optionValue={CustomStyleField.BorderBottomRightRadius}
                                labelName={"Bottom Right"}
                                isMobileStyle={isMobileStyleContainerSize}
                            />
                        </div>
                    </>
                }
                {(editableNode.tagName === 'div') &&
                    <div className="col-md-6 mb-3">
                        <EditModalSizeInput
                            deleteStyleByName={deleteStyleByName}
                            changeStyleFunction={changeStyleFunction}
                            editableNode={editableNode}
                            optionValue={CustomStyleField.Gap}
                            labelName={"Gap (px)"}
                            isMobileStyle={isMobileStyleContainerSize}
                        />
                    </div>
                }
                <div className="col-md-6 mb-3">
                    <Opacity
                        labelName={"Opacity (%)"}
                        deleteStyleByName={deleteStyleByName}
                        changeStyleFunction={changeStyleFunction}
                        editableNode={editableNode}
                        optionValue={CustomStyleField.Opacity}
                        isMobileStyle={isMobileStyleContainerSize}/>
                </div>
            </div>


            <BorderSpacing isMobileStyleContainerSize={isMobileStyleContainerSize}
                           changeNodeFunction={changeNodeFunction}
                           editableNode={editableNode}
                           isMobileViewDimension={isMobileViewDimension}
            />
            <Spacing isMobileStyleContainerSize={isMobileStyleContainerSize} deleteStyleByName={deleteStyleByName}
                     changeNodeFunction={changeNodeFunction}
                     editableNode={editableNode} isMobileViewDimension={isMobileViewDimension}
                     changeStyleFunction={changeStyleFunction}/>
        </>
    )
}

export default StyleSettings