import React, { useEffect, useState } from "react";
import {
    ChangeNodeFunction,
    ChangeStyleFunction, deleteCustomStyleByName,
    DeleteCustomStyleByNameFunction
} from "../../../service/editModalService";
import EditModalSizeInput from "../ui/input/EditModalSizeInput";
import {CustomStyleField} from "../../../types/CustomStyleField";
import {PageNode} from "../../../types/PageNode";
import {InputTypes} from "../../../types/InputTypes";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

interface SpacingProperty {
    isMobileStyleContainerSize: boolean
    editableNode: PageNode,
    changeStyleFunction: ChangeStyleFunction,
    changeNodeFunction: ChangeNodeFunction,
    deleteStyleByName: DeleteCustomStyleByNameFunction,
    isMobileViewDimension: boolean,


}

export const Spacing: React.FC<SpacingProperty> = ({
                                                       isMobileViewDimension,
                                                       deleteStyleByName,
                                                       changeStyleFunction,
                                                       changeNodeFunction,
                                                       editableNode
                                                   }) => {
    const activeNode = useSelector((state: RootState) => state.nodeModal.value) as PageNode;
    const [isMixedMargin, setIsMixedMargin] = useState(false);
    const [isMixedPadding, setIsMixedPadding] = useState(false);
    
    let isMobileStyleContainerSize: boolean = false
    if (isMobileViewDimension) {
        isMobileStyleContainerSize = editableNode.isMobileStyleContainerSize
    }

    const changePaddingStyleFunction = (editedNode: PageNode, isMobileStyle: boolean, isMixed: boolean): PageNode => {
        let tmpNode = {...editableNode}

        if (isMobileStyle) {
            tmpNode.isMobileMixedPadding = isMixed
        } else {
            tmpNode.isMixedPadding = isMixed
        }

        if (isMixed) {
            tmpNode = deleteCustomStyleByName(CustomStyleField.Padding, tmpNode, isMobileStyle)
        } else {
            tmpNode = deleteCustomStyleByName(CustomStyleField.PaddingTop, tmpNode, isMobileStyle)
            tmpNode = deleteCustomStyleByName(CustomStyleField.PaddingBottom, tmpNode, isMobileStyle)
            tmpNode = deleteCustomStyleByName(CustomStyleField.PaddingLeft, tmpNode, isMobileStyle)
            tmpNode = deleteCustomStyleByName(CustomStyleField.PaddingRight, tmpNode, isMobileStyle)
        }

        changeNodeFunction(tmpNode)
        return tmpNode
    }
    const changeMarginStyleFunction = (editedNode: PageNode, isMobileStyle: boolean, isMixed: boolean): PageNode => {
        let tmpNode = {...editableNode}

        if (isMobileStyle) {
            tmpNode.isMobileMixedMargin = isMixed
        } else {
            tmpNode.isMixedMargin = isMixed
        }

        if (isMixed) {
            tmpNode = deleteCustomStyleByName(CustomStyleField.Margin, tmpNode, isMobileStyle)
        } else {
            tmpNode = deleteCustomStyleByName(CustomStyleField.MarginTop, tmpNode, isMobileStyle)
            tmpNode = deleteCustomStyleByName(CustomStyleField.MarginLeft, tmpNode, isMobileStyle)
            tmpNode = deleteCustomStyleByName(CustomStyleField.MarginBottom, tmpNode, isMobileStyle)
            tmpNode = deleteCustomStyleByName(CustomStyleField.MarginRight, tmpNode, isMobileStyle)
        }

        changeNodeFunction(tmpNode)
        return tmpNode
    }

    const updateMixedValues = () => {
        const isAnyMargin = !!(activeNode?.customStyle?.marginTop || activeNode?.customStyle?.marginBottom || activeNode?.customStyle?.marginLeft || activeNode?.customStyle?.marginRight);
        setIsMixedMargin(activeNode.isMixedMargin || isAnyMargin);
        if (isMobileStyleContainerSize) {
            setIsMixedMargin(activeNode.isMobileMixedMargin);
        }

        const isAnyPadding = !!(activeNode?.customStyle?.paddingTop || activeNode?.customStyle?.paddingBottom || activeNode?.customStyle?.paddingLeft || activeNode?.customStyle?.paddingRight);
        setIsMixedPadding(activeNode.isMixedPadding || isAnyPadding);
        if (isMobileStyleContainerSize) {
            setIsMixedPadding(activeNode.isMobileMixedPadding);
        }
    }

    useEffect(() => {
        if(!activeNode) return;
        updateMixedValues();
        const modalElement = document.getElementById('edit-block-modal');
        const handleClose = () => {
            updateMixedValues();
        };
    
        modalElement.addEventListener('hide.bs.modal', handleClose);
        return () => {
          modalElement.removeEventListener('hide.bs.modal', handleClose);
        };
        // eslint-disable-next-line
    }, [activeNode]);

    return (
        <div className="row gx-3 pb-3">
            <div className="col-lg-6">
                <div className="row gx-3">
                    <div className="mt-3">
                        <EditModalSizeInput
                            deleteStyleByName={deleteStyleByName}
                            changeStyleFunction={changeStyleFunction}
                            editableNode={editableNode}
                            optionValue={CustomStyleField.Padding}
                            labelName={"Padding (px)"}
                            isMobileStyle={isMobileStyleContainerSize}
                            inputType={InputTypes.Mixed}
                            isMixedStyle={isMixedPadding}
                            changeCustomFunction={changePaddingStyleFunction}
                            handleChangeIsMixedStyle={setIsMixedPadding}
                        />
                    </div>
                </div>
                {isMixedPadding &&
                    <>
                        <p className="fw-bold mt-4">Padding</p>
                        <div className="">
                            <div className="row gx-3">
                                <div className="col-6 mb-3">
                                    <EditModalSizeInput
                                        deleteStyleByName={deleteStyleByName}
                                        changeStyleFunction={changeStyleFunction}
                                        editableNode={editableNode}
                                        optionValue={CustomStyleField.PaddingTop}
                                        labelName={"Top"}
                                        isMobileStyle={isMobileStyleContainerSize}
                                    />
                                </div>
                                <div className="col-6 mb-3">
                                    <EditModalSizeInput
                                        deleteStyleByName={deleteStyleByName}
                                        changeStyleFunction={changeStyleFunction}
                                        editableNode={editableNode}
                                        optionValue={CustomStyleField.PaddingBottom}
                                        labelName={"Bottom"}
                                        isMobileStyle={isMobileStyleContainerSize}
                                    />
                                </div>
                                <div className="col-6">
                                    <EditModalSizeInput
                                        deleteStyleByName={deleteStyleByName}
                                        changeStyleFunction={changeStyleFunction}
                                        editableNode={editableNode}
                                        optionValue={CustomStyleField.PaddingLeft}
                                        labelName={"Left"}
                                        isMobileStyle={isMobileStyleContainerSize}
                                    />
                                </div>
                                <div className="col-6">
                                    <EditModalSizeInput
                                        deleteStyleByName={deleteStyleByName}
                                        changeStyleFunction={changeStyleFunction}
                                        editableNode={editableNode}
                                        optionValue={CustomStyleField.PaddingRight}
                                        labelName={"Right"}
                                        isMobileStyle={isMobileStyleContainerSize}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            <div className="col-lg-6">
                <div className="row gx-3">
                    <div className="mt-3">

                        <EditModalSizeInput
                            deleteStyleByName={deleteStyleByName}
                            changeStyleFunction={changeStyleFunction}
                            editableNode={editableNode}
                            optionValue={CustomStyleField.Margin}
                            labelName={"Margin (px)"}
                            isMobileStyle={isMobileStyleContainerSize}
                            inputType={InputTypes.Mixed}
                            isMixedStyle={isMixedMargin}
                            changeCustomFunction={changeMarginStyleFunction}
                            handleChangeIsMixedStyle={setIsMixedMargin}
                        />
                    </div>
                </div>
                {isMixedMargin &&
                    <>
                        <p className="fw-bold mt-4">Margin</p>
                        <div className="">
                            <div className="row g-3">
                                <div className="col-6">
                                    <EditModalSizeInput
                                        deleteStyleByName={deleteStyleByName}
                                        changeStyleFunction={changeStyleFunction}
                                        editableNode={editableNode}
                                        optionValue={CustomStyleField.MarginTop}
                                        labelName={"Top"}
                                        isMobileStyle={isMobileStyleContainerSize}
                                    />
                                </div>
                                <div className="col-6">
                                    <EditModalSizeInput
                                        deleteStyleByName={deleteStyleByName}
                                        changeStyleFunction={changeStyleFunction}
                                        editableNode={editableNode}
                                        optionValue={CustomStyleField.MarginBottom}
                                        labelName={"Bottom"}
                                        isMobileStyle={isMobileStyleContainerSize}
                                    />
                                </div>
                                <div className="col-6">
                                    <EditModalSizeInput
                                        deleteStyleByName={deleteStyleByName}
                                        changeStyleFunction={changeStyleFunction}
                                        editableNode={editableNode}
                                        optionValue={CustomStyleField.MarginLeft}
                                        labelName={"Left"}
                                        isMobileStyle={isMobileStyleContainerSize}
                                    />
                                </div>
                                <div className="col-6">
                                    <EditModalSizeInput
                                        deleteStyleByName={deleteStyleByName}
                                        changeStyleFunction={changeStyleFunction}
                                        editableNode={editableNode}
                                        optionValue={CustomStyleField.MarginRight}
                                        labelName={"Right"}
                                        isMobileStyle={isMobileStyleContainerSize}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                }

            </div>
        </div>
    )
}
export default Spacing

