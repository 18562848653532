import React, {useEffect, useRef, useState} from "react";
import {Editor} from "@tinymce/tinymce-react";
import {ChangeNodeFunction,} from "../../../service/editModalService";
import {MobileStyleRadioButton} from "../ui/MobileStyleRadioButton";
import {PageNode} from "../../../types/PageNode";
import {UserRole} from "../../../types/User";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";

interface TinyTextInputProperty {
    editableNode: PageNode,
    inline?: boolean,
    menubar?: boolean,
    isMobileViewDimension: boolean,
    changeNodeFunction: ChangeNodeFunction,
}

export const TinyTextInput: React.FC<TinyTextInputProperty> = ({
                                                                   editableNode,
                                                                   changeNodeFunction,
                                                                   inline = false,
                                                                   menubar = true,
                                                                   isMobileViewDimension,
                                                               }) => {
    // ЭТУ БАЛДУ НЕЛЬЗЯ РЕРЕНДЕРИТЬ ТАК КАК СБИВАЕТСЯ КУРСОР ПОСЛЕ НАЖАТИЯ ЕНТЕР!!!!
    const editorRef = useRef(null);

    const user = useSelector((state: RootState) => state.appStorage.user)
    const mainNode: PageNode = useSelector((state: RootState) => state.nodes.present.value[0]);

    const [editorKey, setEditorKey] = useState(Date.now());

    let isAdmin: boolean = false;
    if (user) {
        isAdmin = user.roles.includes(UserRole.ROLE_ADMIN)
    }

    useEffect(() => {
        setEditorKey(Date.now());
    }, [mainNode])
    
    useEffect(() => {
        document.addEventListener("focusin", function (e: FocusEvent) {
            const target = e.target as HTMLElement
            if (
                target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !==
                null
            ) {
                e.stopImmediatePropagation();
            }
        });
    }, []);

    useEffect(() => {
        const handleDocumentClick = (event: MouseEvent) => {
            const button = document.querySelector('button[title="Reveal or hide additional toolbar items"]') as HTMLButtonElement;
            if (button && !button.contains(event.target as Node) && button.getAttribute('aria-expanded') === 'true') {
                // Close popup "..." if click outside button
                button.click();
            }
        };

        document.addEventListener("click", handleDocumentClick);

        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    let useMobileText: boolean = false
    let isShowMobileTextEnabler = false
    if (isMobileViewDimension && isAdmin) {
        //TODO: tmp fix make, Common user at me qr see this.
        isShowMobileTextEnabler = true
        useMobileText = editableNode.isMobileTextEnabled ?? false
    }


    const updateValues = (saveToMobileVersion: boolean) => {
        const editorContent = editorRef.current.getContent();
        if (saveToMobileVersion) {
            editableNode.mobileValue = editorContent;
        } else {
            editableNode.value = editorContent;
        }

        changeNodeFunction(editableNode);
    };


    let value = useMobileText
        ? editableNode.mobileValue || editableNode.value
        : editableNode.value || ''
    // if (value === undefined)
    //     value = ''

    //TODO: https://www.tiny.cloud/docs/tinymce/latest/react-pm-host/
    return (
        <>
            <div className="mb-3">
                <Editor
                    key={editorKey}
                    tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                    licenseKey="gpl"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={
                        value
                    }
                    init={{
                        height: 500,
                        menubar: menubar,
                        inline: inline,
                        mobile: {
                            menubar: menubar,
                            toolbar_mode: 'sliding',  
                        },
                        // forced_root_block_attrs: {
                        //     'class': 'p-0 m-0',
                        // },
                        // selector: selector,
                        font_size_formats:
                            "8px 10px 12px 14px 16px 18px 20px 22px 24px 30px 32px 36px 40px 46px 48px 56px",
                        plugins: [
                            "fullscreen",
                            "lists",
                            "link",
                            "table",
                            "wordcount",
                        ],
                        toolbar:
                            "undo redo | formatselect | " +
                            "bold italic underline | forecolor | fontselect fontsize |  alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | link|" +
                            "removeformat | help",
                        content_style:
                            `body { font-family:${mainNode?.customStyle?.fontFamily || "Helvetica"},Arial,sans-serif; font-size:${mainNode?.customStyle?.fontSize || "20px"}; background-color: #CDD1D7; ${mainNode?.customStyle?.color ? `color: ${mainNode.customStyle.color}` : ""}`,
                        setup: (editor) => {
                            editor.on('PostRender', () => {  
                                const menubar = editor.getContainer().querySelector('.tox-menubar');
                                if (menubar) {
                                    (menubar as HTMLElement).style.flexWrap = 'nowrap';
                                    (menubar as HTMLElement).style.overflow = 'auto';

                                    const styleSheet = document.createElement('style');
                                    styleSheet.type = 'text/css';
                                    styleSheet.innerText = `
                                        .tox-menubar {
                                            -ms-overflow-style: none;
                                            scrollbar-width: none;
                                        }
                                        .tox-menubar::-webkit-scrollbar {
                                            display: none;
                                        }
                                    `;
                                    document.head.appendChild(styleSheet);
                                }
                            });
                        },
                    }}
                    onBlur={() => {
                        updateValues(useMobileText);
                    }}
                />
            </div>
            <div className={'d-flex'}>

                {isShowMobileTextEnabler ? <><h5 className={'mb-3 me-auto'}>Save to mobile version</h5>
                    <MobileStyleRadioButton currentStatus={editableNode.isMobileTextEnabled ?? false}
                                            onChange={(state) => {
                                                editableNode.isMobileTextEnabled = state;
                                                changeNodeFunction(editableNode)
                                                updateValues(editableNode.isMobileTextEnabled)
                                            }}/></> : ""}

            </div>
        </>
    );
};

export default TinyTextInput;
