/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const UpdatePageScreeShotDocument = gql`
    mutation updatePageScreeShot($id: Int, $image: String) {
  updatePage(id: $id, image: $image) {
    image
  }
}
    `;
export type UpdatePageScreeShotMutationFn = Apollo.MutationFunction<UpdatePageScreeShotMutation, UpdatePageScreeShotMutationVariables>;

/**
 * __useUpdatePageScreeShotMutation__
 *
 * To run a mutation, you first call `useUpdatePageScreeShotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageScreeShotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageScreeShotMutation, { data, loading, error }] = useUpdatePageScreeShotMutation({
 *   variables: {
 *      id: // value for 'id'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUpdatePageScreeShotMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePageScreeShotMutation, UpdatePageScreeShotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePageScreeShotMutation, UpdatePageScreeShotMutationVariables>(UpdatePageScreeShotDocument, options);
      }
export type UpdatePageScreeShotMutationHookResult = ReturnType<typeof useUpdatePageScreeShotMutation>;
export type UpdatePageScreeShotMutationResult = Apollo.MutationResult<UpdatePageScreeShotMutation>;
export type UpdatePageScreeShotMutationOptions = Apollo.BaseMutationOptions<UpdatePageScreeShotMutation, UpdatePageScreeShotMutationVariables>;
export type UpdatePageScreeShotMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  image?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type UpdatePageScreeShotMutation = { __typename?: 'Mutation', updatePage: { __typename?: 'GraphPage', image?: string | null } };
