import React from 'react';
import {ChangeStyleFunction, DeleteCustomStyleByNameFunction} from "../../../service/editModalService";
import {PageNode} from "../../../types/PageNode";

interface ImgFillProps {
    editableNode: PageNode,
    isMobileStyle: boolean,
    changeStyleFunction: ChangeStyleFunction,
    deleteStyleByName: DeleteCustomStyleByNameFunction,
    labelName: string,
    styleName: string
}

const ImgFill: React.FC<ImgFillProps> = ({
                                             changeStyleFunction,
                                             editableNode, isMobileStyle,
                                             deleteStyleByName,
                                             labelName,
                                             styleName
                                         }) => {

    let nodeCustomStyle: CustomStyle | undefined;
    if (editableNode) {
        if (isMobileStyle) {
            nodeCustomStyle = editableNode.customStyleMobile
        } else {
            nodeCustomStyle = editableNode.customStyle
        }
    }

    const handleChange = (e: any): void => {
        // if (!isCarousel) {
        if (e.target.value === "Fill / (Fit, Crop)" || e.target.value === "") {
            deleteStyleByName(e.target.name, isMobileStyle)
        } else {
            changeStyleFunction(e.target.name, e.target.value, isMobileStyle)
        }
        // } else {
        //     if(e.target.value === "Fill / (Fit, Crop)" || e.target.value === ""){
        //         deleteFunction(e, id)
        //     } else {
        //         changeFormStyleFunction(e, id)
        //     }
        // }
    }


    return (
        <select className="form-select border-border-gray py-3" name={styleName}
                onChange={handleChange} value={nodeCustomStyle?.[styleName] ? nodeCustomStyle?.[styleName] : ''}>
            <option value="">{labelName}</option>
            <option value={"cover"}>Cover</option>
            <option value={"contain"}>Contain</option>
            <option value={"auto"}>Auto</option>
        </select>
    )
}

export default ImgFill;
