import React, {useState} from 'react'

import {ChangeNodeFunction, DeleteCustomStyleByNameFunction} from "../../../../../service/editModalService";
import {handleDeleteImg, truncateString} from "../../../../../service/imageUploadService";
import {ImageNode} from "../../../../../types/PageNode";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../store";
import FileUploadComponent from '../../../../FileUpload/FileUploadComponent';
import {Modal} from "bootstrap";
import { FileManagerFile } from '../../../../../service/FileManager/FileManagerFile';

interface ImageUploadButtonProps {
    addNodeImage: (
        editableNode: ImageNode,
        changeFunction: any,
        isMobileStyle: boolean,
        fileManager: FileManagerFile,
    ) => void,
    changeFunctionImage: any,
    editableNode: ImageNode,
    con: string | undefined,
    deleteStyleByName: DeleteCustomStyleByNameFunction,
    changeNodeFunction: ChangeNodeFunction,
    isMobileStyle: boolean,
    isMobileViewDimension: boolean
}

const ImageUploadButton: React.FC<ImageUploadButtonProps> = ({
                                                                 addNodeImage, changeFunctionImage, editableNode,
                                                                 con,
                                                                 deleteStyleByName,
                                                                 changeNodeFunction,
                                                                 isMobileStyle,
                                                                 isMobileViewDimension

                                                             }) => {

    const [showImg] = useState(true)
    const [showFileManager, setShowFileManager] = useState(false)
    const style = {border: '1px solid #16B054', background: '#E5FAED', padding: "9px 12px", height: "58px"}
    const isAdvancedMode = useSelector((state: RootState) => state.appStorage.isAdvancedMode)

    let imageSrc
    if (isMobileStyle) {
        imageSrc = editableNode.srcMobile
    } else {
        imageSrc = editableNode.src
    }

    return (
        <>
            {showFileManager && <FileUploadComponent onClose={() => {
                editableNode.id === "1" ? Modal.getOrCreateInstance('#global-settings-modal').show() : Modal.getOrCreateInstance('#edit-block-modal').show()
                setShowFileManager(false)
            }} onFileChoose={(fileManager) => addNodeImage(editableNode, changeFunctionImage, isMobileStyle, fileManager)} allowedFileTypes={[".jpg", ".png", ".pdf", ".svg"]}/>}
            {con ?
                <>
                    <div onClick={() => {
                         setShowFileManager(true)
                         Modal.getOrCreateInstance('#edit-block-modal').hide()
                         Modal.getOrCreateInstance('#global-settings-modal').hide()
                    }}
                         style={style} className={`${isAdvancedMode ? 'mb-3' : ''} btn btn-outline-primary w-100`}>
                        <div className='d-flex align-items-center justify-content-between h-100'>
                            <div className='col-9 d-flex align-items-center h-100'>
                                {showImg ? (
                                    <div
                                        className='d-flex align-items-center justify-content-center h-100 overflow-hidden rounded-1'
                                        style={{width: '37px'}}>
                                        <img className='h-100' src={imageSrc} alt=''/>
                                    </div>
                                ) : (
                                    <></>
                                )}

                                {
                                    <div className={`text-start ps-2 lh-sm`}>
                                        <p className='mb-0 text-dark'>
                                            {isMobileStyle
                                                ? editableNode.srcNameMobile ? truncateString(
                                                    editableNode.srcNameMobile,
                                                    isMobileViewDimension ? 12 : 16
                                                ) : "File Name"

                                                : editableNode.srcName ? truncateString(
                                                    editableNode.srcName,
                                                    isMobileViewDimension ? 12 : 16
                                                ) : "File Name"
                                            }
                                        </p>

                                        <p className='mb-0 text-secondary fs-7'><small>
                                            {isMobileStyle
                                                ? editableNode.srcSizeMobile ? editableNode.srcSizeMobile + " MB" : ""
                                                : editableNode.srcSize ? editableNode.srcSize + " MB" : ""
                                            }
                                        </small>
                                        </p>
                                    </div>
                                }
                            </div>
                            <div className='d-flex align-items-center'>
                                {/*<div className="pe-4" onClick={(e) => handleEyeClick(e, setShowImg)}><i*/}
                                {/*    className={`bi bi-${showImg ? 'eye' : 'eye-slash'} text-dark fs-4`}></i></div>*/}
                                <div onClick={(e) => {
                                    handleDeleteImg(e, editableNode, deleteStyleByName, changeNodeFunction, isMobileStyle);
                                }}><i className="bi bi-x-circle text-dark"></i></div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                <div className={'btn btn-outline-primary bg-btn-img-color text-primary w-100 p-3 mb-1'}
                     onClick={() => {
                        setShowFileManager(true)
                        Modal.getOrCreateInstance('#edit-block-modal').hide()
                     }}>
                    <i className='bi bi-download me-2'></i>Upload Image</div>
                <p className={`${isAdvancedMode ? '' : 'mb-0'} text-secondary fs-7`}>Support file formats: PNG, JPEG.</p>
                </>
            }

        </>
    )
}

export default ImageUploadButton