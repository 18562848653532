import React, {useEffect, useState} from "react";
import {ChangeNodeFunction} from "../../../../service/editModalService";
import {PageNode} from "../../../../types/PageNode";

interface IncomeProperties {
    changeNodeFunction: ChangeNodeFunction,
    editableNode: PageNode,
}

const AdminSettingsComponent: React.FC<IncomeProperties> = ({changeNodeFunction, editableNode}) => {

    // let adminSettings: AdminSettings = editableNode.adminSettings ? {...editableNode.adminSettings} :
    //     {
    //         isElementAllowedToAdd: false,
    //         elementAllowedToAddId: undefined,
    //         elementPreviewImage: undefined
    //     }

    const [isHidden, setIsHidden] = useState(false)
    useEffect(() => {
        if (editableNode.isHidden === undefined || editableNode.isHidden === false) {
            setIsHidden(false)
        } else if (editableNode.isHidden === true) {
            setIsHidden(true)
        }
    }, [editableNode.isHidden])

    const handleSwitchChange = (event: any, variableName: string, defaultValue: any) => {
        const {checked} = event.target;
        if (!checked) {
            setIsHidden(true);
            changeNodeFunction({...editableNode, [variableName]: true})
        } else {
            setIsHidden(false)
            changeNodeFunction({...editableNode, [variableName]: defaultValue})
        }
    }

    return (<div>
            <h5 className="mt-4 mb-3">Additional Settings</h5>
            <div className="form-check form-switch my-3 ">
                <input className="form-check-input"
                       type="checkbox"
                       role="switch"
                       id="flexIsHiddenSwitch"
                       checked={!isHidden}
                       onChange={event => handleSwitchChange(event, 'isHidden', false)}
                />
                <label className="form-check-label" htmlFor="flexIsHiddenSwitch">Normal View</label>
            </div>
            {/*<div className="form-check form-switch my-3 ">*/}
            {/*    <input className="form-check-input"*/}
            {/*           type="checkbox"*/}
            {/*           role="switch"*/}
            {/*           id="isElementAllowedToAdd"*/}
            {/*           checked={adminSettings.isElementAllowedToAdd}*/}
            {/*           onChange={() => changeElementAllowedToAdd()}*/}
            {/*    />*/}
            {/*    <label className="form-check-label" htmlFor="isElementAllowedToAdd">Add element to favorite</label>*/}
            {/*</div>*/}
        </div>);
};

export default AdminSettingsComponent