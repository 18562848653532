import React from 'react';
import {PageNode} from "../../../types/PageNode";

interface TextBlockProperty {
    nodeToShow: PageNode,
    styleObject: any,
    handleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    isMobileViewDimension: boolean,
    className: string,
    isPreviewState: boolean,
    handleDoubleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const TextBlock: React.FC<TextBlockProperty> = ({
                                                           nodeToShow,
                                                           styleObject,
                                                           handleClick,
                                                           className,
                                                           handleDoubleClick,
                                                           isPreviewState
                                                       }) => {
    let mobileValue: string
    let pcValue: string
    if (isPreviewState) {
        mobileValue = nodeToShow.mobileValue ?? ''
        pcValue = nodeToShow.value ?? ''
    } else {
        mobileValue = nodeToShow.mobileValue ?? 'Add your custom text'
        pcValue = nodeToShow.value ?? 'Add your custom text'
    }


    return (<>
        {nodeToShow.isMobileTextEnabled ?
            <>
                <div
                    onClick={handleClick}
                    onDoubleClick={handleDoubleClick}
                    id={'blockContent' + nodeToShow.id}
                    className={`${className} d-block d-sm-none mobile-text`}
                    style={styleObject}
                    dangerouslySetInnerHTML={{__html: mobileValue}}
                ></div>
                <div
                    onClick={handleClick}
                    onDoubleClick={handleDoubleClick}
                    id={'blockContent' + nodeToShow.id}
                    className={`${className} d-none d-sm-block desktop-text`}
                    style={styleObject}
                    dangerouslySetInnerHTML={{__html: pcValue}}
                ></div>
            </>
            : <div
                onClick={handleClick}
                onDoubleClick={handleDoubleClick}
                id={'blockContent' + nodeToShow.id}
                className={`${className} d-block`}
                style={styleObject}
                dangerouslySetInnerHTML={{__html: pcValue}}
            ></div>
        }

    </>);
};

export default TextBlock;
