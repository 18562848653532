import React from 'react';
import ContainerSize from "./ContainerSize";
import BorderSpacing from "./BorderSpacing";
import {
    ChangeNodeFunction,
    ChangeStyleFunction,
    DeleteCustomStyleByNameFunction
} from "../../../service/editModalService";
import EditModalSizeInput from "../ui/input/EditModalSizeInput";
import {CustomStyleField} from "../../../types/CustomStyleField";
import Spacing from "./Spacing";
import {ButtonNode} from "../../../types/PageNode";

export interface UrlSettingsProps {
    isMobileViewDimension: boolean
    editableNode: ButtonNode,
    changeStyleFunction: ChangeStyleFunction,
    changeNodeFunction: ChangeNodeFunction,
    deleteStyleByName: DeleteCustomStyleByNameFunction
    changeFormStyleFunction: (event: React.ChangeEvent<HTMLSelectElement>) => void,
    imageSrc: string
}

// Компонент с типизированными входными параметрами
const UrlSettings: React.FC<UrlSettingsProps> = ({
                                                     editableNode,
                                                     isMobileViewDimension,
                                                     changeNodeFunction,
                                                     changeStyleFunction,
                                                     deleteStyleByName,
                                                     imageSrc
                                                 }) => {
    let isMobileStyleContainerSize: boolean = false
    if (isMobileViewDimension) {
        isMobileStyleContainerSize = editableNode.isMobileStyleContainerSize
    }

    return (
        <div>
            <ContainerSize isMobileStyleContainerSize={isMobileStyleContainerSize}
                           deleteStyleByName={deleteStyleByName}
                           changeNodeFunction={changeNodeFunction}
                           changeStyleFunction={changeStyleFunction}
                           editableNode={editableNode}
                           imageSrc={imageSrc}
            />
            <div className="row">
                {/*<div className="col-md-6 mb-3">*/}
                {/*TODO */}
                {/*    <Alignment changeFormBackClassesFunction={changeFormBackClassesFunction}*/}
                {/*               backClassesIndexed={backClassesIndexed}*/}
                {/*               deleteClassElementFunction={deleteClassElementFunction}/>*/}
                {/*</div>*/}
                {/*<div className="col-md-6 mb-3">*/}
                {/*    <InputWidthPostfix optionValue={"border"} changeFormStyleFunction={changeFormStyleFunction}*/}
                {/*                       labelName={"Border (px)"} nodeCustomStyle={nodeCustomStyle} deleteFunction={deleteStyleElementFunction}/>*/}
                {/*</div>*/}
                <div className="col-md-6 mb-3">
                    <EditModalSizeInput
                        deleteStyleByName={deleteStyleByName}
                        changeStyleFunction={changeStyleFunction}
                        editableNode={editableNode}
                        optionValue={CustomStyleField.BorderRadius}
                        labelName={"Border Radius (px)"}
                        isMobileStyle={isMobileStyleContainerSize}
                    />
                </div>
            </div>
            <BorderSpacing isMobileStyleContainerSize={isMobileStyleContainerSize}
                           changeNodeFunction={changeNodeFunction}
                           editableNode={editableNode}
                           isMobileViewDimension={isMobileViewDimension}
            />
            <Spacing isMobileStyleContainerSize={isMobileStyleContainerSize}
                     changeNodeFunction={changeNodeFunction}

                     editableNode={editableNode} deleteStyleByName={deleteStyleByName}
                     isMobileViewDimension={isMobileViewDimension} changeStyleFunction={changeStyleFunction}/>
            {/*TODO*/}
            {/*<TextSettings backClassesIndexed={backClassesIndexed}*/}
            {/*              changeFormBackClassesFunction={changeFormBackClassesFunction}*/}
            {/*              deleteClassElementFunction={deleteClassElementFunction}*/}
            {/*              nodeCustomStyle={nodeCustomStyle}*/}
            {/*              deleteStyleElementFunction={deleteStyleElementFunction}*/}
            {/*              changeFormStyleFunction={changeFormStyleFunction}/>*/}
        </div>
    )
}

export default UrlSettings;