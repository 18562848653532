import {ServiceInstance} from "./ServiceContainer";

export default class QueryService extends ServiceInstance {
    isDevMode: string;
    token: string;

    constructor() {
        super();
        const queryString = window.location.search;
        const urlSearchParams = new URLSearchParams(queryString);
        this.isDevMode = urlSearchParams.get('dev')
        this.token = urlSearchParams.get('token')
    }
}