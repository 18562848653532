import React from "react";
import {PageNode} from "../../../../types/PageNode";
import {ChangeNodeFunction} from "../../../../service/editModalService";

interface ClassesProps {
    editableNode: PageNode,
    changeFunction: ChangeNodeFunction
}

const Classes: React.FC<ClassesProps> = ({editableNode, changeFunction}: ClassesProps) => {
    return <>
        <div className="form-floating mb-3">
            <input id={'edit-block-modal-classes-input'} onChange={(e) => {
                const tmpNode = {...editableNode}
                tmpNode.classes = e.target.value.split(' ');
                changeFunction(tmpNode);
            }} type='text' placeholder={'Set classes'} value={editableNode.classes ? editableNode.classes.join(' ') : ''}
                   className={'form-control'}/>
            <label htmlFor="edit-block-modal-classes-input"
                   className="form-label text-background-mobile">Classes</label>
        </div>
    </>
}

export default Classes