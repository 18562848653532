import {PageNode} from "../../../types/PageNode";
import React from "react";
import {OnClickActionType} from "../../../types/OnClickAction";

interface IncomeProperties {
    children: React.ReactNode;
    nodeToShow: PageNode
}

const OnClickActionWrapper: React.FC<IncomeProperties> = ({nodeToShow, children}) => {
    const getHref = (type: OnClickActionType, value: string): string => {
        switch (type) {
            case OnClickActionType.OpenLink:
                return value;
            case OnClickActionType.WriteEmail:
                return `mailto:${value}`;
            case OnClickActionType.CallPhone:
                return `tel:${value}`;
            case OnClickActionType.OpenFile:
                return value;
            case OnClickActionType.SaveVcard:
                return value;
            default:
                return '';
        }
    };

    if (nodeToShow.onClickAction && nodeToShow.onClickAction.type !== undefined && nodeToShow.onClickAction.value !== '') {
        return <a style={{display: 'contents'}} className=' text-decoration-none text-reset'
                  href={getHref(nodeToShow.onClickAction.type, nodeToShow.onClickAction.value)}
                  download={nodeToShow.onClickAction.type === OnClickActionType.SaveVcard ? "vcardFile.vcf" : undefined}
                  >
            {children}</a>
    } else {
        return <>{children}</>
    }
}
export default OnClickActionWrapper;