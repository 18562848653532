import React, {useEffect} from 'react';
import {CustomStyleField} from "../../../types/CustomStyleField";
import {AlignmentInput} from "./Aligment/AlignmentInput";
import {
    ChangeStyleFunction,
    DeleteCustomStyleByNameFunction
} from "../../../service/editModalService";
import {ImageNode} from "../../../types/PageNode";
import {AlignTypes} from "./Aligment/AlignTypes";

interface ImgSettingsProps {
    changeStyleFunction: ChangeStyleFunction,
    isMobileStyleImage: boolean,
    deleteStyleByName: DeleteCustomStyleByNameFunction,
    id: string | undefined,
    editableNode: ImageNode,
}

const ImgSettings: React.FC<ImgSettingsProps> = ({
                                                     changeStyleFunction,
                                                     isMobileStyleImage,
                                                     deleteStyleByName,
                                                     id,
                                                     editableNode,
                                                 }) => {
    let nodeCustomStyle: CustomStyle = editableNode.customStyle
    if (isMobileStyleImage) {
        nodeCustomStyle = editableNode.customStyleMobile
    }

    const parseRotation = (transformString: string): number => {
        if (!transformString) return 0;
        const match = transformString.match(/rotate\((\d+)deg\)/);
        return match ? parseInt(match[1], 10) : 0;
    };

    const [rotate, setRotate] = React.useState( nodeCustomStyle ? parseRotation(nodeCustomStyle[CustomStyleField.Transform]) : 0);
    useEffect(() => {
        if (rotate !== 0) {
            handleClickRotate(CustomStyleField.Transform, `rotate(${rotate}deg)`);
        }
    }, [rotate]);

    const handleClickRotate = (name: string, value: string) => {
        if (rotate > 360) {
            deleteStyleByName(name, isMobileStyleImage)
            changeStyleFunction(name, value, isMobileStyleImage);
        } else {
            changeStyleFunction(name, value, isMobileStyleImage);
        }
    }


    return (
        <>
            <div className={'border border-1 rounded  border-border-gray h-100 py-2'}>
                <div className="d-flex justify-content-evenly h-100" role="group"
                     aria-label="Basic radio toggle button group">
                    <div>
                        <button className="btn border-0 active-blue-button"
                                onClick={() => setRotate(prev => prev + 90)}>
                            <i className="bi bi-arrow-clockwise"></i>
                        </button>
                    </div>
                    <div className="vr"></div>
                    <div className='d-flex justify-content-evenly align-items-center'>
                        <AlignmentInput value={'scaleX(-1)'} name={CustomStyleField.Transform} type={AlignTypes.Style}
                                        iconValue={'bi bi-symmetry-vertical'} htmlFor={'transform-scaleY'}
                                        id={id} changeByValueFunction={changeStyleFunction}
                                        deleteFunction={deleteStyleByName}
                                        editableNode={editableNode}
                                        isMobileEditMode={isMobileStyleImage}/>
                        <AlignmentInput value={'scaleY(-1)'} name={CustomStyleField.Transform} type={AlignTypes.Style}
                                        iconValue={'bi bi-symmetry-horizontal'} htmlFor={'transform-scaleX'}
                                        id={id} changeByValueFunction={changeStyleFunction}
                                        deleteFunction={deleteStyleByName}
                                        editableNode={editableNode}
                                        isMobileEditMode={isMobileStyleImage}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ImgSettings;
