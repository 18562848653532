import React, {ChangeEvent, useState, useEffect} from 'react';
import IconItem from "./IconItem";
import {iconOptions} from "../../../data/iconOptions";
import ImageUploadButton from "../../context-menu/block/modules/image-upload/ImageUploadButton";
import {addNodeImage} from "../../../service/imageUploadService";
import {ImageNode, PageNode} from "../../../types/PageNode";
import {
    ChangeNodeFunction,
    DeleteCustomStyleByNameFunction,
    ChangeStyleFunction
} from "../../../service/editModalService";
import ColorPickerModule from "./ColorPickerModule";
import ImgSettings from "./ImgSettings";

interface IconsCreateProps {
    editableNode: ImageNode,
    changeNodeFunction: ChangeNodeFunction,
    deleteStyleByName: DeleteCustomStyleByNameFunction,
    isMobileViewDimension: boolean,
    changeStyleFunction: ChangeStyleFunction,
}

const IconsCreate: React.FC<IconsCreateProps> = ({
                                                     editableNode,
                                                     changeNodeFunction,
                                                     deleteStyleByName,
                                                     isMobileViewDimension,
                                                     changeStyleFunction,
                                                 }) => {

    const [selectedIcon, setSelectedIcon] = useState<string>(editableNode.selectedIcon || '');
    const [iconsState, setIconsState] = useState(iconOptions);
    const [searchTerm, setSearchTerm] = useState<string>('');

    const handleSelectedIconChange = (editedNode: ImageNode, icon: string) => {
        setSelectedIcon(icon);
        editedNode.src = null;
        editedNode.selectedIcon = icon;
        changeNodeFunction(editedNode);
        updateIconsState(icon);
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const iconWord = event.target.value;
        setSearchTerm(iconWord);
    }

    const updateIconsState = (icon: string) => {
        const updatedIcons = iconOptions.filter(el => el !== icon);
        setIconsState([icon, ...updatedIcons]);
    }

    const changeImageAction = (editedNode: PageNode) => {
        editedNode.selectedIcon = null;
        changeNodeFunction(editedNode);
        setSelectedIcon('');
    }


    useEffect(() => {
        if (editableNode.selectedIcon) {
            setSelectedIcon(editableNode.selectedIcon);
            updateIconsState(editableNode.selectedIcon);
        } else {
            setSelectedIcon('');
        }
    }, [editableNode.selectedIcon]);

    const isMobileStyleImage = isMobileViewDimension ? editableNode.isMobileStyleImage : false;

    const filteredIcons = iconsState.filter(icon => icon.includes(searchTerm));

    return (
        <>
            <p className='fw-bold fs-5'>Upload Icon</p>
            <div className="row mb-4">
                <div className="col-md-6">
                    <ImageUploadButton
                        addNodeImage={addNodeImage}
                        editableNode={editableNode}
                        changeFunctionImage={changeImageAction}
                        con={editableNode.src}
                        changeNodeFunction={changeNodeFunction}
                        deleteStyleByName={deleteStyleByName}
                        isMobileStyle={false}
                        isMobileViewDimension={isMobileViewDimension}
                    />
                </div>

                <div className="col-md-6">
                    <ColorPickerModule
                        changeNodeFunction={changeNodeFunction}
                        editableNode={editableNode}
                        isMobileStyle={isMobileStyleImage}
                    />
                </div>

                <div className="col-md-3">
                    <ImgSettings
                        changeStyleFunction={changeStyleFunction}
                        deleteStyleByName={deleteStyleByName}
                        editableNode={editableNode}
                        isMobileStyleImage={isMobileStyleImage}
                        id={undefined}
                    />
                </div>
            </div>

            <div className='mb-3'>
                <p className='fw-bold fs-5'>Select Icon</p>
                <div className="mb-4">
                    <input
                        type="text"
                        className="form-control py-3"
                        placeholder="Search"
                        onChange={handleInputChange}
                    />
                </div>

                <div className="container-scroll">
                    <div className="row g-3">
                        {filteredIcons.map((icon: string, index: number) => (
                            <div className='col-lg-1 col-3' key={`${icon}-${index}`}>
                                <IconItem
                                    icon={icon}
                                    selectedIcon={selectedIcon || ''}
                                    editableNode={editableNode}
                                    handleSelectedIconChange={handleSelectedIconChange}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default IconsCreate;
