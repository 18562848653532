import {createSlice} from "@reduxjs/toolkit";
import {postData} from "../service/post";
// import store from "../store";

let pathArray = window.location.pathname.split('/');
const pageId = pathArray[1];
export const nodes = createSlice({
    name: 'nodes',
    initialState: {
        value: [

        ]
    },
    reducers: {
        addNode: (state, action) => {
            let element = findNode(state.value[0], action.payload.activeNode.id);
            // let element = state.value.findLast((element) => element.id === action.payload.activeNode.id);
            let newNode = action.payload.newNode;
            newNode.id ??= (Date.now()).toString();
            newNode.classes ??= [];
            newNode.nodes ??= [];
            newNode.parentId = action.payload.activeNode.id;
            // newNode.classes ??= ['border-1', 'border-dashed p-4'];
            element.nodes.push(action.payload.newNode);
            // state.value = [{tagName: 'div'}];
            postData(process.env.REACT_APP_BACKEND_ADDRESS + '/page/save/' + pageId, state.value);
        },
        changeNode: (state, action) => {
            changeNodeByNode(state.value[0], action.payload.editedNode);
            postData(process.env.REACT_APP_BACKEND_ADDRESS + '/page/save/' + pageId, state.value);
        },
        changeNodeNoSendRequest: (state, action, sendRequest = true) => {
            const result=changeNodeByNode(state.value[0], action.payload.editedNode);
            if(!result)
            {
                console.log(action.payload.editedNode)
            }
        },
        deleteNode: (state, action) => {
            deleteNodeByNode(state.value[0], action.payload.removeNode)
            postData(process.env.REACT_APP_BACKEND_ADDRESS + '/page/save/' + pageId, state.value);
        },
        moveForward: (state, action) => {
            moveNodeForward(state.value[0], action.payload.node)
            postData(process.env.REACT_APP_BACKEND_ADDRESS + '/page/save/' + pageId, state.value);
        }, moveBack: (state, action) => {
            moveNodeBack(state.value[0], action.payload.node)
            postData(process.env.REACT_APP_BACKEND_ADDRESS + '/page/save/' + pageId, state.value);
        },
        setNodes: (state, action) => {
            state.value = action.payload.nodes;
        },
        updateRootNode: (state, action) => {
            state.value[0] = action.payload.newRootNode;
            postData(process.env.REACT_APP_BACKEND_ADDRESS + '/page/save/' + pageId, state.value);
        },
        changeNodeHidden: (state, action) => {
            const node = findNode(state.value[0], action.payload.id);
            if (node) {
                if (action.payload.type === 'hidden') {
                    node.hidden = action.payload.hidden;
                } else if (action.payload.type === 'hiddenMobile') {
                    node.hiddenMobile = action.payload.hiddenMobile;
                }
                postData(process.env.REACT_APP_BACKEND_ADDRESS + '/page/save/' + pageId, state.value);
            }
        },
    }
})

/**
 *
 * @param node
 * @param id
 */
export function findNode(node, id) {
    if (node.id === id) {
        return node;
    } else if (node.nodes) {
        let foreachResult = null;
        node.nodes.forEach(function (element) {
            if (element) {
                let tmpElement = findNode(element, id);
                if (tmpElement) {
                    foreachResult = tmpElement;
                    return null;
                }
            }
        });
        return foreachResult;
    }
    return null;
}

/**
 *
 * @param node
 * @param editedNode
 */
function changeNodeByNode(node, editedNode) {
    if (node.id === editedNode.id) {
        Object.assign(node, editedNode);
        return true;
    }
    if (node.nodes.length > 0) {
        let searchIndex = false;
        node.nodes.forEach(function (element, index) {
            if (element) {
                if (element.id === editedNode.id) {
                    searchIndex = index;
                    return null;
                }
            }
        });
        if (searchIndex !== false) {
            node.nodes[searchIndex] = editedNode;
            return true;
        } else {
            node.nodes.forEach(function (element) {
                if (element) {
                    if (changeNodeByNode(element, editedNode)) {
                        return true;
                    }
                }
            });
        }
        //TODO: make it depends to foreach result
        return false;
    }
}

/**
 *
 * @param node
 * @param removeNode
 */
function deleteNodeByNode(node, removeNode) {
    if (node.nodes.length > 0) {
        let searchIndex = -1;
        node.nodes.forEach(function (element, index) {
            if (element && element.id === removeNode.id) {
                searchIndex = index;
                return null;
            }
        });
        if (searchIndex > -1) {
            node.nodes.splice(searchIndex, 1);
            return true;
        } else {
            node.nodes.forEach(function (element) {
                if (element !== null) {
                    if (deleteNodeByNode(element, removeNode))
                        return true;
                }
            });
        }
        //TODO: make it depends to foreach result
        return false;
    }
}

/**
 *
 * @param node
 * @param nodeToAction
 */
function moveNodeForward(node, nodeToAction) {
    if (node.nodes.length > 0) {
        let searchIndex = -1;
        node.nodes.forEach(function (element, index) {
            if (element !== null && element.id === nodeToAction.id) {
                searchIndex = index;
                return null;
            }
        });
        if (searchIndex > -1 && node.nodes[searchIndex + 1] !== undefined) {
            let tmp = node.nodes[searchIndex];
            node.nodes[searchIndex] = node.nodes[searchIndex + 1];
            node.nodes[searchIndex + 1] = tmp;
            return true;
        } else if (searchIndex === node.nodes.length - 1) {
            let tmp = node.nodes[searchIndex];
            node.nodes.splice(searchIndex, 1);
            node.nodes.unshift(tmp);
            return true;
        } else {
            node.nodes.forEach(function (element) {
                if (element !== null) {
                    if (moveNodeForward(element, nodeToAction))
                        return true;
                }
            });
        }
        //TODO: make it depends to foreach result
        return false;
    }
}

/**
 *
 * @param node
 * @param nodeToAction
 */
function moveNodeBack(node, nodeToAction) {
    if (node.nodes.length > 0) {
        let searchIndex = -1;
        node.nodes.forEach(function (element, index) {
            if (element !== null && element.id === nodeToAction.id) {
                searchIndex = index;
                return null;
            }
        });
        if (searchIndex - 1 > -1) {
            let tmp = node.nodes[searchIndex];
            node.nodes[searchIndex] = node.nodes[searchIndex - 1];
            node.nodes[searchIndex - 1] = tmp;
            return true;
        } else {
            node.nodes.forEach(function (element) {
                if (element !== null) {
                    if (moveNodeBack(element, nodeToAction))
                        return true;
                }
            });
        }
        //TODO: make it depends to foreach result
        return false;
    }
}

export const {addNode} = nodes.actions
export const {changeNode} = nodes.actions
export const {changeNodeNoSendRequest} = nodes.actions
export const {deleteNode} = nodes.actions
export const {moveForward} = nodes.actions
export const {moveBack} = nodes.actions
export const {setNodes} = nodes.actions
export const {updateRootNode} = nodes.actions;
export const {changeNodeHidden} = nodes.actions

export default nodes.reducer;