/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const UploadFileDocument = gql`
    mutation UploadFile($file: String!, $fileName: String!) {
  uploadFile(file: $file, fileName: $fileName) {
    filePath
    fileName
    id
    fullFilePath
  }
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const GetUserFilesDocument = gql`
    query GetUserFiles {
  user {
    id
    files {
      fileName
      id
      extension
      filePath
      size
    }
  }
}
    `;

/**
 * __useGetUserFilesQuery__
 *
 * To run a query within a React component, call `useGetUserFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserFilesQuery(baseOptions?: Apollo.QueryHookOptions<GetUserFilesQuery, GetUserFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserFilesQuery, GetUserFilesQueryVariables>(GetUserFilesDocument, options);
      }
export function useGetUserFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFilesQuery, GetUserFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserFilesQuery, GetUserFilesQueryVariables>(GetUserFilesDocument, options);
        }
export function useGetUserFilesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserFilesQuery, GetUserFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserFilesQuery, GetUserFilesQueryVariables>(GetUserFilesDocument, options);
        }
export type GetUserFilesQueryHookResult = ReturnType<typeof useGetUserFilesQuery>;
export type GetUserFilesLazyQueryHookResult = ReturnType<typeof useGetUserFilesLazyQuery>;
export type GetUserFilesSuspenseQueryHookResult = ReturnType<typeof useGetUserFilesSuspenseQuery>;
export type GetUserFilesQueryResult = Apollo.QueryResult<GetUserFilesQuery, GetUserFilesQueryVariables>;
export const DeleteFileDocument = gql`
    mutation DeleteFile($id: Int!) {
  deleteFile(id: $id)
}
    `;
export type DeleteFileMutationFn = Apollo.MutationFunction<DeleteFileMutation, DeleteFileMutationVariables>;

/**
 * __useDeleteFileMutation__
 *
 * To run a mutation, you first call `useDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileMutation, { data, loading, error }] = useDeleteFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFileMutation, DeleteFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(DeleteFileDocument, options);
      }
export type DeleteFileMutationHookResult = ReturnType<typeof useDeleteFileMutation>;
export type DeleteFileMutationResult = Apollo.MutationResult<DeleteFileMutation>;
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<DeleteFileMutation, DeleteFileMutationVariables>;
export type UploadFileMutationVariables = Types.Exact<{
  file: Types.Scalars['String']['input'];
  fileName: Types.Scalars['String']['input'];
}>;


export type UploadFileMutation = { __typename?: 'Mutation', uploadFile: { __typename?: 'GraphFile', filePath: string, fileName: string, id?: number | null, fullFilePath: string } };

export type GetUserFilesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUserFilesQuery = { __typename?: 'Query', user?: { __typename?: 'GraphUser', id?: number | null, files: Array<{ __typename?: 'GraphFile', fileName: string, id?: number | null, extension: string, filePath: string, size?: number | null }> } | null };

export type DeleteFileMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type DeleteFileMutation = { __typename?: 'Mutation', deleteFile: boolean };
