import React from 'react'
import {changeActiveModalNode} from "../../../reducers/node-modal";
import {Modal} from "bootstrap";
import {useDispatch, useSelector} from "react-redux";

// const TMP => () => {}
const AddNewBlock = () => {
    const dispatch = useDispatch();
    const nodes = useSelector((state) => state.nodes.present.value);
    return <>
        <div className={'container text-center'} style={{marginTop: 100, marginBottom: 100}} >
           <div><i className="bi bi-window-plus" style={{fontSize: 90, color: "#F2F7FF"}}></i></div>
            <div onClick={
                () => {
                    dispatch(changeActiveModalNode(nodes[0]));
                    Modal.getOrCreateInstance('#container-to-add-modal').show();
                }
            } className={'btn btn-primary py-3 px-4'}><i className={'bi bi-plus-circle me-2'}></i>Create New Container</div>
        </div>
    </>
}

export default AddNewBlock
