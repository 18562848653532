export var SocketConnection = (function () {
    let webSocketConnection;
    let connectionStatus = 0;
    return {
        isReadyForSend: function () {
            return connectionStatus === 1;
        },
        getInstance: function (pageId) {
            try {
                if (webSocketConnection == null) {
                    webSocketConnection = new WebSocket(process.env.REACT_APP_WS_ADDRESS + "?page=" + pageId);
                    webSocketConnection.onopen = () => {
                        connectionStatus = 1;
                    };
                    // Hide the constructor so the returned object can't be new'd...
                }
                return webSocketConnection;
            } catch (e) {
                return null
            }

        },
    };
})();