export class ServiceContainer {
    private static services: Map<string, any> = new Map();

    static register(instance: ServiceInstance,serviceName:string): void {
        if (!ServiceContainer.services.has(serviceName)) {
            ServiceContainer.services.set(serviceName, instance);
        }
    }

    static resolve<T>(identifier: string): T {
        const service = ServiceContainer.services.get(identifier);
        if (!service) {
            throw new Error(`Service ${identifier} not found`);
        }
        return service;
    }
}

export class ServiceInstance {

}