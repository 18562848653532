import {Dispatch} from "react";
import {changeIsReadOnlyMode, changePageId, changeSeoDescription, changeSeoTitle, changeTitle, changeType, changeUser,} from "../reducers/app";
import {RequestPageType} from "../types/RequestPageType";
import {setNodes} from "../reducers/nodes";
import {NodeType, PageNode} from "../types/PageNode";
import PageService from "./Page/PageService";
import UserService from "./User/UserService";
import {ApolloClient, NormalizedCacheObject} from "@apollo/client";

// export const ComponentInitService = (pathId, isMobile, readOnlyMode, setLoaded, isMobilePreviewState) => {
export default class ComponentInitService {
    // let mainNode = useSelector((state) => state.nodes.present.value[0]);

    public static init(dispatch: Dispatch<any>, pathId: number, apolloClient: ApolloClient<NormalizedCacheObject>): Promise<PageService> {
        return new Promise((resolve, reject) => {
            PageService.getInstance().init(pathId,apolloClient).then((result) => {
                const page = result.page;
                if (page) {
                    //USER PART START
                    let userService = UserService.getInstance();
                    userService.init(apolloClient).then((result) => {
                        //TODO: Я не вижу тут проверки что пользователю вообще можно смотреть эту страницу
                        if (result.canEditPage(page.user.email, page.userAccesses)) {
                            dispatch(changeIsReadOnlyMode(true));
                        } else {
                            dispatch(changeIsReadOnlyMode(false));
                        }
                        dispatch(changeUser(result.user))
                    }).catch(reason => {
                        reject(reason)
                    });
                    //USER PART END

                    //PAGE PART START
                    const {title, contentString, id, type, seoTitle, seoDescription} = page;

                    if (id) {
                        dispatch(changePageId(id));
                    }

                    if (title) {
                        dispatch(changeTitle(title));
                    }

                    if (seoTitle) {
                        dispatch(changeSeoTitle(seoTitle));
                    }

                    if (seoDescription) {
                        dispatch(changeSeoDescription(seoDescription));
                    }

                    if (type) {
                        dispatch(changeType(type))
                    }

                    if (contentString && JSON.parse(contentString).length > 0) {
                        dispatch(setNodes({nodes: JSON.parse(contentString)}));
                    } else {
                        let initNode: PageNode = {
                            breadCrumbTitle: "Window",
                            type: NodeType.PageNode,
                            id: '1',
                            parentId: null,
                            tagName: 'div',
                            className: null,
                            nodes: [],
                            classes: []
                        }
                        if (type === RequestPageType.MobilePage) {
                            initNode.customStyle = {'max-width': '400px', 'margin': '0px auto'}
                        }
                        dispatch(setNodes({nodes: [initNode]}))
                    }
                    resolve(PageService.getInstance())
                    //PAGE PART END
                }
            }).catch((reason) => {
                reject(reason)
            })
        })
    }

}
