import React, {useEffect, useState} from "react";
import ContextMenu from "./components/context-menu/ContextMenu";
import {useDispatch, useSelector} from "react-redux";
import DesktopNavigationBar from "./components/preview/desktopNavigationBar";
import NodeContainer from "./components/node-container";
import CantLoad from "./components/system/cantLoad";
import MobileView from "./components/system/MobileView";
import EditBarView from "./components/preview/EditBatView";
import AddNewBlock from "./components/node/display/addNewBlock";
import MobileNavigationBar from "./components/preview/mobileNavigationBar";
import {useMediaQuery} from "react-responsive";
import {ActionCreators} from "redux-undo";
import {useHotkeys} from "react-hotkeys-hook";
import CssLinkModal from "./components/context-menu/modals/CssLinkModal";
import {changeIsAdvancedMode, changeIsMobileViewDimension, changePreview} from "./reducers/app";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ShoppingCheckout from "./components/context-menu/ShoppingCheckout";
import WebSocketProvider from "./components/WebSocketProvider";
import ComponentInitService from "./service/ComponentInitService";
import PageService from "./service/Page/PageService";
import {RootState} from "./store";
import QueryService from "./service/QueryService";
import {ScreenShotService} from "./service/ScreenShot/ScreenShotService";
import {PageScreenShotProcessor} from "./service/ScreenShot/PageScreenShotProcessor";
import ScreenShotNodeComponent from "./components/system/ScreenShotNodeComponent";
import GlobalSettingsModal from "./components/context-menu/modals/GlobalSettingsModal";
import { changeNode } from "./reducers/nodes";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
let isMobilePreview = urlParams.get("mobile");

interface InputProps {
    queryService: QueryService,
    apolloClient: any,
    screenShotService: ScreenShotService
}

const App: React.FC<InputProps> = ({queryService, apolloClient, screenShotService}) => {
    const dispatch = useDispatch();
    const isMobileDisplayMode = useSelector((state: RootState) => state.appStorage.isMobileDisplayMode);
    const isMobileViewDimension = useSelector((state: RootState) => state.appStorage.isMobileViewDimension);


    const countUndoRedo = useSelector((state: RootState) => state.nodes)
    const nodes = useSelector((state: RootState) => state.nodes.present.value)

    const isMobileAdvancedMode = new URLSearchParams(queryString).get('advanced')
    const isAdvancedMode = useSelector((state: RootState) => state.appStorage.isAdvancedMode);

    const isMobilePreviewState = new URLSearchParams(queryString).get('preview');
    const previewState = useSelector((state: RootState) => state.appStorage.preview)

    const readOnlyMode = useSelector((state: RootState) => state.appStorage.isReadOnlyMode);
    const isMobile = useMediaQuery({ query: `(max-width: 576px)` });
    const getMetaTag = document.querySelector('meta[name="viewport"]');

    const pathId = parseInt(window.location.pathname.split("/").pop());
    const [isLoaded, setLoaded] = useState(false);

    useEffect(() => {

        ComponentInitService.init(dispatch, pathId, apolloClient).then(() => {
            setLoaded(true)
        }).catch((reason) => {
            if (queryService.isDevMode) {
                alert(reason)
            } else {
                window.location.href = process.env.REACT_APP_BACKEND_ADDRESS + "/domain/";
            }
        })

        if (isMobilePreviewState === 'true') {
            dispatch(changePreview(Boolean(urlParams.get('preview'))));
        }
        if (isMobileAdvancedMode === 'true') {
            dispatch(changeIsAdvancedMode(Boolean(urlParams.get('advanced'))));
        }

        const handleVisibilityChange = async () => {
            screenShotService.runScreenShot(new PageScreenShotProcessor(PageService.getInstance().page.id,apolloClient))
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };


    }, []);

    useEffect(() => {
        if (readOnlyMode || isMobilePreviewState === 'true') {
            dispatch(changePreview(true));
        } else {
            dispatch(changePreview(false));
        }
    }, [readOnlyMode]);

    if (isMobile) {
        dispatch(changeIsMobileViewDimension(true));
    } else {
        dispatch(changeIsMobileViewDimension(false));
    }

    // useHotkeys("esc", () => dispatch(changeActiveModalNode()))

    useHotkeys("ctrl+z", () => {
        if (countUndoRedo.past.length > 1) {
            dispatch(ActionCreators.undo());
            dispatch(changeNode({ editedNode: nodes }))
        }
    }, [countUndoRedo]);
    useHotkeys("cmd+z", () => {
        if (countUndoRedo.past.length > 1) {
            dispatch(ActionCreators.undo());
            dispatch(changeNode({ editedNode: nodes }))
        }
    }, [countUndoRedo]);

    useHotkeys("ctrl+y", () => {
        dispatch(ActionCreators.redo());
        dispatch(changeNode({ editedNode: nodes }))
    });
    useHotkeys("cmd+y", () => {
        dispatch(ActionCreators.redo());
        dispatch(changeNode({ editedNode: nodes }))
    });
    const page=PageService.getInstance().page
    return (
        <>
        <BrowserRouter>
            <Routes>
                <Route path='/:id' element={<>
                    {readOnlyMode === false && (
                        <>
                            {isMobile ? (
                                <MobileNavigationBar
                                    pageTitle={page.title}
                                    getMetaTag={getMetaTag}
                                    isMobilePreview={isMobilePreview}
                                    previewState={previewState}
                                    isMobileViewDimension={isMobileViewDimension}
                                />
                            ) : (
                                <DesktopNavigationBar
                                    getMetaTag={getMetaTag}
                                    pageTitle={page.title}
                                    isMobileViewDimension={isMobileViewDimension}
                                    isMobileDisplayMode={isMobileDisplayMode}
                                />
                            )}
                        </>
                    )}
                    {(isMobileDisplayMode && !isMobileViewDimension && !isMobilePreview) ? (
                        <>
                            <MobileView
                                isMobileDisplayMode={isMobileDisplayMode}
                                isMobileViewDimension={isMobileViewDimension}
                                isMobilePreview={isMobilePreview}
                                previewState={previewState}
                                isAdvancedMode={isAdvancedMode}
                            />
                        </>
                    ) : (
                        <div className={`${isMobilePreview ? 'isMobilePreview' : ''}`}>
                            {isLoaded}
                            {isLoaded ? (
                                <>
                                    <NodeContainer/>
                                    {!previewState && nodes.length && !nodes[0].nodes.length && <AddNewBlock />}
                                </>
                            ) : (
                                <>
                                    <CantLoad />
                                </>
                            )}
                        </div>
                    )}
                    {isLoaded && <WebSocketProvider pageId={pathId} />}
                    <EditBarView />
                    <CssLinkModal/>
                    <ContextMenu />
                    <ScreenShotNodeComponent/>
                    {isLoaded && <GlobalSettingsModal />}
                    {/*<FileUploadComponent/>*/}
                </>}/>
                <Route path='/:id/checkout/*' element={<ShoppingCheckout/>}/>
            </Routes>
        </BrowserRouter>
        </>
    );
}

export default App;