// const TMP => () => {}
import React from "react";

export default function AttributeInputs({nodeState, setNodeState}) {

    let inputs = [];
    if (nodeState.attributes) {
        Object.keys(nodeState.attributes).map(key => {
            let obj = {}
            obj[key] = nodeState.attributes[key];
            inputs.push(obj);
            return true;
        });
    }

    const handleInputChange = (event, key) => {
        let tmpNode = {...nodeState};
        let tmpAttributes = {...nodeState.attributes}
        tmpAttributes[key] = event.target.value;
        tmpNode.attributes = tmpAttributes;
        setNodeState(tmpNode);
    };

    const handleKeyChange = (event, key) => {
        let tmpNode = {...nodeState};
        let tmpAttributes = {...nodeState.attributes}
        let value = event.target.value;
        let tmpValue = tmpAttributes[key]
        if (tmpAttributes[value.trim()] !== undefined) {
            value = ' ' + value
        } else {
            value = value.trimStart()
        }
        delete tmpAttributes[key];
        tmpAttributes[value] = tmpValue;
        tmpNode.attributes = tmpAttributes;
        setNodeState(tmpNode);
    };


    const handleAddInput = () => {
        let tmpNode = {...nodeState};
        if (tmpNode.attributes) {
            tmpNode.attributes = {...tmpNode.attributes, '': ''};
        } else {
            tmpNode.attributes = {'': ''};
        }
        setNodeState(tmpNode);
    };

    const handleRemoveInput = (key) => {
        let tmpNode = {...nodeState};
        let tmpAttributes = {...tmpNode.attributes};
        delete tmpAttributes[key];
        tmpNode.attributes = tmpAttributes;
        setNodeState(tmpNode);
    };

    // function MyComponent(props) {
    //     let { disabled, ...attrs } = props;
    //     if (disabled) {
    //         // thus, it will has the disabled attribute only if it
    //         // is disabled
    //         attrs = {
    //             ...attrs,
    //             disabled: true
    //         }
    //     };
    //
    //     return (
    //         <button {...attrs}>MyLabel</button>
    //     )
    // }
    return <>
        {inputs.length > 0 ?
            inputs.map((input, index) => {
                let keys = Object.keys(input);
                let objectKey = keys[0];
                let objectValue = input[keys[0]];
                return <div className={'row mb-3'} key={index}>
                    <div className={'col-5 pe-2'}>
                        <div className="form-floating">
                            <input type="text" className="form-control" id={index + 'name'}
                                   value={objectKey.trimStart()} onChange={(event) => handleKeyChange(event, objectKey)}
                                   placeholder="attribute name"/>
                            <label htmlFor={index + 'name'}>Attribute name</label>
                        </div>
                    </div>
                    <div className={'col-5 ps-1 pe-3'}>
                        <div className="form-floating">
                            <input type="text" className="form-control" id={index + 'value'}
                                   value={objectValue}
                                   onChange={(event) => handleInputChange(event, objectKey)}
                                   placeholder="Attribute value"/>
                            <label htmlFor={index + 'value'}>Attribute value</label>
                        </div>
                    </div>
                    <div className={'col-1 ps-0'}>
                        <div className="form-floating">
                            <button className={'form-control position-relative'} onClick={handleAddInput}><span
                                className='position-absolute top-50 start-50 translate-middle'><i
                                className='bi bi-plus-lg'></i></span>
                            </button>
                        </div>
                    </div>
                    <div className={'col-1 ps-0'}>
                        <div className="form-floating">
                            <button className={'form-control position-relative'}
                                    onClick={() => handleRemoveInput(objectKey)}><span
                                className='position-absolute top-50 start-50 translate-middle'><i
                                className='bi bi-dash'></i></span>
                            </button>
                        </div>
                    </div>
                </div>
            })
            :
            <button className='col-lg-6 py-3 btn btn-outline-primary text-primary bg-btn-img-color'
                    onClick={handleAddInput}>Add attribute</button>
        }
    </>
}

