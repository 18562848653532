export enum CustomStyleField
{
    Width='width',
    Height='height',
    BorderRadius='borderRadius',
    Border = 'border',
    BorderTopRightRadius='borderTopRightRadius',
    BorderTopLeftRadius='borderTopLeftRadius',
    BorderBottomLeftRadius='borderBottomLeftRadius',
    BorderBottomRightRadius='borderBottomRightRadius',
    BorderTop='borderTop',
    BorderBottom='borderBottom',
    BorderLeft='borderLeft',
    BorderRight = 'borderRight',
    BorderStyle='borderStyle',
    PaddingTop = 'paddingTop',
    PaddingBottom = 'paddingBottom',
    PaddingLeft = 'paddingLeft',
    PaddingRight = 'paddingRight',
    Padding = 'padding',
    Margin = 'margin',
    MarginTop='marginTop',
    MarginBottom='marginBottom',
    MarginLeft='marginLeft',
    MarginRight='marginRight',
    BorderColor='borderColor',
    Hover='hover',
    HoverColor='hoverColor',
    Color='color',
    Active='active',
    Background='background',
    BackgroundSize='backgroundSize',
    BackgroundColor='backgroundColor',
    BackgroundImage='backgroundImage',
    ObjectFit='objectFit',
    ImgFluid='imgFluid',
    Loading='loading',
    LoadingMobile='loadingMobile',
    Transform='transform',
    ButtonType='buttonType',
    Value='value',
    Target='target',
    FontSize="fontSize",
    Opacity='opacity',
    Gap='gap',
    DFlex='dFlex',
    fontFamily='fontFamily',
}