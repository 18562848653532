import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useMediaQuery} from "react-responsive";

const ProductList = ({params, submit}) => {
    const shoppingCart = useSelector((state) => state.shoppingModal.value);
    const [sumShopping, setSumShopping] = useState(0);
    const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
    useEffect(() => {
        // eslint-disable-next-line array-callback-return
        setSumShopping(shoppingCart.reduce((acc, sum) => {
            return acc+Number(sum.productPrice * sum.productQuantity)
        }, 0))
    }, [shoppingCart])
    const [iconStyles, setIconStyles] = useState({transform: 'rotate(0deg)'});
    const h5Attributes = isMobile ?
        {'data-bs-toggle': 'collapse', 'data-bs-target': '#collapseBlock', 'role':'button', 'aria-controls': 'collapseBlock', 'aria-expanded':"false"}:
        {}
    const toggleStyles = () => {
        if (iconStyles.transform === "rotate(180deg)") {
            setIconStyles({transform: 'rotate(0deg)'})
        } else {
            setIconStyles({transform: 'rotate(180deg)'})
        }
    }
    return (
        <>
            <h5 {...h5Attributes} className="d-flex justify-content-between align-items-center mb-md-5 mb-4"
                onClick={toggleStyles}>
                <span>Order Summary</span>
                <i className='bi bi-caret-down-fill d-block d-md-none' style={{...iconStyles, transition: 'transform 0.35s ease'}}></i>
            </h5>
            <div id='collapseBlock' className={isMobile ? "collapse": ''}>
                {shoppingCart.map(product => (
                    <div key={product.productName+Date.now()} className="d-flex justify-content-between mb-3">
                        <div className="col-md-3 d-flex">
                            <div className="border flex-shrink-0"
                                 style={{height:'50px', width:'50px', backgroundImage: `url("${product.productSrc}")`, backgroundSize:'cover'}}></div>
                            <div className="d-flex align-items-start justify-content-between flex-column m-3">
                                <h6>{product.productName}</h6>
                            </div>
                        </div>
                        <p className='col-md-auto d-flex align-items-center'>$ <span>{(product.productPrice * product.productQuantity)}</span></p>
                    </div>
                ))}
            </div>
            <div className="border-top d-flex justify-content-between pt-3">
                <span>Total</span>
                <h5>${sumShopping}</h5>
            </div>
            {params['*'] === 'confirm' ? <></>:<button className='btn col-12 btn-dark d-none d-md-block' onClick={submit}>Pay now</button>}
        </>
    );
};

export default ProductList