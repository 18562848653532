import React from "react";
import {fontObj} from "../../../../data/fonts";

const FontPickerModule = ({nodeCustomStyle, changeFormStyleFunction, styleName, labelName, deleteFunction}) => {


    const handleChangeFonts = (e) => {
        if(e.target.value === labelName || e.target.value === ""){
            deleteFunction(e)
        } else {
        changeFormStyleFunction(e)
        }
    }

    return (
        <div className="form-floating ">
            <select className="form-select border-border-gray  pt-2" name={styleName} placeholder="Font Face"
                    onChange={handleChangeFonts}
                    value={nodeCustomStyle?.[styleName] ? nodeCustomStyle?.[styleName] : ''}>
                <option defaultValue="">{labelName}</option>
                {fontObj.map((value) => (
                    <option key={value.id} value={value.font}>{value.font}</option>
                ))}
            </select>
        </div>
    );
}

export default FontPickerModule;