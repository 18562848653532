const fontObj = [
    {id: 1, font: 'Arial'},
    {id: 2, font: 'Roboto'},
    {id: 3, font: 'Comic Sans MS'},
    {id: 4, font: 'Courier New'},
    {id: 5, font: 'Franklin Gothic Medium'},
    {id: 6, font: 'Georgia'},
    {id: 7, font: 'Impact'},
    {id: 8, font: 'Lucida Console'},
    {id: 9, font: 'Lucida Sans Unicode'},
    {id: 10, font: 'Microsoft Sans Serif'},
    {id: 11, font: 'Palatino Linotype'},
    {id: 12, font: 'Sylfaen'},
    {id: 13, font: 'Tahoma'},
    {id: 14, font: 'Times New Roman'},
    {id: 15, font: 'Trebuchet MS'},
    {id: 16, font: 'Verdana'},
    {id: 17, font: 'Ebrima'},
    {id: 18, font: 'MS Gothic'},
    {id: 19, font: 'MV Boli'},
    {id: 20, font: 'Palatino Linotype'},
    {id: 21, font: 'Segoe Print'},
    {id: 22, font: 'Segoe UI Historic'},
    {id: 23, font: 'Sitka'},
    {id: 24, font: 'Sylfaen'},
    {id: 25, font: 'Trebuchet MS'},
    {id: 26, font: 'Verdana'},
    {id: 27, font: 'Webdings'},
    {id: 28, font: 'Wingdings'},
]

export {fontObj}