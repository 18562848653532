import React from 'react';
import {CustomStyleField} from "../types/CustomStyleField";
import {NodeType, PageNode} from "../types/PageNode";

interface CustomStylesProps {
    node: PageNode;
    isMobileViewDimension: boolean;
}

// Компонент с типизированными входными параметрами
const CustomStyles: React.FC<CustomStylesProps> = ({node, isMobileViewDimension}) => {

    const styleToString = (style: any) => {
        let returnString = ''
        Object.keys(style).map((key ) => {
            if ((key === CustomStyleField.Width || key === CustomStyleField.Height || key === CustomStyleField.BorderRadius ||
                    key === CustomStyleField.BorderTop || key === CustomStyleField.BorderBottom || key === CustomStyleField.BorderRight ||
                    key === CustomStyleField.BorderLeft || key === CustomStyleField.PaddingLeft || key === CustomStyleField.PaddingRight ||
                    key === CustomStyleField.PaddingTop || key === CustomStyleField.PaddingBottom || key === CustomStyleField.MarginBottom ||
                    key === CustomStyleField.MarginTop || key === CustomStyleField.MarginRight || key === CustomStyleField.MarginLeft
                )
                && !node.isMobileStyleContainerSize) {
                return;
            }
            if ((key === CustomStyleField.Background || key === CustomStyleField.BorderColor || key === CustomStyleField.Color)
                && !node.isMobileStyleColor) {
                return;
            }
            if ((key === CustomStyleField.BackgroundImage || key === CustomStyleField.BackgroundColor || key === CustomStyleField.BackgroundSize || key === CustomStyleField.ObjectFit)
                && !node.isMobileStyleBackground) {
                return;
            }
            if ((key === CustomStyleField.Transform || key === CustomStyleField.BackgroundColor || key === CustomStyleField.ObjectFit)
                && !node.isMobileStyleImage) {
                return;
            }
            returnString += key.split(/(?=[A-Z])/).join('-').toLowerCase() + ':' + style[key] + '!important;'

        })
        return returnString;
        // return Object.keys(style).reduce((acc, key) => (
        //     acc + key.split(/(?=[A-Z])/).join('-').toLowerCase() + ':' + style[key] + '!important;'
        // ), '');
    };

    const styleToStringWithAction = (style: any, action: string): string => {
        let returnString = ''
        Object.keys(style).map((key) => {
            let name = ''
            if (action === 'hover') {
                if (key === CustomStyleField.HoverColor) {
                    name = 'color'
                }
                if (key === CustomStyleField.Hover) {
                    name = 'background-color'
                }
            }
            returnString += name + ':' + style[key] + '!important;'
        })
        return returnString
    }

    const generateStyleTag = (styleContent: string) => (
        <style dangerouslySetInnerHTML={{__html: styleContent}}></style>
    );

    const mobileStyle = node.customStyleMobile
        ? `@media (max-width: 576px) {
            #blockContent${node.id} {${styleToString(node.customStyleMobile)}}
          }`
        : '';

    const hoverStyle = node.customStyle && (node.tagName === "a" || node.type === NodeType.ButtonNode)
        ? `#blockContent${node.id}:hover {${styleToStringWithAction(node.customStyle, 'hover')}}`
        : '';

    const hoverStyleMobile = node.customStyleMobile && (node.tagName === "a" || node.type === NodeType.ButtonNode)
        ? `#blockContent${node.id}:hover {${styleToStringWithAction(node.customStyleMobile, 'hover')}}`
        : '';

    const activeStyle = node.customStyle && (node.className === "btn" || node.tagName === "a" || node.tagName === 'button') && node.customStyle['active']
        ? `#blockContent${node.id}:active {color: ${(node.customStyle['active'])}!important}`
        : '';

    const activeStyleMobile = node.customStyleMobile && (node.className === "btn" || node.tagName === "a" || node.tagName === 'button') && node.customStyleMobile['active']
        ? `#blockContent${node.id}:active {color: ${(node.customStyleMobile['active'])}!important}`
        : '';

    // const desktopHiddenStyle = node.hidden === "desktopHidden" && !isMobileViewDimension && node.className === "container"
    //     ? `@media (min-width: 576px) {
    //         #blockContent${node.id} {display: none!important}
    //       }`
    //     : '';
    //
    // const mobileHiddenStyle = node.hidden === "mobileHidden" && isMobileViewDimension && node.className === "container"
    //     ? `@media (max-width: 576px) {
    //         #blockContent${node.id} {display: none!important}
    //       }`
    //     : '';

    return (
        <>
            {mobileStyle && generateStyleTag(mobileStyle)}
            {!node.isMobileStyleColor || !isMobileViewDimension ? hoverStyle && generateStyleTag(hoverStyle) : hoverStyleMobile && generateStyleTag(hoverStyleMobile)}
            {!node.isMobileStyleColor || !isMobileViewDimension ? activeStyle && generateStyleTag(activeStyle) : activeStyleMobile && generateStyleTag(activeStyleMobile)}
            {/*{desktopHiddenStyle && generateStyleTag(desktopHiddenStyle)}*/}
            {/*{mobileHiddenStyle && generateStyleTag(mobileHiddenStyle)}*/}
        </>
    );
};

export default CustomStyles;
