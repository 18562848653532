import React from "react";
import "./../App.scss";
import {useSelector} from "react-redux";
import Node from "./node/node";
import DynamicHeadContent from "./context-menu/block/modules/DynamicHeadContent";
import {PageNode} from "../types/PageNode";
import {RootState} from "../store";
import ScreenShotNodeComponent from "./system/ScreenShotNodeComponent";

interface StylesProps {
}

const NodeContainer: React.FC<StylesProps> = () => {
    const nodes = useSelector((state: RootState) => state.nodes.present.value);
    const links = useSelector((state: RootState) => state.appStorage.links);
    const previewState = useSelector((state:RootState) => state.appStorage.preview)




    return (
        <>
            <div id="mainBlock">
                <div>
                    {nodes.map((node: PageNode) => (
                        <Node isPreviewState={previewState} key={node.id} node={node} />
                    ))}
                    {links.map((link: any, index: any) => (
                        <DynamicHeadContent key={index} path={link} />
                    ))}
                </div>
            </div>

        </>
    );
}

export default NodeContainer;