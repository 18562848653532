import {HttpLink} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {ServiceContainer} from "../ServiceContainer";
import QueryService from "../QueryService";

export default class LinkProvider {
    public static getHttpLink() {
        return new HttpLink({
            uri: process.env.REACT_APP_BACKEND_ADDRESS + "/graphql",
            credentials: 'include',
        })

    }

    public static getContextLink(httpLink: HttpLink, token: string) {
        let contextLink = setContext((_, {headers}) => {
            return {
                headers: {
                    ...headers,
                    'X-AUTH-TOKEN': token,
                },
            };
        });
        return contextLink.concat(httpLink)
    }

    public static getCurrentLink() {
        const token = ServiceContainer.resolve<QueryService>('QueryService').token
        if (!token) {
            return LinkProvider.getHttpLink();
        } else {
            return LinkProvider.getContextLink(LinkProvider.getHttpLink(), token)
        }
    }
}