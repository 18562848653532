import React, {useState} from 'react';
import {changeIsMobileDisplayMode, changePreview} from "../../reducers/app";
import {handlerCopy, pasteCopyNode} from "../../service/EditBarService";
import {deleteNode} from "../../reducers/nodes";
import {changeActiveModalNode} from "../../reducers/node-modal";
import {useAdditionalHandlers, useClickHandlers} from "../../service/desktopNavigationService";
import {useSelector} from "react-redux";
import {openModal} from "../../reducers/modalSlice";


const MobileSettingsBar = ({dispatch, getMetaTag, activeNode, isMobileViewDimension}) => {
    const previewState = useSelector((state) => state.appStorage.preview)
    const [checkPreview, setCheckPreview] = useState(previewState)
    const nodes = useSelector((state) => state.nodes.present.value);
    const {handleClickDuplicate, handlerClickExport, handlerClickPublish} = useClickHandlers(dispatch);
    const {handleShowHiddenBlocksClick, confirmActionAndRedirect} = useAdditionalHandlers(dispatch, previewState, nodes);
    const showHiddenBlocks = useSelector((state) => state.appStorage.showHiddenBlocks);
    return (
        <div className="text-start">
            <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasWithBothOptions"
                    aria-controls="offcanvasWithBothOptions">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="offcanvas offcanvas-start text-white" data-bs-scroll="true" tabIndex="-1"
                 id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel" style={{background: "#3E4957"}}>
                <div className="offcanvas-header justify-content-end">
                    <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas"
                            aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                <li className={`nav-item ${isMobileViewDimension === false ? 'active' : ''}`}>
                                    <a className="nav-link"
                                       onClick={() => {
                                           dispatch(changeIsMobileDisplayMode(false))
                                           getMetaTag.setAttribute("content", "width=device-width, initial-scale=0")
                                       }
                                       }>
                                        <i className="bi bi-display text-white"></i>
                                    </a>
                                </li>
                                <li className={`nav-item ${isMobileViewDimension === true ? 'active' : ''}`}>
                                    <a className="nav-link"
                                       onClick={() => {
                                           dispatch(changeIsMobileDisplayMode(true))
                                       }}>
                                        <i className="bi bi-phone text-white"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="form-check form-switch text-white" onClick={() => {
                            setCheckPreview(prevCheck => {
                                dispatch(changePreview(!prevCheck))
                                return !prevCheck
                            })
                        }}>
                            <a className="pe-2"><i className="bi bi-play-circle" style={checkPreview ? {color: "blue"} : {color: "white"} }></i></a>
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Preview</label>
                        </div>
                    </div>
                    <div>
                        <div className="mt-3">
                            <h6 className="text-secondary">Page</h6>
                        </div>
                        <ul className="navbar-nav">
                            <li className="nav-item mt-1">
                                <a className="nav-link" href="/domain/new" data-bs-dismiss="offcanvas">
                                    <i className="bi bi-file-earmark me-2"></i>New Page
                                </a>
                            </li>
                            <li className="nav-item mt-1">
                                <a className="nav-link" href="/domain/" data-bs-dismiss="offcanvas">
                                    <i className="bi bi-files me-2"></i>My Projects</a>
                            </li>
                            <li className="nav-item mt-1"><a className="nav-link" href="#"
                                                             onClick={handlerClickExport}>
                                <i className="bi bi-reply me-2"></i>Export</a>
                            </li>
                            <li className="nav-item mt-1">
                                <a className="nav-link" data-bs-dismiss="offcanvas"><i
                                    className="bi bi-download"></i> Save Project</a>
                            </li>
                            <li className="nav-item mt-1">
                                        <span className="nav-link"
                                              onClick={handlerClickPublish}>
                                            <i className='bi bi-box-arrow-up-right me-2'></i>Publish Page</span>
                            </li>
                            <li className="nav-item mt-1"><a className="nav-link" href="#" onClick={() => {
                                dispatch(openModal({modalName: "css-link-modal"}));
                            }}>
                                <i className="bi bi-code-slash pe-2"></i> CSS Link</a>
                            </li>
                            <li className="nav-item mt-1">
                                <a className="nav-link" data-bs-dismiss="offcanvas" onClick={handleClickDuplicate}><i
                                    className="bi bi-file-earmark-plus"></i> Duplicate Page</a>
                            </li>
                            <li className="nav-item mt-1">
                                <a className="nav-link" data-bs-dismiss="offcanvas"
                                   onClick={(event) => confirmActionAndRedirect(event)}>
                                    <i className="bi bi-trash"></i> Delete Page</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <div className="mt-3">
                            <h6 className="text-secondary">EDIT</h6>
                        </div>
                        <ul className="navbar-nav">
                            <li className="nav-item mt-1">
                                <a className="nav-link" data-bs-dismiss="offcanvas" onClick={() => {
                                    handlerCopy(activeNode)
                                }}><i className="bi bi-stickies"></i> Copy</a>
                            </li>
                            <li className="nav-item mt-1">
                                <a className="nav-link" data-bs-dismiss="offcanvas"><i className="bi bi-file-earmark-check"></i> Select All</a>
                            </li>
                            <li className="nav-item mt-1">
                                {activeNode ?
                                    (<a className="nav-link" data-bs-dismiss="offcanvas" onClick={() => {
                                        dispatch(deleteNode({removeNode: activeNode}))
                                        dispatch(changeActiveModalNode(undefined));
                                        handlerCopy(activeNode)
                                    }}><i className="bi bi-scissors"></i> Cut</a>) :
                                    (<a className="nav-link" data-bs-dismiss="offcanvas"><i className="bi bi-scissors"></i> Cut</a>)
                                }
                            </li>
                            <li className="nav-item mt-1">
                                <a className="nav-link" data-bs-dismiss="offcanvas" onClick={() => {
                                    pasteCopyNode(dispatch, activeNode)
                                }}><i className="bi bi-clipboard-check"></i> Paste</a>
                            </li>
                            <li className="nav-item mt-1">
                                <a className="nav-link" href="#" onClick={handleShowHiddenBlocksClick}>
                                    {showHiddenBlocks ? (
                                            <>
                                                <i className="bi bi-eye-slash pe-2"></i> Hide Blocks
                                            </>
                                        ) :
                                        ( <>
                                                <i className="bi bi-eye pe-2"></i> Show Hidden Blocks
                                            </>
                                        )}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <div className="mt-3">
                            <h6 className="text-secondary">MAIN</h6>
                        </div>
                        <ul className="navbar-nav">
                            <li className="nav-item mt-1">
                                <a className="nav-link" data-bs-dismiss="offcanvas"><i className="bi bi-question-circle"></i> Help</a>
                            </li>
                            <li className="nav-item mt-1">
                                <a className="nav-link" data-bs-dismiss="offcanvas"><i className="bi bi-person"></i> Account</a>
                            </li>
                            <li className="nav-item mt-1">
                                <a className="nav-link" data-bs-dismiss="offcanvas"><i className="bi bi-box-arrow-right"></i> Log Out</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileSettingsBar