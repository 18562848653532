import {GetPageDocument, GetPageQuery, GetPageQueryVariables} from "./PageService.hooks";
import {ApolloClient, NormalizedCacheObject} from "@apollo/client";


export default class PageService {
    get page(): GetPageQuery['page'] {
        return this._page;
    }

    private static instance: PageService
    private _page: GetPageQuery['page'];


    public init(pathId: number, apolloClient: ApolloClient<NormalizedCacheObject>): Promise<PageService> {
        return new Promise((resolve, reject) => {
            apolloClient.query<GetPageQuery, GetPageQueryVariables>({
                query: GetPageDocument,
                variables: {id: pathId}
            }).then((data) => {
                this._page = data.data.page
                resolve(this)
            }).catch(reason => {
                reject(reason)
            })
        })
    }

    public static getInstance() {
        if (PageService.instance === undefined) {
            PageService.instance = new PageService();
        }
        return PageService.instance;
    }
}