import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import FormCheckout from "./FormCheckout";
import ConfirmCheckout from "./ConfirmCheckout";
import {setPersonData} from "../../reducers/shopping-modal";
import ProductList from "./productList";

const ShoppingCheckout = () => {
    const shoppingCart = useSelector((state) => state.shoppingModal.value);
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [sumShopping, setSumShopping] = useState(0);
    useEffect(() => {
        // eslint-disable-next-line array-callback-return
        setSumShopping(shoppingCart.reduce((acc, sum) => {
            return acc+Number(sum.productPrice * sum.productQuantity)
        }, 0))
    }, [shoppingCart])

    useEffect(() => {
        return () => {
            setPersonData({})
        }
    })

    const [inputsValues, setInputsValues] = useState({
        email: '',
        firstName: '',
        lastName: '',
        company: '',
        address: '',
        apartments: '',
        country: '',
        city: '',
        zip: '',
        paymentMethod: 'paypal',
        order: Date.now()
    })

    const submit = (e) => {
        e.preventDefault()
        if (shoppingCart.length) {
            dispatch(setPersonData(inputsValues))
            handlePayment(e)
            // navigate(`${location.pathname}/confirm`)
        }
    }

    useEffect(() => {
        const fondyScript = document.createElement('script');
        fondyScript.src = 'https://pay.fondy.eu/static_common/v1/checkout/ipsp.js';
        fondyScript.async = true
        document.body.appendChild(fondyScript);
        //
        //  fondyScript.onload = () => {
        //      setTimeout(()=>{
        //          const button = window.$ipsp.get('.button');
        //          button.setMerchantId(1396424);
        //          button.setAmount('100', 'USD');
        //          button.setHost('pay.fondy.eu');
        //      }, 1000)
        // };

        // return () => {
        //     document.body.removeChild(fondyScript);
        // };
    }, []);

    const handlePayment = () => {
        const button = window.$ipsp.get('button');
        button.setMerchantId(1396424);
        button.setAmount(sumShopping, 'USD', true);
        button.setHost('pay.fondy.eu');
        button.setResponseUrl(`${window.location.href}/confirm`)
        const checkoutUrl = button.getUrl();
        // window.$ipsp.get('checkout').config({
        //     'wrapper':'#frameholder',
        //     'styles' : {},
        // }).scope(function(){
        //     this.width(480);
        //     this.height(480);
        //     this.loadUrl(checkoutUrl);
        // });
        window.location.href = checkoutUrl;
    };

    const ConfirmCheckout = () => {
        useEffect(() => {
            const confirmPayment = async () => {
                try {
                    // Отправить POST-запрос на сервер
                    const response = await fetch("/api/confirm", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({ /* Данные, которые нужно отправить */})
                    });

                    // Обработать ответ сервера
                    if (response.ok) {
                        // Успешный ответ от сервера
                        console.log("Payment confirmed");
                    } else {
                        // Неуспешный ответ от сервера
                        console.log("Payment confirmation failed");
                    }
                } catch (error) {
                    // Обработка ошибок
                    console.error("Error confirming payment", error);
                }
            };

            confirmPayment();
        }, []);
    };

    return (
        <div className="container">
            <div className="row g-5 m-md-5 mt-0 m-2">
                <div className="col-md-5 col-lg-4 order-last">
                    <div className="border p-4 rounded d-none d-md-block">
                        <ProductList params={params} submit={submit}/>
                    </div>

                </div>
                <div className="col-md-6 p-1 col-lg-7">
                    <h2 className="mb-3">Checkout</h2>
                    <div className="through-checking d-flex align-items-center mb-5">
                        <h6 className="me-3 mb-0">Information</h6>
                        <i className={`bi bi-chevron-right me-3 ${params['*'] === 'confirm' ? '' : 'opacity-50'}`}></i>
                        <h6 className={`mb-0 ${params['*'] === 'confirm' ? '' : 'opacity-50'}`}>Order Confirmation</h6>
                    </div>
                    <Routes>
                        <Route path='/' element={<FormCheckout submit={submit}
                                                               inputsValues={inputsValues}
                                                               setInputsValues={setInputsValues}
                                                               navigate={navigate}
                                                               params={params}
                        />}/>
                        <Route path='/confirm' element={<ConfirmCheckout/>}/>
                    </Routes>
                    {/*<button className="button" onClick={handlePayment}>*/}
                    {/*    Pay an arbitrary amount*/}
                    {/*</button>*/}
                </div>
            </div>
            <script src="'https://pay.fondy.eu/static_common/v1/checkout/ipsp.js"></script>
        </div>

    );
};

export default ShoppingCheckout