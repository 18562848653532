import {createSlice} from "@reduxjs/toolkit";
// @deprecated
export const activeModal = createSlice({
    name: 'activeModal',
    initialState: {
        value: {activeModalName: null}
    },
    reducers: {
        changeActiveModalName: (state, action) => {
            state.value = action.payload;
        }
    }
})
export const {changeActiveModalName} = activeModal.actions
export default activeModal.reducer;