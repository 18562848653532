import React from 'react';

const FontSizePicker = ({changeFormStyleFunction, nodeCustomStyle, deleteFunction}) => {
    const handleChangeSize = (e) => {
        if (e.target.value === 'none') {
            deleteFunction(e);
        } else {
        changeFormStyleFunction(e)
        }
    }
    return(
        <>
            <div className="form-floating  small">
                <select id="edit-block-modal-font-size" name='fontSize' onChange={handleChangeSize}
                        className='form-control border-border-gray form-select me-2' placeholder='Font Size'
                        value={nodeCustomStyle?.fontSize ?? 'none'}>
                    <option value='none'>None</option>
                    <option value='8px'>8px</option>
                    <option value='10px'>10px</option>
                    <option value='12px'>12px</option>
                    <option value='14px'>14px</option>
                    <option value='16px'>16px</option>
                    <option value='18px'>18px</option>
                    <option value='20px'>20px</option>
                    <option value='22px'>22px</option>
                    <option value='24px'>24px</option>
                    <option value='26px'>26px</option>
                    <option value='28px'>28px</option>
                    <option value='30px'>30px</option>
                    <option value='32px'>32px</option>
                    <option value='34px'>34px</option>
                    <option value='36px'>36px</option>
                    <option value='38px'>38px</option>
                    <option value='40px'>40px</option>
                    <option value='42px'>42px</option>
                    <option value='44px'>44px</option>
                    <option value='46px'>46px</option>
                    <option value='48px'>48px</option>
                    <option value='50px'>50px</option>
                    <option value='52px'>52px</option>
                    <option value='54px'>54px</option>
                    <option value='56px'>56px</option>
                </select>
                <label htmlFor="edit-block-modal-font-size" className="form-label">Font Size</label>
            </div>
        </>
    )
}

export default FontSizePicker;