import React, { useEffect, useState } from "react";
import {
    ChangeNodeFunction, changeStyleByValue,
    ChangeStyleFunction, deleteCustomStyleByName,
    DeleteCustomStyleByNameFunction
} from "../../../service/editModalService";
import EditModalSizeInput from "../ui/input/EditModalSizeInput";
import {CustomStyleField} from "../../../types/CustomStyleField";
import {PageNode} from "../../../types/PageNode";
import {InputTypes} from "../../../types/InputTypes";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

interface StyleSettingsProps {
    editableNode: PageNode,
    changeNodeFunction: ChangeNodeFunction,
    isMobileStyleContainerSize:boolean,
    isMobileViewDimension: boolean,
}

const BorderSpacing: React.FC<StyleSettingsProps> = ({
                                                         editableNode,
                                                         isMobileViewDimension,
                                                         changeNodeFunction,
                                                     }) => {
    const activeNode = useSelector((state: RootState) => state.nodeModal.value) as PageNode;
    const [isMixedBorder, setIsMixedBorder] = useState(false);
    let isMobileStyleContainerSize: boolean = false
    if (isMobileViewDimension) {
        isMobileStyleContainerSize = editableNode.isMobileStyleContainerSize
    }

    const changeBorderCustomFunction = (editedNode: PageNode, isMobileStyle: boolean, isMixed: boolean): PageNode => {
        let tmpNode = {...editableNode}

        if (isMobileStyle) {
            tmpNode.isMobileMixedBorder = isMixed
        } else {
            tmpNode.isMixedBorder = isMixed
        }

        if (isMixed) {
            tmpNode = deleteCustomStyleByName(CustomStyleField.Border, tmpNode, isMobileStyle)
        } else {
            tmpNode = deleteCustomStyleByName(CustomStyleField.BorderTop, tmpNode, isMobileStyle)
            tmpNode = deleteCustomStyleByName(CustomStyleField.BorderLeft, tmpNode, isMobileStyle)
            tmpNode = deleteCustomStyleByName(CustomStyleField.BorderBottom, tmpNode, isMobileStyle)
            tmpNode = deleteCustomStyleByName(CustomStyleField.BorderRight, tmpNode, isMobileStyle)
        }
        tmpNode = deleteCustomStyleByName(CustomStyleField.BorderStyle, tmpNode, isMobileStyle)

        changeNodeFunction(tmpNode)
        return tmpNode
    }

    const changeBorderStyleFunction: ChangeStyleFunction = (name, value, isMobileEditMode) => {
        let tmpNode = changeStyleByValue(name, value, editableNode, isMobileEditMode)

        if (isMobileEditMode) {
            tmpNode.customStyleMobile = Object.assign({...tmpNode.customStyleMobile}, {[CustomStyleField.BorderStyle]: 'solid'});
        } else {
            tmpNode.customStyle = Object.assign({...tmpNode.customStyle}, {[CustomStyleField.BorderStyle]: 'solid'});
        }

        const borderValue = value + ' solid'
        tmpNode = changeStyleByValue(name, borderValue, editableNode, isMobileEditMode)
        changeNodeFunction(tmpNode)
    }

    const deleteBorderStyleFunction: DeleteCustomStyleByNameFunction = (name, isMobileEditMode) => {
        let tmpNode = deleteCustomStyleByName(name, editableNode, isMobileEditMode)

        if (isMobileEditMode) {
            if (!Object.keys(tmpNode.customStyleMobile).some(el => el.startsWith('border') && el !== CustomStyleField.BorderStyle)) {
                tmpNode.customStyleMobile = Object.assign(
                    {...tmpNode.customStyleMobile},
                    {[CustomStyleField.BorderStyle]: ""}
                );
                delete tmpNode["customStyleMobile"][CustomStyleField.BorderStyle]
            }
        } else {
            if (!Object.keys(tmpNode.customStyle).some(el => el.startsWith('border') && el !== CustomStyleField.BorderStyle)) {
                tmpNode.customStyle = Object.assign(
                    {...tmpNode.customStyle},
                    {[CustomStyleField.BorderStyle]: ""}
                );
                delete tmpNode["customStyle"][CustomStyleField.BorderStyle]
            }
        }
        changeNodeFunction(tmpNode)
    }

    const updateMixedValue = () => {
        const isBorder = !!(activeNode?.customStyle?.borderTop || activeNode?.customStyle?.borderBottom || activeNode?.customStyle?.borderLeft || activeNode?.customStyle?.borderRight);
        setIsMixedBorder(activeNode.isMixedBorder || isBorder);
        if (isMobileStyleContainerSize) {
            setIsMixedBorder(activeNode.isMobileMixedBorder);
        }
    }

    useEffect(() => {
        if(!activeNode) return;
        updateMixedValue();
        const modalElement = document.getElementById('edit-block-modal');
        const handleClose = () => {
            updateMixedValue();
        };
    
        modalElement.addEventListener('hide.bs.modal', handleClose);
        return () => {
          modalElement.removeEventListener('hide.bs.modal', handleClose);
        };
        // eslint-disable-next-line
    }, [activeNode]);

    return (
        <div>
            <div className="row gx-3">
                <div className="col-md-6 gx-3">
                    <EditModalSizeInput
                        deleteStyleByName={deleteBorderStyleFunction}
                        changeStyleFunction={changeBorderStyleFunction}
                        editableNode={editableNode}
                        optionValue={CustomStyleField.Border}
                        labelName={"Border (px)"}
                        isMobileStyle={isMobileStyleContainerSize}
                        inputType={InputTypes.Mixed}
                        isMixedStyle={isMixedBorder}
                        changeCustomFunction={changeBorderCustomFunction}
                        handleChangeIsMixedStyle={setIsMixedBorder}
                    />
                </div>
            </div>
            {isMixedBorder &&
                <>
                    <p className="fw-bold mt-4">Border</p>
                <div className="row g-3">
                    <div className="col-6">
                        <EditModalSizeInput
                            editableNode={editableNode}
                            deleteStyleByName={deleteBorderStyleFunction}
                            changeStyleFunction={changeBorderStyleFunction}
                            optionValue={CustomStyleField.BorderTop}
                            labelName={"Top"}
                            isMobileStyle={isMobileStyleContainerSize}
                        />
                    </div>
                    <div className="col-6">
                        <EditModalSizeInput
                            editableNode={editableNode}
                            deleteStyleByName={deleteBorderStyleFunction}
                            changeStyleFunction={changeBorderStyleFunction}
                            optionValue={CustomStyleField.BorderBottom}
                            labelName={"Bottom"}
                            isMobileStyle={isMobileStyleContainerSize}
                        />
                    </div>
                    <div className="col-6">
                        <EditModalSizeInput
                            editableNode={editableNode}
                            deleteStyleByName={deleteBorderStyleFunction}
                            changeStyleFunction={changeBorderStyleFunction}
                            optionValue={CustomStyleField.BorderLeft}
                            labelName={"Left"}
                            isMobileStyle={isMobileStyleContainerSize}
                        />
                    </div>
                    <div className="col-6">
                        <EditModalSizeInput
                            editableNode={editableNode}
                            deleteStyleByName={deleteBorderStyleFunction}
                            changeStyleFunction={changeBorderStyleFunction}
                            optionValue={CustomStyleField.BorderRight}
                            labelName={"Right"}
                            isMobileStyle={isMobileStyleContainerSize}
                        />
                    </div>

                </div>
                </>
            }
        </div>
    )
}
export default BorderSpacing