import {DivColNode, DivRowNode, NodeType, PageNode} from "../../../types/PageNode";
import {getNewNode} from "../../NodeService";
import DivColNodeFactory from "./DivColNodeFactory";

export default class DivRowNodeFactory {
    public static getNode = (parentNode:PageNode) => {
        let node: DivRowNode = {
            ...getNewNode(NodeType.DivRowNode, parentNode, {
                "tagName": "div",
                "className": "row",
                "backClassesIndexed": {'gx-0': 'gx-0'},
                "backClassesMobile": {},
                'breadCrumbTitle': 'Row',
            }), ...{structure: 1}
        }
        node.nodes.push(DivColNodeFactory.getNode(node))

        return node
    }
}