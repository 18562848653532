import {changeActiveModalNode} from "../reducers/node-modal";
import {addNode, changeNode, findNode} from "../reducers/nodes";
import {DivColNode, DivRowNode, NodeType, PageNode} from "../types/PageNode";
import {BackClass} from "../types/BackClass";
import {AnyAction} from "@reduxjs/toolkit";
import {Dispatch} from "react";
import {changeNewNode} from "../reducers/app";

let iteration: number = 0;

/**
 * It's need for template nodes. Because they have similar ids on second time
 * @deprecated
 * @param node
 * @param parentId
 * @param idArray
 */
export function addNewIdForChildNodes(node: PageNode, parentId: string = null, idArray: {[key: string]: string} = null): {[key: string]: string} {
    iteration++;
    if (idArray === null) {
        idArray = {}
    }
    const newID = Date.now() + '-' + iteration;
    idArray[node.id] = newID

    node.id = newID
    if (parentId != null)
        node.parentId = parentId;
    if (node.nodes.length > 0) {
        node.nodes.forEach((foreachNode) => {
            addNewIdForChildNodes(foreachNode, node.id, idArray)
        });
    }
    return idArray
}

export function getNodeWithNewIds(node: PageNode, parentId: string = null): PageNode {
    let copyNode: PageNode = {...node}
    copyNode.id = Date.now() + '-' + iteration
    if (parentId != null)
        copyNode.parentId = parentId;
    if (copyNode.nodes.length > 0) {
        let childNodes: PageNode[] = []
        copyNode.nodes.forEach((foreachNode) => {
            iteration++
            childNodes.push(getNodeWithNewIds(foreachNode, copyNode.id))
        });
        copyNode.nodes = childNodes
    }
    return copyNode
}

export function changeDataBSTargetId(node: PageNode, idArray: {[key: string]: string}) {
    if (node.attributes && node.attributes['data-bs-target']) {
        const newID = idArray[node.attributes['data-bs-target'].replace('#blockContent', '')]
        node.attributes = {...node.attributes, 'data-bs-target': `#blockContent${newID}`}
    }
    if (node.nodes.length > 0) {
        node.nodes.forEach((foreachNode) => {
            changeDataBSTargetId(foreachNode, idArray)
        })
    }
}

export function getDefaultDivColNode(id: string, tagName: string, className: string, parentId: string, type: string): PageNode {
    return {
        id: id,
        type: type,
        tagName: tagName,
        className: className,
        style: [] as object,
        classes: [] as string[],
        nodes: [] as PageNode[],
        parentId: parentId,
        backClassesIndexed: [] as unknown as BackClass,
        backClassesMobile: [] as unknown as BackClass,
    }
}

export function addNodeToActiveNodeWithAutoSelect(dispatch:Dispatch<AnyAction>, activeNode:PageNode, nodeToAdd:PageNode) {
    dispatch(changeNewNode(nodeToAdd))
    dispatch(changeActiveModalNode(addNodeToActiveNode(dispatch, activeNode, nodeToAdd)));
}

export function addNodeToActiveNode(dispatch:Dispatch<AnyAction>, activeNode: PageNode, nodeToAdd:PageNode): PageNode {
    let newNode = {...nodeToAdd};
    newNode.style = {};
    newNode.classes ??= [];
    newNode.nodes ??= [];
    dispatch(addNode({activeNode, newNode}));
    return newNode;
}

export function addNodeAfterActiveNode(dispatch: Dispatch<AnyAction>, activeNode: PageNode, nodeToAdd: PageNode, nodes: ParentNode[]): PageNode {
    let newNode = {...nodeToAdd};
    const parentNode = findNode(nodes[0], newNode.parentId)
    let newParentNode = {
        ...parentNode,
        nodes: [...parentNode.nodes]
    };
    const index = newParentNode.nodes.findIndex((node: PageNode) => node.id === activeNode.id) + 1
    newParentNode.nodes.splice(index, 0, newNode);
    dispatch(changeNode({editedNode:newParentNode}))
    return newNode;
}

export function makeStructureChange(previousNodeState:DivRowNode, newNodeState: DivRowNode) {
    let newNodeStateCopy = {...newNodeState};
    if (newNodeStateCopy.structure) {
        let previousNodeStateStructure = previousNodeState.structure !== undefined ? previousNodeState.structure : 0;
        // let diff = newNodeState.structure - previousNodeState.structure;
        let newColSize = Math.round(12 / newNodeStateCopy.structure);


        if (newNodeStateCopy.structure > previousNodeStateStructure) {
            let diff = newNodeStateCopy.structure - previousNodeStateStructure;


            let lastId: number = Date.now();
            for (let step = 0; step < diff; step++) {
                let newNode: PageNode = getDefaultDivColNode((lastId++).toString(), 'div', 'block', (newNodeStateCopy.id).toString(), NodeType.DivColNode);
                newNodeStateCopy.nodes = [...newNodeStateCopy.nodes, newNode];
            }
        } else if (newNodeStateCopy.structure < previousNodeState.structure) {
            let step = previousNodeStateStructure;
            for (; step > newNodeStateCopy.structure; step--) {
                let tmpNodes: PageNode[] = [...newNodeStateCopy.nodes];
                let popElement: PageNode = tmpNodes.pop();

                if (tmpNodes.length > 0) {
                    let lastChild: PageNode = {...tmpNodes[tmpNodes.length - 1]};
                    lastChild.nodes = [...lastChild.nodes, ...popElement.nodes];
                    tmpNodes[tmpNodes.length - 1] = lastChild;
                }
                newNodeStateCopy.nodes = tmpNodes;
            }
        }
        newNodeStateCopy.nodes.forEach((node: DivColNode, index) => {
            let tmpNode = {...node};
            if (newNodeState.structure !== previousNodeState.structure || tmpNode.colSize === undefined) {
                tmpNode.colSize = newColSize;
            }
            // newNodeStateCopy.nodes[index] = tmpNode;
            let nodes = [...newNodeStateCopy.nodes]
            nodes[index] = tmpNode;
            newNodeStateCopy.nodes = nodes;
        })


    }
    return newNodeStateCopy;
}


export function getNewNode(type: NodeType, parentNode?: PageNode, override?: Partial<PageNode>) {
    let newNode: PageNode = {
        classes: [],
        style: [],
        id: (Date.now()).toString(),
        nodes: [],
        parentId: parentNode ? parentNode.id : undefined,
        type: type
    };
    if (override) {
        newNode = {...newNode, ...override}
    }

    return newNode;
}

export function getAllNodes(startNode: PageNode, nodes?: PageNode[]) {
    if (nodes === undefined) {
        nodes = []
    }
    nodes.push(startNode)
    if (startNode.nodes.length > 0) {
        startNode.nodes.map((node) => {
            getAllNodes(node, nodes)
        })
    }
    return nodes;
}