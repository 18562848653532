import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import {NodeRenderService} from "../../../service/NodeRenderService";
import {IFrameNode, PageNode} from "../../../types/PageNode";
import CustomStyles from "../../../service/CustomsStyles";

interface InputProps {
    node: IFrameNode
    handleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    handleDoubleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    nodes: PageNode[]
}

export const IFrameComponent: React.FC<InputProps> = ({node, handleClick, handleDoubleClick, nodes}) => {
    let nodeToShow: IFrameNode = {...node};
    const isAdvancedMode = useSelector((state: RootState) => state.appStorage.isAdvancedMode);
    const isPreviewState = useSelector((state: RootState) => state.appStorage.preview)
    const isMobileViewDimension = useSelector((state: RootState) => state.appStorage.isMobileViewDimension)
    const activeNode = useSelector((state: RootState) => state.nodeModal.value)
    const activeContainerNode = useSelector((state: RootState) => state.activeContainerNode.activeContainerNode);

    let dispatch = useDispatch();

    let nodeStyle = NodeRenderService.getStyle(nodeToShow);
    nodeStyle = {...nodeStyle};
    if (!isPreviewState) {
        nodeStyle.pointerEvents = 'none';
    }

    let className = NodeRenderService.getClasses(nodeToShow, activeNode, activeContainerNode, isPreviewState, isAdvancedMode).join(' ');

    // title="YouTube video player"
    // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    // referrerPolicy="strict-origin-when-cross-origin"
    return <>
        <div
            onClick={(event) => {
                NodeRenderService.handleClick(event, isPreviewState, isAdvancedMode, nodeToShow, dispatch, nodes)
            }}
            onDoubleClick={NodeRenderService.handleDoubleClick}
        >
            <iframe key={nodeToShow.id}
                    onClick={e => handleClick(e)}
                    onDoubleClick={handleDoubleClick}
                    id={'blockContent' + nodeToShow.id}
                    className={(className)}
                    style={nodeStyle}
                    allowFullScreen
                    width="100%" height="100%" src={node.value}
                    ></iframe>
        </div>
        <CustomStyles node={node} isMobileViewDimension={isMobileViewDimension}/>
    </>
        ;

}
