import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {ChangeNodeFunction} from "../../../service/editModalService";
import {CustomStyleField} from "../../../types/CustomStyleField";
import {ButtonNode} from "../../../types/PageNode";
import TargetPicker from "./TargetPicker";
import TinyTextInput from "./TinyTextInput";

interface LinkProps {
    editableNode: ButtonNode,
    changeNodeFunction: ChangeNodeFunction,
    changeFormFunction: (e: any) => void,
    isMobileViewDimension: boolean,
}

const Link: React.FC<LinkProps> = ({editableNode, changeNodeFunction, changeFormFunction, isMobileViewDimension}) => {
    const activeNode = useSelector((state: RootState) => state.nodeModal.value)
    const [selectedInput, setSelectedInput] = useState('')
    useEffect(()=>{
        if (editableNode && editableNode[CustomStyleField.ButtonType] !== undefined && editableNode[CustomStyleField.ButtonType] !== selectedInput) {
            setSelectedInput(editableNode[CustomStyleField.ButtonType])
        }
    }, [activeNode, editableNode, selectedInput])

    const handleChangeLink = (e: React.ChangeEvent<HTMLSelectElement>) => {
        changeFormFunction(e);
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedInput(e.target.value)
        editableNode[CustomStyleField.ButtonType] = e.target.value
        changeNodeFunction(editableNode)
    }



    return (
        <>
            <TinyTextInput changeNodeFunction={changeNodeFunction}
                           editableNode={editableNode}
                           isMobileViewDimension={isMobileViewDimension}/>

            <div className='mb-3 opacity-50 ps-1' style={{fontSize: '14px'}}>
                {editableNode && editableNode.nodes && editableNode.nodes.length ? 'Remove child elements to add text' : ''}
            </div>

            {/*<div className={`col-md-12 form-floating mb-4 ${!selectedInput ? 'opacity-50' : ''}`}>*/}
            {/*    <select className="form-select " id="actionClick" name="actionClick"*/}
            {/*            value={selectedInput} onChange={handleInputChange}>*/}
            {/*        <option value="" hidden>Action on Click</option>*/}
            {/*        <option value='useMyLink'>Custom link</option>*/}
            {/*        <option value='usePageLink'>Project link</option>*/}
            {/*        /!*<option value='cart'>Cart</option>*!/*/}
            {/*    </select>*/}
            {/*    <label htmlFor="actionClick" className="form-label">Link</label>*/}
            {/*</div>*/}

            {/*{selectedInput === 'useMyLink' ? (*/}
            {/*    <div className="form-floating mb-3 small">*/}
            {/*        <select*/}
            {/*            id="edit-block-modal-font-size"*/}
            {/*            name={CustomStyleField.PageUrl}*/}
            {/*            onChange={handleChangeLink}*/}
            {/*            className='form-control form-select me-2'*/}
            {/*            value={editableNode[CustomStyleField.PageUrl]}*/}
            {/*        >*/}
            {/*            /!*<option value='test'>super-shluhi.me-web.site/super-shluha-1.html</option>*!/*/}
            {/*        </select>*/}
            {/*        <label htmlFor="edit-block-modal-font-size" className="form-label">Page URL</label>*/}
            {/*    </div>*/}
            {/*) : (*/}
            {/*    <></>*/}
            {/*)}*/}

            {/*{selectedInput === 'usePageLink' ? (*/}
            {/*    <div className="form-floating mb-3">*/}
            {/*        <input*/}
            {/*            id={'edit-block-modal-href-input'}*/}
            {/*            onChange={(e) => {*/}
            {/*                // setNode({...node, [CustomStyleField.Href]: e.target.value})*/}
            {/*                editableNode[CustomStyleField.Href] = e.target.value*/}
            {/*                changeNodeFunction(editableNode);*/}
            {/*            }}*/}
            {/*            type='text'*/}
            {/*            placeholder={'Set destination link'}*/}
            {/*            value={editableNode[CustomStyleField.Href]}*/}
            {/*            className={'form-control'}*/}
            {/*        />*/}
            {/*        <label htmlFor="edit-block-modal-href-input" className="form-label">Custom URL</label>*/}
            {/*    </div>*/}
            {/*) : (<></>)}*/}
            {/*{selectedInput === 'cart' ? (*/}
            {/*    <ShoppingCartSettings editedNode={editableNode}*/}
            {/*                          isMobileViewDimension={isMobileViewDimension}*/}
            {/*                          changeFunction={changeFunction}*/}
            {/*                          deleteStyleElementFunction={deleteStyleElementFunction} data={data}/>*/}
            {/*): <></>}*/}
            {/*<div className="col-md-12 mt-3">*/}

            {/*</div>*/}
        </>
    );
}

export default Link;