import {ChangeNodeFunction, DeleteCustomStyleByNameFunction} from "./editModalService";
import React, {SetStateAction} from "react";
import {CustomStyleField} from "../types/CustomStyleField";
import {ImageNode} from "../types/PageNode";
import { FileManagerFile } from "./FileManager/FileManagerFile";

const addNodeImage = (
    editedNode: ImageNode,
    changeFunction: any,
    isMobileStyle: boolean,
    fileManagerFile: FileManagerFile
): void => {
    if (isMobileStyle) {
        editedNode.srcMobile = fileManagerFile.serverPath
        editedNode.srcNameMobile = fileManagerFile.title
    } else {
        editedNode.src = fileManagerFile.serverPath;
        editedNode.srcName = fileManagerFile.title;
    }
    changeFunction(editedNode);
};

// const addImageWithReturn = async (editedNode, changeFunction, isMobileViewDimension, data) => {
//     let newImage = {};
//     const userId = hashCode(data.me.userName);
//
//     let flmngr = window.flmngr.create({
//         urlFileManager: `https://me-qr.com/ckeditor/file-manager?user-id=${userId}`,
//         urlFiles: 'https://cdn.me-qr.com/n1ed/files/' + userId + '/'
//     });
//
//     let modal = Modal.getOrCreateInstance('#edit-block-modal');
//     await new Promise((resolve, reject) => {
//         flmngr.pickFiles(
//             {
//                 isMultiple: false,
//                 acceptExtensions: ["png", "jpg", "jpeg", "gif", "webp", "svg"],
//                 onFinish: async function (files) {
//                     if (files.length > 0) {
//                         let file = files[0];
//
//                         await fetch(file.url)
//                             .then(res => {
//                                 let sizeInBytes = res.headers.get("Content-Length");
//                                 let sizeInMegabytes  = sizeInBytes / (10 ** 6);
//                                 newImage.srcSize = Math.ceil(sizeInMegabytes * 100) / 10;
//                             })
//
//                         let url = file.url;
//                         let urlParts = url.split('/');
//                         let srcName = urlParts[urlParts.length - 1];
//
//                         newImage.srcName = srcName;
//                         if (isMobileViewDimension) {
//                             newImage.srcMobile = file.url;
//                         } else {
//                             newImage.src = file.url;
//                         }
//
//                         if (editedNode.sliderList) {
//                             if (!Array.isArray(editedNode.sliderList)) {
//                                 editedNode.sliderList = [];
//                             }
//                             editedNode.sliderList = editedNode.sliderList.slice();
//                             editedNode.sliderList.push({
//                                 key: Date.now(),
//                                 src: file.url,
//                                 srcName: srcName,
//                                 srcSize: newImage.srcSize,
//                             });
//                         }
//                     }
//                     changeFunction({ ...editedNode, sliderList: editedNode.sliderList });
//                     Modal.getOrCreateInstance('#edit-block-modal').show();
//                 },
//                 onCancel: function () {
//                     modal.show();
//                 },
//             }
//         );
//     });
//
//     modal.hide();
//     return newImage;
// };

 const truncateString = (str: string, maxLength: number): string => {
    if (str.length <= maxLength) {
        return str;
    }

    return str.slice(0, maxLength) + '...';
};

 const handleDeleteImg = (
     e: React.MouseEvent<HTMLDivElement>,
     editedNode: ImageNode,
     deleteStyleByName: DeleteCustomStyleByNameFunction,
     changeNodeFunction: ChangeNodeFunction,
     isMobileStyle: boolean
 ) => {
     e.stopPropagation();
     let nodeCustomStyle
     if (isMobileStyle) {
         editedNode.srcMobile = null
         editedNode.srcNameMobile = null
         changeNodeFunction(editedNode)
         nodeCustomStyle = editedNode.customStyleMobile
     } else {
         editedNode.src = null
         editedNode.srcName = null
         changeNodeFunction(editedNode)
         nodeCustomStyle = editedNode.customStyle
     }
     if (nodeCustomStyle && nodeCustomStyle[CustomStyleField.BackgroundImage]) {
         deleteStyleByName(CustomStyleField.BackgroundImage, isMobileStyle);
     }
};

//  const handleCarouselDeleteImg = (e, editedNode, id, setSliderList, changeFunctionImage) => {
//     e.stopPropagation();
//     if (editedNode.editSettings && editedNode.editSettings.isCarousel === true) {
//         const updatedSliderList = editedNode.sliderList.filter((item) => item.key !== id);
//         setSliderList(updatedSliderList);
//         changeFunctionImage(editedNode);
//     }
// };

 const handleEyeClick = (e: React.MouseEvent<HTMLDivElement>, setShowImg: React.Dispatch<SetStateAction<boolean>>) => {
    e.stopPropagation();
    setShowImg((prevShowImg) => !prevShowImg);
};

export { truncateString, handleDeleteImg, handleEyeClick, addNodeImage };

