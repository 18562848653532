import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    addNodeToActiveNode,
    addNodeToActiveNodeWithAutoSelect,
    getDefaultDivColNode,
    getNewNode,
    makeStructureChange
} from "../../../service/NodeService";
import {findNodeWhichAreContainer} from "../../../service/EditBarService";
import containerImg from "../../../assets/element-img/container.svg"
import btnImg from "../../../assets/element-img/button.svg"
import iframeImg from "../../../assets/element-img/iframe.svg"
import blockImg from "../../../assets/element-img/img.svg"
import textImg from "../../../assets/element-img/text.svg"
import iconBlock from "../../../assets/element-img/icon-block.svg"
import {Modal} from "bootstrap";
import {hideAddElementModal} from "../../../service/ModalService";
import StaticTags from "../add-element/advanced/StaticTags";
import {changeActiveModalNode} from "../../../reducers/node-modal";
import {NodeType, PageNode} from "../../../types/PageNode";
import {RootState} from "../../../store";
import {changeNewNode} from "../../../reducers/app";

// const TMP => () => {}
const ElementAddModal = () => {
    const activeNode: PageNode = useSelector((state: RootState) => state.nodeModal.value);
    const isAdvancedMode = useSelector((state: RootState) => state.appStorage.isAdvancedMode);
    const dispatch = useDispatch();
    const nodes = useSelector((state: RootState) => state.nodes.present.value);
    if (!activeNode) {
        return <></>
    }
    // let templateNodes = nodes[0] ? getAllNodes(nodes[0]).filter((pageNode: PageNode) =>  pageNode.adminSettings?.isElementAllowedToAdd && pageNode.type!==NodeType.DivContainer) : []
    // let uniqueTemplateNodes = templateNodes.reduce((acc: PageNode[], current) => {
    //     const x = acc.find(item => current.adminSettings && item.adminSettings.elementAllowedToAddId === current.adminSettings.elementAllowedToAddId);
    //     if (!x) {
    //         acc.push(current);
    //     }
    //     return acc;
    // }, []);

    return <div className="modal modal-xl" id="element-add-modal" tabIndex={-1}>
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header border-0">
                    <h4 className="modal-title">Elements</h4>
                    <div className="">
                        {/*<div className="form-check form-switch pe-3">*/}
                        {/*    <input className="form-check-input" type="checkbox"*/}
                        {/*           onChange={() => {*/}
                        {/*               changeIsAdvancedModeAction(!isAdvancedMode)*/}
                        {/*           }}*/}
                        {/*           checked={isAdvancedMode}*/}
                        {/*           id="flexSwitchCheckDefault"/>*/}
                        {/*    <label className="form-check-label text-dark" htmlFor="flexSwitchCheckDefault">Advanced*/}
                        {/*        Mode</label>*/}
                        {/*</div>*/}
                    </div>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    {(activeNode && activeNode.id !== '1') && <>
                        <div className={'container'}>
                            {/*{*/}
                            {/*    uniqueTemplateNodes.length > 0 && <div className={'row'}>*/}
                            {/*        <div className={'col-12'}>*/}
                            {/*            <h5 className="ps-2 pt-1 pb-2"><b>Template Elements</b></h5>*/}
                            {/*            <div className={'row'}>*/}
                            {/*                {uniqueTemplateNodes.map((pageNode: PageNode) => {*/}
                            {/*                    return <div className='col-12 col-md-4 '>*/}
                            {/*                        <div*/}
                            {/*                            onClick={() => {*/}
                            {/*                                let pageNodeWithNewID=getNodeWithNewIds(pageNode);*/}
                            {/*                                pageNodeWithNewID.adminSettings={...pageNodeWithNewID.adminSettings,...{isElementAllowedToAdd:false, elementAllowedToAddId:undefined}}*/}

                            {/*                                addNodeToActiveNode(dispatch, activeNode, pageNodeWithNewID);*/}
                            {/*                                Modal.getOrCreateInstance('#element-add-modal').hide();*/}
                            {/*                            }}*/}
                            {/*                            className="text-center border-1 border-secondary border-opacity-25 p-2 border">*/}
                            {/*                            <img src={pageNode.adminSettings.elementPreviewImage} alt={''} style={{maxWidth:'100%'}}/>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                })}*/}

                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*}*/}

                            <div className={'row'}>
                                <div className={'col-12'}>
                                    <div>
                                        <h5 className="ps-2 pt-1 pb-2"><b>Container</b></h5>
                                        <div className=" ms-2 btn p-0 element-block"
                                             onClick={() => {
                                                 if (activeNode.type === NodeType.DivRowNode) {
                                                     let nodeContainer = findNodeWhichAreContainer(activeNode, nodes[0], 'row');
                                                     if (nodeContainer.structure && nodeContainer.structure <= 3) {
                                                         const newNode = {
                                                             ...nodeContainer,
                                                             structure: nodeContainer.structure + 1
                                                         }
                                                         const currentNode = makeStructureChange(nodeContainer, newNode)
                                                         dispatch(changeActiveModalNode(currentNode))
                                                     } else {
                                                         const newNode = addNodeToActiveNode(dispatch, nodeContainer, getDefaultDivColNode(Date.now().toString(), 'div', 'container-col', nodeContainer.id, NodeType.DivColNode));
                                                         dispatch(changeNewNode(newNode))
                                                         dispatch(changeActiveModalNode(newNode))
                                                     }
                                                     Modal.getOrCreateInstance('#edit-block-modal').show();
                                                     hideAddElementModal();
                                                 } else {
                                                     let newNode: PageNode = getNewNode(NodeType.DivNode, activeNode)
                                                     let tmpNode = {
                                                         tagName: 'div',
                                                     }
                                                     addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {...newNode, ...tmpNode});
                                                     Modal.getOrCreateInstance('#edit-block-modal').show();
                                                     hideAddElementModal();
                                                 }
                                             }}
                                             data-bs-target="#edit-text-modal" data-bs-toggle="modal">
                                            <img src={containerImg} alt="" className="img-fluid element-img"/>
                                        </div>
                                    </div>
                                    {(activeNode.id !== '1') && <>
                                        <h5 className="ps-2 pt-3 pb-2"><b>Form</b></h5>
                                        <div>
                                            <div className=" ms-2 btn p-0 element-block"
                                                 onClick={() => {
                                                     let newNode: PageNode = getNewNode(NodeType.ButtonNode, activeNode)
                                                     let tmpNode = {
                                                         tagName: 'a',
                                                         disallowedAddElements: true,
                                                         className: "btn",
                                                     }
                                                     addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {...newNode, ...tmpNode});
                                                     Modal.getOrCreateInstance('#edit-block-modal').show();
                                                     hideAddElementModal();
                                                 }}
                                                 data-bs-target="#edit-text-modal" data-bs-toggle="modal">
                                                <img src={btnImg} alt="" className="img-fluid element-img"/>
                                            </div>
                                        </div>
                                        {/*{isAdvancedMode &&*/}
                                        {/*    <>*/}
                                        {/*        <h5 className="ps-2 pt-3 pb-2"><b>Slider</b></h5>*/}
                                        {/*        <div className="d-flex">*/}
                                        {/*            <div className=" ms-2 btn p-0 element-block"*/}
                                        {/*                 onClick={() => {*/}
                                        {/*                     addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {*/}
                                        {/*                         tagName: 'div',*/}
                                        {/*                         className: "",*/}
                                        {/*                         editSettings: {*/}
                                        {/*                             isCarousel: true*/}
                                        {/*                         }*/}
                                        {/*                     });*/}
                                        {/*                     Modal.getOrCreateInstance('#edit-block-modal').show();*/}
                                        {/*                     hideAddElementModal();*/}
                                        {/*                 }}*/}
                                        {/*                 data-bs-target="#edit-text-modal" data-bs-toggle="modal">*/}
                                        {/*                <img src={slider1} alt=""*/}
                                        {/*                     className="img-fluid element-img"/>*/}
                                        {/*            </div>*/}
                                        {/*            <div className=" ms-2 btn p-0 element-block"*/}
                                        {/*                 onClick={() => {*/}
                                        {/*                     addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {*/}
                                        {/*                         tagName: 'div',*/}
                                        {/*                         className: "",*/}
                                        {/*                         editSettings: {*/}
                                        {/*                             isCarouselWithText: true*/}
                                        {/*                         }*/}
                                        {/*                     });*/}
                                        {/*                     Modal.getOrCreateInstance('#edit-block-modal').show();*/}
                                        {/*                     hideAddElementModal();*/}
                                        {/*                 }}*/}
                                        {/*                 data-bs-target="#edit-text-modal" data-bs-toggle="modal">*/}
                                        {/*                <img src={slider2} alt=""*/}
                                        {/*                     className="img-fluid element-img"/>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </>*/}
                                        {/*}*/}
                                        <h5 className="ps-2 pt-3 pb-2"><b>Text & Graphic</b></h5>
                                        <div className="d-flex">
                                            <div>
                                                <div className=" ms-2 btn p-0 element-block"
                                                     onClick={() => {
                                                         let newNode: PageNode = getNewNode(NodeType.TextNode, activeNode)
                                                         let tmpNode = {
                                                             tagName: 'text',
                                                             disallowedAddElements: true,
                                                             className: "",
                                                         }
                                                         addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {...newNode, ...tmpNode});
                                                         Modal.getOrCreateInstance('#edit-block-modal').show();
                                                         hideAddElementModal();
                                                     }}
                                                     data-bs-target="#edit-text-modal" data-bs-toggle="modal">
                                                    <img src={textImg} alt=""
                                                         className="img-fluid element-img"/>
                                                </div>
                                            </div>
                                            <div>
                                                <div className=" ms-2 btn p-0 element-block"
                                                     onClick={() => {
                                                         let newNode: PageNode = getNewNode(NodeType.ImageNode, activeNode)
                                                         let tmpNode = {
                                                             tagName: 'img',
                                                             disallowedAddElements: true,
                                                             className: "img",
                                                         }
                                                         addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {...newNode, ...tmpNode});
                                                         Modal.getOrCreateInstance('#edit-block-modal').show();
                                                         hideAddElementModal();
                                                     }}
                                                     data-bs-target="#edit-text-modal" data-bs-toggle="modal">
                                                    <img src={blockImg} alt=""
                                                         className="img-fluid element-img"/>
                                                </div>
                                            </div>
                                        </div>

                                        {/*<h5 className="ps-2 pt-3 pb-2"><b>Lists</b></h5>*/}
                                        {/*<div className="d-flex">*/}
                                        {/*    <div>*/}
                                        {/*        <div className=" ms-2 btn p-0 element-block"*/}
                                        {/*             onClick={() => {*/}
                                        {/*                 addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {*/}
                                        {/*                     tagName: "ul",*/}
                                        {/*                     className: "",*/}
                                        {/*                 });*/}
                                        {/*                 Modal.getOrCreateInstance('#edit-block-modal').show();*/}
                                        {/*                 hideAddElementModal();*/}
                                        {/*             }}*/}
                                        {/*             data-bs-target="#edit-text-modal" data-bs-toggle="modal">*/}
                                        {/*            <img src={orderListImg} alt=""*/}
                                        {/*                 className="img-fluid element-img"/>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*    <div>*/}
                                        {/*        <div className=" ms-2 btn p-0 element-block"*/}
                                        {/*             onClick={() => {*/}
                                        {/*                 addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {*/}
                                        {/*                     tagName: "li",*/}
                                        {/*                     className: "",*/}
                                        {/*                 });*/}
                                        {/*                 Modal.getOrCreateInstance('#edit-block-modal').show();*/}
                                        {/*                 hideAddElementModal();*/}
                                        {/*             }}*/}
                                        {/*             data-bs-target="#edit-text-modal" data-bs-toggle="modal">*/}
                                        {/*            <img src={unorderedListImg} alt=""*/}
                                        {/*                 className="img-fluid element-img"/>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <h5 className="ps-2 pt-3 pb-2"><b>Iframe</b></h5>
                                        <div className="d-flex">
                                            <div>
                                                <div className=" ms-2 btn p-0 element-block"
                                                     onClick={() => {
                                                         let newNode: PageNode = getNewNode(NodeType.IFrameNode, activeNode)
                                                         let tmpNode = {
                                                             tagName: 'iframe'
                                                         }
                                                         addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {...newNode, ...tmpNode});
                                                         Modal.getOrCreateInstance('#edit-block-modal').show();
                                                         hideAddElementModal();
                                                     }}
                                                     data-bs-target="#edit-text-modal" data-bs-toggle="modal">
                                                    <img src={iframeImg} alt=""
                                                         className="img-fluid element-img"/>
                                                </div>
                                            </div>
                                            {/*<div>*/}
                                            {/*    <div className=" ms-2 btn p-0 element-block"*/}
                                            {/*         onClick={() => {*/}
                                            {/*             addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {*/}
                                            {/*                 type:NodeType.IFrameNode,*/}
                                            {/*             });*/}
                                            {/*             Modal.getOrCreateInstance('#edit-block-modal').show();*/}
                                            {/*             hideAddElementModal();*/}
                                            {/*         }}*/}
                                            {/*         data-bs-target="#edit-text-modal" data-bs-toggle="modal">*/}
                                            {/*        <img src={youtubeImg} alt=""*/}
                                            {/*             className="img-fluid element-img"/>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                        {isAdvancedMode && <>
                                            <h5 className="ps-2 pt-3 pb-2"><b>Links & Icons</b></h5>
                                            <div className="d-flex">
                                                {/*<div className=" ms-2 btn p-0 element-block"*/}
                                                {/*     onClick={() => {*/}
                                                {/*         addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {*/}
                                                {/*             tagName: 'div',*/}
                                                {/*             className: "",*/}
                                                {/*             editSettings: {*/}
                                                {/*                 isSocialTextLinks: true*/}
                                                {/*             }*/}
                                                {/*         });*/}
                                                {/*         Modal.getOrCreateInstance('#edit-block-modal').show();*/}
                                                {/*         hideAddElementModal();*/}
                                                {/*     }}*/}
                                                {/*     data-bs-target="#edit-text-modal" data-bs-toggle="modal">*/}
                                                {/*    <img src={textLinks} alt=""*/}
                                                {/*         className="img-fluid element-img"/>*/}
                                                {/*</div>*/}
                                                {/*<div className=" ms-2 btn p-0 element-block"*/}
                                                {/*     onClick={() => {*/}
                                                {/*         addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {*/}
                                                {/*             tagName: 'div',*/}
                                                {/*             className: "",*/}
                                                {/*             editSettings: {*/}
                                                {/*                 isSocialLinks: true*/}
                                                {/*             }*/}
                                                {/*         });*/}
                                                {/*         Modal.getOrCreateInstance('#edit-block-modal').show();*/}
                                                {/*         hideAddElementModal();*/}
                                                {/*     }}*/}
                                                {/*     data-bs-target="#edit-text-modal" data-bs-toggle="modal">*/}
                                                {/*    <img src={iconLinks} alt=""*/}
                                                {/*         className="img-fluid element-img"/>*/}
                                                {/*</div>*/}
                                                <div className=" ms-2 btn p-0 element-block"
                                                     onClick={() => {
                                                         let newNode: PageNode = getNewNode(NodeType.IconNode, activeNode)
                                                         let tmpNode = {
                                                             tagName: 'i',
                                                             className: "bi",
                                                             editSettings: {
                                                                 isIcons: true
                                                             }
                                                         }
                                                         addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {...newNode, ...tmpNode});
                                                         Modal.getOrCreateInstance('#edit-block-modal').show();
                                                         hideAddElementModal();
                                                     }}
                                                     data-bs-target="#edit-text-modal" data-bs-toggle="modal">
                                                    <img src={iconBlock} alt=""
                                                         className="img-fluid element-img"/>
                                                </div>
                                            </div>
                                        </>}
                                    </>}
                                    {(isAdvancedMode) && <>
                                        <h5 className="ps-2 pt-3 pb-2"><b>Advanced</b></h5>
                                        <StaticTags activeNode={activeNode} dispatch={dispatch}></StaticTags>
                                        {/*<DynamicTag activeNode={activeNode} dispatch={dispatch}></DynamicTag>*/}
                                    </>}
                                </div>
                            </div>
                        </div>
                    </>}

                </div>
                {/*<div className="modal-footer">*/}
                {/*    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>*/}
                {/*    <button type="button" className="btn btn-primary">Save changes</button>*/}
                {/*</div>*/}
            </div>
        </div>
    </div>;
}

export default ElementAddModal
