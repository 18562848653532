// const TMP => () => {}
import React from "react";

const TextInput = ({editedNode, changeFormFunction}) => {
    return (
        <>
            <div className="form-floating mb-3">
                <input id={'edit-block-modal-text-input'} name='value' onChange={changeFormFunction}
                       value={editedNode.value ? editedNode.value : ""} placeholder={'Enter your text'} type='text'
                       className={'form-control'}/>
                <label htmlFor="edit-block-modal-text-input"
                       className="form-label text-background-mobile">{editedNode.tagName === 'button' ? "Button Title" : "Inner text"}</label>
            </div>
        </>
    )
}

export default TextInput
