import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setNodes} from "../reducers/nodes";
import {SocketConnection} from "../service/SocketConnection";

const WebSocketProvider = ({pageId}) => {
    //TODO: make this class like a service. no need to use it as component
    const dispatch = useDispatch();
    const nodes = useSelector((state) => state.nodes.present.value);

    useEffect(() => {
        //ON each state update it will happen
        let connection = SocketConnection.getInstance(pageId);
        if (SocketConnection.isReadyForSend()) {
            connection.send(JSON.stringify({id: pageId, nodes}))
        }
    }, [JSON.stringify(nodes)]);



    useEffect(() => {
        let connection = SocketConnection.getInstance(pageId);
        if (connection) {
            connection.addEventListener('message', messageListenerAndUpdatePage);

            return () => {
                connection.removeEventListener('message', messageListenerAndUpdatePage);
                connection.close()
            }
        }
    }, [dispatch,pageId]);

    const messageListenerAndUpdatePage = (e) => {
        if (SocketConnection.isReadyForSend()) {
            const data = JSON.parse(e.data)
            const {id, nodes} = data;

            if (pageId === id) {
                dispatch(setNodes({nodes}));
            }
        }
    };
    return <></>
};

export default WebSocketProvider;