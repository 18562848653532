import React, { useState, useEffect } from 'react';

const Dropdown = ({ nodeToShow }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = (e) => {
        e.preventDefault()
        setIsOpen(!isOpen);
    };

    const handleBlur = (e) => {
        e.preventDefault()
        setIsOpen(false);
    };

    useEffect(() => {
        const togglerButtons = document.querySelectorAll('.navbar-toggler');
        const handleClick = () => setIsOpen(!isOpen);

        togglerButtons.forEach((button) => {
            button.addEventListener('click', handleClick);
        });

        return () => {
            togglerButtons.forEach((button) => {
                button.removeEventListener('click', handleClick);
            });
        };
    }, [isOpen]);

    const isNavbarToggler = nodeToShow === 'navbar-toggler';
    const menuStyle = {
        display: isOpen && isNavbarToggler ? 'block' : 'none',
    };

    return (
            <div className="custom-dropdown" onClick={handleToggle} onBlur={handleBlur}>
                <ul className="custom-dropdown-menu" aria-labelledby="dropdownMenuButton1" style={menuStyle}>
                    <li><a className="custom-dropdown-item" href="#">Action</a></li>
                </ul>
            </div>
    );
};

export default Dropdown;