// import React from 'react'
// import {useDispatch, useSelector} from "react-redux";
// import {addNewIdForChildNodes, addNodeToActiveNode} from "../../../service/NodeService";
// import {useQuery} from "@apollo/client";
// import {COMMON_TEMPLATE_LIST} from "../../../apollo/Queries";
// import {Modal} from "bootstrap";
//
// // const TMP => () => {}
// const CommonTemplates = () => {
//     const activeNode = useSelector((state) => state.nodeModal.value)
//     const dispatch = useDispatch();
//     const {data: commonTemplatesData} = useQuery(COMMON_TEMPLATE_LIST);
//     return <>
//         {(commonTemplatesData && commonTemplatesData.commonTemplateList) ?
//             commonTemplatesData.commonTemplateList.map((template) => (
//                 <div key={template.id} className={'col-6 col-sm-4 blue-hover'}>
//                     <img className={'w-100'} src={template.img} onClick={() => {
//                         let nodeToAdd = JSON.parse(template.content);
//                         addNewIdForChildNodes(nodeToAdd);
//                         addNodeToActiveNode(dispatch, activeNode, nodeToAdd);
//                         Modal.getOrCreateInstance('#edit-block-modal').hide();
//                     }} alt={'heading block'} data-bs-dismiss="modal"/>
//                 </div>
//             )) : ''
//         }
//     </>
// }
//
// export default CommonTemplates


import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    addNewIdForChildNodes,
    addNodeAfterActiveNode,
    addNodeToActiveNode,
    changeDataBSTargetId
} from "../../../service/NodeService";
import {Modal} from "bootstrap";
import {RootState} from "../../../store";
import {PageNode} from "../../../types/PageNode";
import {Template} from "../../../graphql/types";
import {changeNewNode} from "../../../reducers/app";

interface IncomeProps {
    template: Template,
}

const CommonTemplate: React.FC<IncomeProps> = ({template}) => {
    const activeNode = useSelector((state:RootState) => state.nodeModal.value)
    const dispatch = useDispatch();
    const srcImage = process.env.REACT_APP_IMAGE_ADDRESS + template.img
    const nodes = useSelector((state: RootState) => state.nodes.present.value);
    return <>
        <div key={template.id} className='col-lg-3 col-6 ps-1 p-1 mb-2' data-bs-dismiss="modal"
             onClick={() => {
                 let nodeToAdd:PageNode = JSON.parse(template.content);
                 nodeToAdd.template = {id: template.id,type:template.type,isCommon:true}
                 if (activeNode && activeNode.parentId === '1') {
                     const idArray = addNewIdForChildNodes(nodeToAdd, '1');
                     changeDataBSTargetId(nodeToAdd, idArray)
                     addNodeAfterActiveNode(dispatch, activeNode, nodeToAdd, nodes)
                 } else {
                     const idArray = addNewIdForChildNodes(nodeToAdd);
                     changeDataBSTargetId(nodeToAdd, idArray)
                     addNodeToActiveNode(dispatch, activeNode, nodeToAdd);
                 }
                 dispatch(changeNewNode(nodeToAdd))
                 Modal.getOrCreateInstance('#edit-block-modal').hide();
             }}>
            <div className="blue-hover rounded-2 h-100 d-flex align-items-start justify-content-center">
                <img className={"w-100 rounded-2"} src={srcImage} alt={'heading block'}/>
            </div>
        </div>
    </>
}


export default CommonTemplate
