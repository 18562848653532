import {ScreenShotProcessor} from "./ScreenShotService";
import {ServiceContainer} from "../ServiceContainer";
import QueryService from "../QueryService";
import {ApolloClient, NormalizedCacheObject} from "@apollo/client";
import {PageNode} from "../../types/PageNode";
import {UserTemplateListDocument} from "../../components/context-menu/Template/UserTemplate/UserTemplates.hooks";
import {TemplateScreenShotService} from "./TemplateScreenShotService";
import {
    UpdateTemplateDocument, UpdateTemplateMutation, UpdateTemplateMutationVariables
} from "../../components/context-menu/Template/UpdateTemplate.hooks";

export class UpdateTemplateScreenShotProcessor implements ScreenShotProcessor {
    private apolloClient: ApolloClient<NormalizedCacheObject>;
    private node: PageNode;

    public constructor(node: PageNode, apolloClient: ApolloClient<NormalizedCacheObject>) {
        this.apolloClient = apolloClient
        this.node = node;
    }

    getNodeId(): string {
        return this.node.id
    }

    onScreenShotAction(): Promise<string> {
        return TemplateScreenShotService.takeTemplateScreenShot(this.node.id)
    }

    onScreenShotReady(result: string): Promise<string> {
        const showLog = ServiceContainer.resolve<QueryService>('QueryService').isDevMode
        return new Promise((resolve) => {
            this.apolloClient.mutate<UpdateTemplateMutation, UpdateTemplateMutationVariables>({
                mutation: UpdateTemplateDocument, refetchQueries: [{query: UserTemplateListDocument}], variables: {
                    id: this.node.template.id, content: JSON.stringify(this.node), img: result,

                }
            })
                .then(value => {
                    return resolve(value.data.updateTemplate.img)
                })
                .catch(reason => {
                    if (showLog) {
                        console.error("Ошибка отправки скриншота на сервер: ", reason);
                    }
                })

        })
    }

}