import React from 'react';
import {PageNode} from "../../../types/PageNode";

interface DynamicElementProps extends React.HTMLAttributes<HTMLElement> {
    node: PageNode,
    children?: React.ReactNode
}

const DynamicElement: React.FC<DynamicElementProps> = ({
                                                           node,
                                                           children,
                                                           ...props
                                                       }) => {
    const tagName= node.tagName ? node.tagName.trim() : 'div'

    if(props.id === "blockContent1") {
        const className = `${props.className ? props.className + ' ' : ''}min-vh-100`;
        props.className = className;
    }
    return React.createElement(tagName, props, children);
};

export default DynamicElement;