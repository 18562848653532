// const TMP => () => {}
const Iframe = ({ editableNode, changeFunction }) => {
  return (
    <div className="form-floating mb-3">
      <input
        id={"edit-block-modal-iframe"}
        onChange={(e) => {
            let value = e.target.value;
            value = value.replace('https://youtube.com/shorts/', 'https://www.youtube.com/embed/')
            value = value.replace('https://www.youtube.com/watch?v=4cm9ZCbtWCU', 'https://www.youtube.com/embed/');
            editableNode.value = value.replace('https://youtu.be/', 'https://www.youtube.com/embed/');
          changeFunction(editableNode);
        }}
        defaultValue={editableNode.value}
        placeholder={"Enter your text"}
        type="text"
        className={"form-control"}
      />
      <label htmlFor="edit-block-modal-iframe" className="form-label">
          Video Link
      </label>
    </div>
  );
};

export default Iframe;
