import React from 'react'
import {
    ChangeBackClassesFunction, ChangeStyleFunction,
    DeleteBackClassesByNameFunction,
    DeleteCustomStyleByNameFunction
} from "../../../../service/editModalService";
import {PageNode} from "../../../../types/PageNode";
import {AlignTypes} from "./AlignTypes";

interface InputProperty {
    value: string,
    isMobileEditMode: boolean,
    name: string,
    type: string,
    iconValue: string,
    htmlFor: string,
    editableNode: PageNode,
    changeByValueFunction: ChangeBackClassesFunction | ChangeStyleFunction,
    deleteFunction: DeleteBackClassesByNameFunction | DeleteCustomStyleByNameFunction,
    id: string
}

export const AlignmentInput: React.FC<InputProperty> = ({
                                                            value,
                                                            isMobileEditMode,
                                                            name,
                                                            type,
                                                            iconValue,
                                                            htmlFor,
                                                            editableNode,
                                                            changeByValueFunction,
                                                            deleteFunction,
                                                            id
                                                        }) => {
    let selected = false
    let alignObject: CustomStyle;
    if (isMobileEditMode) {
        if (type === AlignTypes.Class) {
            alignObject = editableNode.backClassesMobile
        } else if (type === AlignTypes.Style) {
            alignObject = editableNode.customStyleMobile
        }

    } else {
        if (type === AlignTypes.Class) {
            alignObject = editableNode.backClassesIndexed
        } else if (type === AlignTypes.Style) {
            alignObject = editableNode.customStyle
        }
    }

    if (alignObject && alignObject[name] === value) {
        selected = true
    }
    let styleBorder = {background: '#ffffff', padding: '0.375rem 0.75rem', color: '#3A83F6'}
    const handleClickAlignment = (e: React.ChangeEvent<HTMLInputElement>) => {
        // if (!id) {
        if (selected) {
            deleteFunction(e.target.name, isMobileEditMode)
        } else {
            changeByValueFunction(e.target.name, e.target.value, isMobileEditMode)
        }
        // } else {
        //     if (selected === classValue) {
        //         deleteFunction(e.target.name)
        //     } else  {
        //         changeFunction(e.target.name)
        //     }
        // }
    }

    const htmlForWithId = id ? `${htmlFor}-${id}` : htmlFor;

    return (
        <div>
            <input type="radio" className="btn-check"
                   id={htmlForWithId}
                   name={name}
                   onChange={handleClickAlignment}
                   value={value}/>
            {/*<label className="btn" htmlFor={htmlForWithId} style={selected === classValue ? styleBorder : null}>*/}
            {/*    <i className={iconValue}></i>*/}
            <label className="btn border-0" htmlFor={htmlForWithId} style={selected ? styleBorder : {
                background: '#ffffff',
                padding: '0.375rem 0.75rem',
                color: '#8B929A'
            }}>
                <i className={iconValue}></i>
            </label>
        </div>
    )
}

