import React from 'react';
import {structureColData} from "../../../../data/colData";
import StructureItem from "./structureItem";


const Structure = ({nodeState, changeFormFunction}) => {
    return (
        <div>
            <h5 className={'mt-3'}>Structure</h5>
            <div className={'row pb-3 px-3'}>
                {structureColData.map(({id, structureColImg}) => (
                    <StructureItem key={id} id={id} structureColImg={structureColImg} nodeState={nodeState} changeFormFunction={changeFormFunction}/>
                ))
                }
            </div>
        </div>
    )
}

export default Structure