import { FileManagerFile } from "../service/FileManager/FileManagerFile";

export interface OnClickAction {
    type: OnClickActionType;
    value: any;
}

export interface OnClickActionFile extends OnClickAction {
    fileObject: FileManagerFile;
    base64Vcard?: string;
}

export enum OnClickActionType {
    OpenLink = 0,
    Page = 1,
    WriteEmail = 2,
    CallPhone = 3,
    OpenFile = 4,
    SaveVcard = 5
}