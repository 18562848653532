import React from "react";
import {useSelector} from "react-redux";
import {ServiceContainer} from "../../service/ServiceContainer";
import {ScreenShotService} from "../../service/ScreenShot/ScreenShotService";
import {PageNode} from "../../types/PageNode";
import {RootState} from "../../store";
import {NewTemplateScreenShotProcessor} from "../../service/ScreenShot/NewTemplateScreenShotProcessor";
import {apolloClient} from "../../index";
import QueryService from "../../service/QueryService";
import {UserRole} from "../../types/User";

interface InputProps {
    activeNode: PageNode
}

const ExportNode: React.FC<InputProps> = ({activeNode}) => {
    const type = useSelector((state: RootState) => state.appStorage.type)
    const token = ServiceContainer.resolve<QueryService>('QueryService').token
    const user = useSelector((state: RootState) => state.appStorage.user);

    const isAdmin = user ? user.roles.includes(UserRole.ROLE_ADMIN) : false
    const handlerExportNode = () => {
        const screenShotService = ServiceContainer.resolve<ScreenShotService>('ScreenShotService')
        screenShotService.runScreenShot(new NewTemplateScreenShotProcessor(type, activeNode, apolloClient, isAdmin))
    };
    if (token)
        return <></>

    return (
        <>
            <button type="button" className="text-start btn btn-sm btn-primary w-100"
                    onClick={(e) => {
                        e.preventDefault();
                        handlerExportNode();
                    }}
            >
                <i className="bi bi-file-richtext me-2"></i>Save as Template
            </button>
        </>
    );
};
export default ExportNode;