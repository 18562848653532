import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Modal} from "bootstrap";
import {changeActiveModalNode} from "../../../reducers/node-modal";
import {updateProductInCookie} from "../../../service/CookieAddService";
import {changeShoppingCart, setShoppingCart} from "../../../reducers/shopping-modal";
import {useLocation, useNavigate} from "react-router-dom";


const ShoppingCartModal = () => {
    const shoppingCart = useSelector((state) => state.shoppingModal.value);
    const [sumShopping, setSumShopping] = useState(0)
    useEffect(() => {
        // eslint-disable-next-line array-callback-return
        setSumShopping(shoppingCart.reduce((acc, sum) => {
            return acc+Number(sum.productPrice * sum.productQuantity)
        }, 0))
    }, [shoppingCart])

    const dispatch = useDispatch();
    const modalRef = React.useRef();

    const handleClickOutside = useCallback((e) => {
        if (e.target.classList.contains('modal') || e.target.id==="closing-shopping-modal-window") {
            e.stopPropagation()
            dispatch(changeActiveModalNode(null));
            Modal.getOrCreateInstance('#shopping-cart-modal').hide();
        }
    }, [dispatch]);

    const deleteCookies = (product) => {
        const productList = updateProductInCookie({'productName': product.productName}, true);
        dispatch(setShoppingCart(productList));
    }

    const increaseCount = (product) => {
        const productList = updateProductInCookie({
            'productName': product.productName,
            "productPrice": product.productPrice,
            "productSrc": product.productSrc,
            "productQuantity": product.productQuantity ? String(Number(product.productQuantity)+1): '2'
        });
        dispatch(setShoppingCart(productList));
    }
    const decreaseCount = (product) => {
        const productList = updateProductInCookie({
            'productName': product.productName,
            "productPrice": product.productPrice,
            "productSrc": product.productSrc,
            "productQuantity": product.productQuantity ? String(Number(product.productQuantity)-1): '0'
        });
        dispatch(setShoppingCart(productList));
    }

    const handleQuantityChange = (e, product) => {
        const inputValue = e.target.value
        const isValidNumber = /^\d+$/.test(Number(inputValue));
        if (isValidNumber) {
            const productList = updateProductInCookie({
                'productName': product.productName,
                "productPrice": product.productPrice,
                "productSrc": product.productSrc,
                "productQuantity": inputValue
            });
            dispatch(setShoppingCart(productList));
        }
    }

    const navigate = useNavigate();
    const location = useLocation();
    const handleClickCheckout = () => {
        if (shoppingCart.length) {
            Modal.getOrCreateInstance('#shopping-cart-modal').hide();
            navigate(`${location.pathname}/checkout`);
        }
    }

    useEffect(() => {
        const modalElement = modalRef.current;
        if (modalElement) {
            modalElement.addEventListener('mousedown', handleClickOutside);

            return () => {
                modalElement.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [handleClickOutside]);

    return <div className="modal modal-lg" id="shopping-cart-modal" tabIndex="-1" ref={modalRef}>
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header border-0">
                    <h5 className="modal-title mb-0">Shopping Cart</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => dispatch(changeActiveModalNode(null))}></button>
                </div>
                <div className="modal-body">
                    <div className="container">
                        {!shoppingCart ? <></>:
                            shoppingCart.map(product => (
                                <div key={product.productName+Date.now()} className="d-flex justify-content-between mb-5">
                                    <div className="col-md-3 d-flex">
                                        <div className="border flex-shrink-0"
                                             style={{height:'125px', width:'125px', backgroundImage: `url("${product.productSrc}")`, backgroundSize:'cover'}}></div>
                                        <div className="d-flex align-items-start justify-content-between flex-column m-3">
                                            <h5>{product.productName}</h5>
                                            <div className="d-flex align-items-center col-md-3">
                                                <i className="bi bi-dash" onClick={()=>decreaseCount(product)}></i>
                                                <input type="text"
                                                       className="form-control input-number"
                                                       style={{width:'40px'}}
                                                       value={product.productQuantity ?? '1'}
                                                       onChange={(e)=>handleQuantityChange(e, product)}/>
                                                <i className="bi bi-plus" onClick={()=>increaseCount(product)}></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-auto d-flex align-items-end justify-content-between flex-column">
                                        <i className="btn bi bi-trash mb-auto"
                                           onClick={() => {
                                               deleteCookies(product)
                                           }
                                           }></i>
                                        <p className='me-2'>$ <span>{product.productPrice}</span></p>
                                    </div>
                                </div> ))
                        }
                    </div>
                </div>
                <div className="p-3 m-2 border-top">
                    <div className="d-flex justify-content-between ms-2 me-2 mb-2">
                        <div className="h4">Total</div>
                        <div className="h3">$ <span>{sumShopping}</span></div>
                    </div>
                    <div className="d-flex buttons-close-continue justify-content-between flex-column flex-md-row gap-2">
                        <div id="closing-shopping-modal-window" className="btn btn-outline-secondary ps-5 pe-5" onClick={handleClickOutside}>Continue Shopping</div>
                        <div className="btn btn-secondary ps-5 pe-5" onClick={handleClickCheckout}>Checkout</div>
                    </div>
                </div>
            </div>
        </div>

    </div>;
}

export default ShoppingCartModal
