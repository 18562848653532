import React, { useEffect, useRef } from "react";

const DynamicHeadContent = ({ path }) => {
    const elementRef = useRef(null);

    useEffect(() => {
        if (!path) return;

        let element;

        if (path.endsWith(".css")) {
            element = (
                <link ref={elementRef} rel="stylesheet" href={path} />
            );
        } else if (path.endsWith(".js")) {
            element = (
                <script ref={elementRef} src={path} defer />
            );
        } else {
            return;
        }

        return () => {
            if (elementRef.current) {
                elementRef.current.remove();
            }
        };
    }, [path]);

    if (path.endsWith(".css")) {
        return <link ref={elementRef} rel="stylesheet" href={path} />;
    } else if (path.endsWith(".js")) {
        return <script ref={elementRef} src={path} defer />;
    } else {
        return null;
    }
};

export default DynamicHeadContent;
