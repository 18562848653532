import React, {useEffect} from "react";
import Node from "../node/node";
import {ServiceContainer} from "../../service/ServiceContainer";
import {ScreenShotService} from "../../service/ScreenShot/ScreenShotService";
import {useSelector} from "react-redux";
import {RootState} from "../../store";

interface InputProps {
}

const ScreenShotNodeComponent: React.FC<InputProps> = () => {
    const nodes = useSelector((state: RootState) => state.nodes.present.value)
    const renderNodeId = useSelector((state: RootState) => state.appStorage.screenShotNodeId)
    useEffect(() => {
        if (renderNodeId !== undefined) {
            const screenShotService = ServiceContainer.resolve<ScreenShotService>('ScreenShotService')
            screenShotService.onScreenShotAction().then(result => {
                if (result) {
                    screenShotService.onScreenShotReady(result)
                }
            });
        }
    }, [renderNodeId]);
    if(!nodes || nodes.length===0)
    {
        return <></>
    }
    return <>
        <div id={'screen-shot-container'} className='opacity-0'
             style={{zIndex: -100, position: "fixed", top: '-9999px'}}>
            <Node isPreviewState={true} additionalClasses={['position-fixed', 'w-100', '']} node={nodes[0]}/>
        </div>
    </>
}
export default ScreenShotNodeComponent