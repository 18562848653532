import React from 'react';
import QRCode from "react-qr-code";
const QrCode = ({url}) => {
    return (
        <div style={{ height: "209px", margin: "0 auto", maxWidth: 209, width: "209px" }}>
            <QRCode
                size={256}
                style={{ height: "209px", maxWidth: "100%", width: "209px" }}
                value={url}
                viewBox={`0 0 256 256`}
                fgColor='#FFFFFF'
                bgColor='#818892'
            />
        </div>

    )
}

export default QrCode