import React, { useState } from "react";
import SelectColor from "../../../EditModal/components/SelectColor";
import { CustomStyleField } from "../../../../types/CustomStyleField";
import { PageNode } from "../../../../types/PageNode";
import { ChangeNodeFunction } from "../../../../service/editModalService";

interface FontColorPickerModuleProps {
  editableNode: PageNode;
  changeNodeFunction: ChangeNodeFunction;
  labelName: string;
}

const FontColorPickerModule: React.FC<FontColorPickerModuleProps> = ({
  editableNode,
  changeNodeFunction,
  labelName,
}) => {
  const [showBlock, setShowBLock] = useState<string>("");

  const changeStyleFunction = (styleName: string, color: string): void => {
    changeNodeFunction({
      ...editableNode,
      customStyle: { ...editableNode.customStyle, [styleName]: color },
    });
  };

  const deleteStyleByName = (styleName: string): void => {
    changeNodeFunction({
      ...editableNode,
      customStyle: { ...editableNode.customStyle, [styleName]: undefined },
    });
  };

  return (
    <div className="mb-3">
      <SelectColor
        editableNode={editableNode}
        styleName={CustomStyleField.Color}
        labelName={labelName}
        showBlock={showBlock}
        setShowBLock={setShowBLock}
        changeStyleFunction={changeStyleFunction}
        deleteStyleByName={deleteStyleByName}
        isMobileStyle={false}
      />
    </div>
  );
};

export default FontColorPickerModule;
