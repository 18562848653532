import React, {useState} from "react";
import QrCode from "../../service/qrCode";

export default function MobileView({isMobileViewDimension, isMobilePreview, isMobileDisplayMode, previewState, isAdvancedMode}) {

    const [openQr, setOpenQr] = useState(true)

    let borderMobile = null
    if (isMobileDisplayMode && !isMobileViewDimension && !isMobilePreview) {
        borderMobile = {
            border: '10px solid #353F4B',
            borderRadius: '40px'
        }
    }

    let url = window.location.href.replace('#', '');
    return <>
        <div className='bg-background-mobile'>
            <div className='container py-5'>
                <div className={'row align-items-center'}>
                    {openQr ?
                        <>
                            <div className={'col-md-8 text-center'}>
                                <iframe style={borderMobile} title={'mobile view'} src={url + `?mobile=true&preview=${previewState}&advanced=${isAdvancedMode}`} width={'396px'} height={'813px'}/>
                            </div>
                            <div className={'col-md-4'}>
                                <div className='bg-qr-code-background rounded-3 py-2 px-3'>
                                    <div className='text-end'>
                                        <button type='button' className='btn px-2' onClick={() => setOpenQr(false)}><i className="bi bi-x-lg text-white"></i></button>
                                    </div>
                                    <div className='text-white text-center'>
                                        <h3><b>Scan to сontinue
                                            <br/>
                                            on mobile</b></h3>
                                    </div>
                                    <div className='text-center pt-2 pb-5'>
                                       <QrCode url={url}/>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <div className={'col-12 text-center'}>
                            <iframe style={borderMobile} title={'mobile view'} src={url + `?mobile=true&preview=${previewState}&advanced=${isAdvancedMode}`} width={'396px'} height={'813px'}/>
                        </div>
                    }
                </div>
            </div>
        </div>
    </>
}

