import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    addNewIdForChildNodes,
    addNodeAfterActiveNode,
    addNodeToActiveNode,
    changeDataBSTargetId
} from "../../../../service/NodeService";
import {useMutation} from "@apollo/client";
import {TEMPLATE_LIST} from "../../../../apollo/Queries";
import {REMOVE_TEMPLATE} from "../../../../apollo/Mutations";
import {Modal} from "bootstrap";
import {RootState} from "../../../../store";
import {TemplateDimensionType} from "../../../../types/TemplateDimensionType";
import {PageNode} from "../../../../types/PageNode";
import {Template} from "../../../../graphql/types";
import {useUserTemplateListQuery} from "./UserTemplates.hooks";
import {changeNewNode} from "../../../../reducers/app";

// const TMP => () => {}
const UserTemplates = () => {
    const activeNode = useSelector((state: RootState) => state.nodeModal.value)
    const dispatch = useDispatch();
    const {data: templatesData} = useUserTemplateListQuery();
    const type = useSelector((state: RootState) => state.appStorage.type)
    const nodes = useSelector((state: RootState) => state.nodes.present.value);
    const [addTemplate] = useMutation(REMOVE_TEMPLATE, {refetchQueries: [{query: TEMPLATE_LIST}]});
    const removeTemplateAction = (id: number): void => {
        addTemplate({
            variables: {
                id: id
            }
        }).then(() => alert('Template Removed')).catch((e) => {
            alert(e);
        });
    }

    return <>
        {(templatesData && templatesData.templateList) ?
            templatesData.templateList.map((template: Template) => (
                template.type === type || template.type === TemplateDimensionType.CommonTemplate ?
                <div key={template.id} className={'mb-2 blue-hover rounded-2 user-template-container'}>
                    <div className={'user-template-cross text-center bg-white px-1 rounded-5 position-absolute'}
                         onClick={() => {
                             removeTemplateAction(template.id);
                         }}>
                        <button className='btn-close'></button>
                    </div>
                    <div className='h-100 w-100 d-flex align-items-center justify-content-center'
                         data-bs-dismiss="modal"
                         onClick={() => {
                             let nodeToAdd: PageNode = JSON.parse(template.content);
                             nodeToAdd.template = {id: template.id,type:template.type,isCommon:false}
                             if (activeNode && activeNode.parentId === '1') {
                                 const idArray = addNewIdForChildNodes(nodeToAdd, '1');
                                 changeDataBSTargetId(nodeToAdd, idArray)
                                 addNodeAfterActiveNode(dispatch, activeNode, nodeToAdd, nodes)
                             } else {
                                 const idArray = addNewIdForChildNodes(nodeToAdd);
                                 changeDataBSTargetId(nodeToAdd, idArray)
                                 addNodeToActiveNode(dispatch, activeNode, nodeToAdd);
                             }
                             dispatch(changeNewNode(nodeToAdd))
                             Modal.getOrCreateInstance('#edit-block-modal').hide();
                         }}
                    >
                        <img className={'user-template-sub-container'} src={process.env.REACT_APP_IMAGE_ADDRESS + template.img} alt={'heading block'}/>
                    </div>
                </div> : <></>
            )) : <></>
        }
    </>
}

export default UserTemplates
