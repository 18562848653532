/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetPageDocument = gql`
    query GetPage($id: Int!) {
  page(id: $id) {
    id
    title
    seoTitle
    seoDescription
    type
    contentString
    parentTemplate {
      id
      templates {
        id
        type
        content
        img
      }
    }
    user {
      userName
      roles
      email
    }
    userAccesses {
      accessType
      user {
        email
        roles
        userName
      }
    }
  }
}
    `;

/**
 * __useGetPageQuery__
 *
 * To run a query within a React component, call `useGetPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPageQuery(baseOptions: Apollo.QueryHookOptions<GetPageQuery, GetPageQueryVariables> & ({ variables: GetPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPageQuery, GetPageQueryVariables>(GetPageDocument, options);
      }
export function useGetPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPageQuery, GetPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPageQuery, GetPageQueryVariables>(GetPageDocument, options);
        }
export function useGetPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPageQuery, GetPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPageQuery, GetPageQueryVariables>(GetPageDocument, options);
        }
export type GetPageQueryHookResult = ReturnType<typeof useGetPageQuery>;
export type GetPageLazyQueryHookResult = ReturnType<typeof useGetPageLazyQuery>;
export type GetPageSuspenseQueryHookResult = ReturnType<typeof useGetPageSuspenseQuery>;
export type GetPageQueryResult = Apollo.QueryResult<GetPageQuery, GetPageQueryVariables>;
export const UpdatePageSeoDataDocument = gql`
    mutation UpdatePageSeoData($id: Int!, $seoTitle: String, $seoDescription: String) {
  updatePage(id: $id, seoTitle: $seoTitle, seoDescription: $seoDescription) {
    seoTitle
    seoDescription
  }
}
    `;
export type UpdatePageSeoDataMutationFn = Apollo.MutationFunction<UpdatePageSeoDataMutation, UpdatePageSeoDataMutationVariables>;

/**
 * __useUpdatePageSeoDataMutation__
 *
 * To run a mutation, you first call `useUpdatePageSeoDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageSeoDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageSeoDataMutation, { data, loading, error }] = useUpdatePageSeoDataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      seoTitle: // value for 'seoTitle'
 *      seoDescription: // value for 'seoDescription'
 *   },
 * });
 */
export function useUpdatePageSeoDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePageSeoDataMutation, UpdatePageSeoDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePageSeoDataMutation, UpdatePageSeoDataMutationVariables>(UpdatePageSeoDataDocument, options);
      }
export type UpdatePageSeoDataMutationHookResult = ReturnType<typeof useUpdatePageSeoDataMutation>;
export type UpdatePageSeoDataMutationResult = Apollo.MutationResult<UpdatePageSeoDataMutation>;
export type UpdatePageSeoDataMutationOptions = Apollo.BaseMutationOptions<UpdatePageSeoDataMutation, UpdatePageSeoDataMutationVariables>;
export type GetPageQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetPageQuery = { __typename?: 'Query', page: { __typename?: 'GraphPage', id?: number | null, title?: string | null, seoTitle?: string | null, seoDescription?: string | null, type?: number | null, contentString: string, parentTemplate?: { __typename?: 'GraphPage', id?: number | null, templates: Array<{ __typename?: 'GraphTemplate', id?: number | null, type?: number | null, content?: string | null, img?: string | null }> } | null, user: { __typename?: 'User', userName: string, roles: Array<string>, email?: string | null }, userAccesses: Array<{ __typename?: 'UserAccess', accessType?: number | null, user?: { __typename?: 'User', email?: string | null, roles: Array<string>, userName: string } | null }> } };

export type UpdatePageSeoDataMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  seoTitle?: Types.InputMaybe<Types.Scalars['String']['input']>;
  seoDescription?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type UpdatePageSeoDataMutation = { __typename?: 'Mutation', updatePage: { __typename?: 'GraphPage', seoTitle?: string | null, seoDescription?: string | null } };
