import React from 'react';
import {DivColNode} from "../../../types/PageNode";
import {ChangeNodeFunction} from "../../../service/editModalService";


interface ContainerColSizeItemProperty {
    editableNode: DivColNode,
    id: number,
    containerColImg: string,
    changeNodeFunction: ChangeNodeFunction,
    isMobileStyleContainerColSize: boolean,
}

const ContainerColSizeItem: React.FC<ContainerColSizeItemProperty> = ({
                                                                          editableNode,
                                                                          containerColImg,
                                                                          id,
                                                                          changeNodeFunction,
                                                                          isMobileStyleContainerColSize,
                                                                      }) => {
    const borderStyle = {border: '1px solid #3A83F6', background: '#F2F7FF'}
    const svgColor = {filter: 'invert(85%) sepia(7%) saturate(1094%) hue-rotate(170deg) brightness(324%) contrast(79%)'}
    let selected = false
    if (isMobileStyleContainerColSize) {
        selected = editableNode.colSizeMobile ? editableNode.colSizeMobile === id : editableNode.colSize ? editableNode.colSize === id : false
    } else {
        selected = editableNode.colSize ? editableNode.colSize === id : false
    }
    const onCLickHandler = (value: number) => {
        if (isMobileStyleContainerColSize) {
            editableNode.colSizeMobile = value
        } else {
            editableNode.colSize = value
        }
        changeNodeFunction(editableNode)
    }
    return (
        <div className={"col-4 col-sm-2 p-0"}>
            {!selected ? (
                <div className={'bg-light rounded-1 text-center m-2'}
                     onClick={() => {
                         onCLickHandler(id)
                     }}>
                    <img className={'w-100 mt-3 mb-3 p-2'} src={containerColImg} alt=''/>
                </div>
            ) : (
                <div className={'bg-light rounded-1 text-center m-2'} style={borderStyle}>
                    <img className={'w-100 mt-3 mb-3 p-2'} src={containerColImg} style={svgColor} alt=''/>
                </div>)}
        </div>
    )

}

export default ContainerColSizeItem