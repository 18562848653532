import {createSlice} from "@reduxjs/toolkit";

export const activeContainerNode = createSlice({
    name: 'activeModal',
    initialState: {
        activeContainerNode: null
    },
    reducers: {
        changeActiveContainerNode: (state, action) => {
            state.activeContainerNode = action.payload;
        }
    }
})
export const {changeActiveContainerNode} = activeContainerNode.actions
export default activeContainerNode.reducer;