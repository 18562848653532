import {createSlice} from "@reduxjs/toolkit";

export const nodeModal = createSlice({
    name: 'nodeModal',
    initialState: {
        value: null
    },
    reducers: {
        changeActiveModalNode: (state, action) => {
            state.value = action.payload;
        }
    }
})
export const {changeActiveModalNode} = nodeModal.actions
export default nodeModal.reducer;