import React from 'react';
import Styles from "../../context-menu/block/modules/Styles";
import Classes from "../../context-menu/block/modules/Classes";
import {ChangeNodeFunction} from "../../../service/editModalService";
import {PageNode} from "../../../types/PageNode";

interface AdditionalSettingsProps {
    changeNodeFunction: ChangeNodeFunction,
    editableNode: PageNode,
}
const AdditionalSettings: React.FC<AdditionalSettingsProps> = ({changeNodeFunction, editableNode}) => {
    return (
        <>
            <h5 className="mt-4 mb-3">Additional Settings</h5>
            <Styles changeFunction={changeNodeFunction} editableNode={editableNode} />
            <Classes changeFunction={changeNodeFunction} editableNode={editableNode}/>
        </>
    )
}

export default AdditionalSettings;