/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const UserTemplateListDocument = gql`
    query UserTemplateList {
  templateList {
    id
    img
    type
    content
  }
}
    `;

/**
 * __useUserTemplateListQuery__
 *
 * To run a query within a React component, call `useUserTemplateListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTemplateListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTemplateListQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserTemplateListQuery(baseOptions?: Apollo.QueryHookOptions<UserTemplateListQuery, UserTemplateListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserTemplateListQuery, UserTemplateListQueryVariables>(UserTemplateListDocument, options);
      }
export function useUserTemplateListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTemplateListQuery, UserTemplateListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserTemplateListQuery, UserTemplateListQueryVariables>(UserTemplateListDocument, options);
        }
export function useUserTemplateListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserTemplateListQuery, UserTemplateListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserTemplateListQuery, UserTemplateListQueryVariables>(UserTemplateListDocument, options);
        }
export type UserTemplateListQueryHookResult = ReturnType<typeof useUserTemplateListQuery>;
export type UserTemplateListLazyQueryHookResult = ReturnType<typeof useUserTemplateListLazyQuery>;
export type UserTemplateListSuspenseQueryHookResult = ReturnType<typeof useUserTemplateListSuspenseQuery>;
export type UserTemplateListQueryResult = Apollo.QueryResult<UserTemplateListQuery, UserTemplateListQueryVariables>;
export type UserTemplateListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserTemplateListQuery = { __typename?: 'Query', templateList: Array<{ __typename?: 'Template', id?: number | null, img?: string | null, type?: number | null, content?: string | null }> };
