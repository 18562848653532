import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
    name: "modal",
    initialState: {
        activeModal: null,
    },
    reducers: {
        openModal: (state, action) => {
            state.activeModal = action.payload.modalName;
        },
        closeModal: (state) => {
            state.activeModal = null;
        },
    },
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;