import React from 'react'
import {ImageNode} from "../../../types/PageNode";

interface ImageBlockProps {
    styleObject: CustomStyle,
    nodeToShow: ImageNode,
    classString: string,
    handleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    handleDoubleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    isMobileViewDimension: boolean,
    nodeAttributes: { [p: string]: any }
}

const IconBlock: React.FC<ImageBlockProps> = ({
                                                  styleObject,
                                                  nodeToShow,
                                                  classString,
                                                  handleClick,
                                                  handleDoubleClick,
                                                  nodeAttributes
                                              }) => {


    return (<>
        {!nodeToShow.selectedIcon && nodeToShow.src ?
            <img
                id={'blockContent' + nodeToShow.id}
                onClick={handleClick}
                onDoubleClick={handleDoubleClick}
                src={nodeToShow.src}
                alt={nodeToShow.alt !== undefined ? nodeToShow.alt : ''}
                className={classString}
                style={styleObject}
            />
            :
            <i {...nodeAttributes}
               onClick={handleClick}
               onDoubleClick={handleDoubleClick}
               className={`${classString} bi-${nodeToShow.selectedIcon ? nodeToShow.selectedIcon : 'circle'}`}
               style={styleObject}
               id={'blockContent' + nodeToShow.id}
            ></i>
        }


    </>)
}

export default IconBlock
