import {GetMeDocument, GetMeQuery, GetMeQueryVariables} from "./UserService.hooks";
import {SymfonyUserInterface, UserAccess} from "../../graphql/types";
import {UserRole} from "../../types/User";
import {ApolloClient, NormalizedCacheObject} from "@apollo/client";


export default class UserService {
    get user(): SymfonyUserInterface {
        return this._user;
    }

    private static instance: UserService
    private _user: SymfonyUserInterface

    public init(apolloClient: ApolloClient<NormalizedCacheObject>): Promise<UserService> {
        return new Promise((resolve, reject) => {
            apolloClient.query<GetMeQuery, GetMeQueryVariables>({query: GetMeDocument}).then(value => {
                this._user = value.data.me
                resolve(this)
            }).catch(reason => {
                reject(reason)
            })
        })
    }

    public static getInstance() {
        if (UserService.instance === undefined) {
            UserService.instance = new UserService();
        }
        return UserService.instance;
    }

    public canEditPage(pageEmail: string, userAccesses: UserAccess[]) {

        let userAccess;
        if (userAccesses.length) {
            userAccess = userAccesses.find((el: UserAccess) => el.user.email === this.user.userName)
        }

        let projectUser;
        if (userAccesses.length) {
            projectUser = userAccesses.find((el: UserAccess) => el.user.email === this.user.userName)
        }

        const isAdmin = this.user.roles.includes(UserRole.ROLE_ADMIN);

        return !((userAccess && userAccess.accessType === 2) || (projectUser && projectUser.accessType === 2) || this.user.userName === pageEmail || isAdmin);
    }
}