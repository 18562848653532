// const TMP => () => {}
const Lists = ({ editedNode, changeFunction }) => {
  let node = { ...editedNode };

  return (
    <div className="form-floating mb-3">
      <input
        id={"edit-block-modal-text-input"}
        onChange={(e) => {
          node.value = e.target.value;
          changeFunction(node);
        }}
        defaultValue={node.value}
        placeholder={"Enter your text"}
        type="text"
        className={"form-control"}
      />
      <label htmlFor="edit-block-modal-text-input" className="form-label">
        Inner Text
      </label>
    </div>
  );
};

export default Lists;
