import {useSelector} from "react-redux";
import {Modal} from "bootstrap";

const CartCircle = () => {
    const shoppingCart = useSelector(state => state.shoppingModal.value)
    return (
        <div className='cursor-pointer cart-circle-div position-fixed bg-white rounded-5 d-flex align-items-center justify-content-center shadow-lg'
             style={{}}
             onClick={() => Modal.getOrCreateInstance('#shopping-cart-modal').show()}>
            <h3 className='bi bi-cart m-0'></h3>
            <div className="position-fixed rounded-5 bg-primary d-flex align-items-center justify-content-center text-white small"
                 style={{top:'77px', right:'23px', height:'18px', padding:'5px'}}>
                {shoppingCart.reduce((acc, sum) => {
                    return acc + Number(sum.productQuantity)
                }, 0)}
            </div>
        </div>
    );
};
export default CartCircle