import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../reducers/modalSlice";
import { addLink, removeLink } from "../../../reducers/app";

export default function CssLinkModal() {
  const dispatch = useDispatch();
  const links = useSelector((state) => state.appStorage.links);
  const activeModalName = useSelector((state) => state.modal.activeModal);

  const isVisible = activeModalName === "css-link-modal";

  const [link, setLink] = useState("");

  const handleChange = (e) => {
    setLink(e.target.value);
  };

  const handleRemoveLink = (index) => {
    dispatch(removeLink(index));
  };

  useEffect(() => {
    if (isVisible) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isVisible]);

  const handleClickOutside = (e) => {
    if (e.target === e.currentTarget) {
      dispatch(closeModal())
    }
  };

  return (
      <>
        {isVisible && <div className="modal-backdrop show"></div>}
        <div
            className={`modal ${isVisible ? "show" : ""}`}
            id="css-link-modal"
            tabIndex="-1"
            style={{ display: isVisible ? "block" : "none" }}
            onClick={handleClickOutside}
            role={isVisible ? "dialog" : ""}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">CSS Link</h5>
                <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={() => dispatch(closeModal())}
                ></button>
              </div>
              <div className="modal-body">
                <input
                    type="text"
                    value={link}
                    onChange={handleChange}
                    placeholder="Enter css link or script link"
                />
                <ul className="link-list">
                  {links.map((linkItem, index) => (
                      <li key={index} className="link-item">
                        {linkItem}
                        <button
                            type="button"
                            className="btn btn-sm btn-danger"
                            onClick={() => handleRemoveLink(index)}
                        >
                          Remove
                        </button>
                      </li>
                  ))}
                </ul>
              </div>
              <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => dispatch(closeModal())}
                >
                  Close
                </button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      if (link.trim()) {
                        dispatch(addLink(link));
                        setLink("");
                      }
                      dispatch(closeModal());
                    }}
                >
                  Save <span className="d-none d-md-inline">Changes</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}
