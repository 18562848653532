import html2canvas from "html2canvas";
import {AppDispatch} from "../../store";
import {changeScreenShotNodeId} from "../../reducers/app";
import {ServiceInstance} from "../ServiceContainer";

export interface ScreenShotProcessor {
    onScreenShotReady(result: string): Promise<string>

    getNodeId(): string

    onScreenShotAction(): Promise<string>
}

export class ScreenShotService implements ServiceInstance {
    screenShotProcessor: ScreenShotProcessor;
    private dispatch: AppDispatch;

    public constructor(dispatch: AppDispatch) {
        this.dispatch = dispatch
    }

    runScreenShot(screenShotProcessor: ScreenShotProcessor) {
        this.screenShotProcessor = screenShotProcessor
        this.dispatch(changeScreenShotNodeId(screenShotProcessor.getNodeId()))
    }

    onScreenShotAction(): Promise<string> {
        return this.screenShotProcessor.onScreenShotAction()
    }

    onScreenShotReady(result: string): void {
        this.screenShotProcessor.onScreenShotReady(result).then(value => {
            this.dispatch(changeScreenShotNodeId(undefined))
        });
    }
    static  takeMobileScreenShot(element: HTMLElement) {
        return ScreenShotService.takeScreenshotOfElement(element, 400, 865, element.clientWidth, 865)
    }

    static takeTemplateScreenShot(element: HTMLElement) {
        return ScreenShotService.takeScreenshotOfElement(element, element.clientWidth, element.clientHeight, element.clientWidth, element.clientHeight)
    }

    static takePCScreenShot(element: HTMLElement) {
        const windowWidth = window.innerWidth
        const windowHeight = window.innerHeight
        let width = 400;
        let height = 400 / (windowWidth / windowHeight);
        if (window.innerWidth < window.innerHeight) {
            width = 400;
            height = 285;
        }
        return ScreenShotService.takeScreenshotOfElement(element, width, height, undefined, undefined)
    }

    static takeScreenshotOfElement = async (element: HTMLElement, endImageWidth: number, endImageHeight: number, inImageWidth: number, inImageHeight: number) => {
        try {
            const canvas = await html2canvas(element, {
                useCORS: true,
                width: inImageWidth,
                height: inImageHeight
            });

            const newCanvas = document.createElement('canvas');
            newCanvas.width = endImageWidth;
            newCanvas.height = endImageHeight;

            const newCtx = newCanvas.getContext('2d');
            newCtx.drawImage(canvas, 0, 0, endImageWidth, endImageHeight);
            return newCanvas.toDataURL("image/jpeg", 0.6);
        } catch (error) {
            console.error("Ошибка создания скриншота: ", error);
            return null;
        }
    };
}