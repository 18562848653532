import React from 'react';
import {ButtonNode} from "../../../types/PageNode";
import {CustomStyleField} from "../../../types/CustomStyleField";
import OnClickActionWrapper from "../Wrapper/OnClickActionWrapper";

interface ButtonBlockProperty {
    nodeToShow: ButtonNode,
    styleObject: any,
    handleClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
    isMobileViewDimension: boolean,
    className: string,
    handleDoubleClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

export const ButtonBlock: React.FC<ButtonBlockProperty> = ({
                                                           nodeToShow,
                                                           styleObject,
                                                           handleClick,
                                                           className,
                                                           handleDoubleClick
                                                       }) => {
    const value = nodeToShow[CustomStyleField.Value] ?? 'Add your custom text'

    return (<>
        <OnClickActionWrapper nodeToShow={nodeToShow}>
            <div
            onClick={handleClick}
            onDoubleClick={handleDoubleClick}
            id={'blockContent' + nodeToShow.id}
            className={className}
            style={styleObject}
                // target={(nodeToShow[CustomStyleField.Target] !== undefined) ? nodeToShow[CustomStyleField.Target] : undefined}
            dangerouslySetInnerHTML={{__html: value}}
            ></div>
        </OnClickActionWrapper>
    </>);
};

export default ButtonBlock;
