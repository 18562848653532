import containerColImg1 from "../assets/col-size-img/col1.svg"
import containerColImg2 from "../assets/col-size-img/col2.svg"
import containerColImg3 from "../assets/col-size-img/col3.svg"
import containerColImg4 from "../assets/col-size-img/col4.svg"
import containerColImg5 from "../assets/col-size-img/col5.svg"
import containerColImg6 from "../assets/col-size-img/col6.svg"
import containerColImg7 from "../assets/col-size-img/col7.svg"
import containerColImg8 from "../assets/col-size-img/col8.svg"
import containerColImg9 from "../assets/col-size-img/col9.svg"
import containerColImg10 from "../assets/col-size-img/col10.svg"
import containerColImg11 from "../assets/col-size-img/col11.svg"
import containerColImg12 from "../assets/col-size-img/col12.svg"

import structureColImg1 from "../assets/images/col1.svg";
import structureColImg2 from "../assets/images/col2.svg";
import structureColImg3 from "../assets/images/col3.svg";
import structureColImg4 from "../assets/images/col4.svg";
import structureColImg6 from "../assets/images/col6.svg";
import structureColImg12 from "../assets/images/col12.svg";

const containerColData = [
    {id: 12, containerColImg: containerColImg12},
    {id: 11, containerColImg: containerColImg11},
    {id: 10, containerColImg: containerColImg10},
    {id: 9, containerColImg: containerColImg9},
    {id: 8, containerColImg: containerColImg8},
    {id: 7, containerColImg: containerColImg7},
    {id: 6, containerColImg: containerColImg6},
    {id: 5, containerColImg: containerColImg5},
    {id: 4, containerColImg: containerColImg4},
    {id: 3, containerColImg: containerColImg3},
    {id: 2, containerColImg: containerColImg2},
    {id: 1, containerColImg: containerColImg1}
]

const structureColData = [
    {id: 1, structureColImg: structureColImg12},
    {id: 2, structureColImg: structureColImg6},
    {id: 3, structureColImg: structureColImg4},
    {id: 4, structureColImg: structureColImg3},
    {id: 6, structureColImg: structureColImg2},
    {id: 12, structureColImg: structureColImg1},
]

export {containerColData, structureColData}