function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

// Функция для получения cookie по имени
function getCookie(name) {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(cookieName) === 0) {
            return cookie.substring(cookieName.length, cookie.length);
        }
    }
    return null;
}

function updateProductInCookie(productData, remove = false) {
    if (productData === '*') {
        setCookie("productList", "", -1);
        return [];
    }
    let productList = getCookie("productList");
    if (productList) {
        productList = JSON.parse(productList);
        if (remove) {
            productList = productList.filter(product => product.productName !== productData.productName);
        } else {
            const existingProductIndex = productList.findIndex(product => product.productName === productData.productName);
            if (existingProductIndex !== -1) {
                productList[existingProductIndex] = productData;
            } else {
                productList.push(productData);
            }
        }
    } else {
        productList = [productData];
    }

    const productListString = JSON.stringify(productList);
    setCookie("productList", productListString, 30);
    return productList
}

export {
    setCookie,
    getCookie,
    updateProductInCookie
}