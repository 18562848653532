import ProductList from "./productList";
import stripe from '../../assets/icons/shoppingIcons/stripe.svg'
import paypal from '../../assets/icons/shoppingIcons/paypal.svg'

const FormCheckout = ({submit, inputsValues, setInputsValues, navigate, params}) => {

    const handleInputChange = (e) => {
        const {value, name, type} = e.target
        if (type === 'radio') {
            setInputsValues({...inputsValues, paymentMethod: name})

        } else {
            setInputsValues({...inputsValues, [name]: value})
        }
    }

    return (
        <form className="" onSubmit={submit}>
            <div className="row g-3 mb-5">
                <h5>Contact Information</h5>
                <div className="col-12 form-floating">
                    <input type="email" className="form-control" id="email" placeholder="Email" value={inputsValues.email}
                           required="required" name="email" onChange={handleInputChange}/>
                    <label className="opacity-50" htmlFor="email">Email</label>
                </div>
            </div>
            <div className="row g-3 mb-5">
                <h5>Shipping Address</h5>
                <div className="col-sm-6 form-floating">
                    <input type="text" className="form-control" id="firstName" placeholder="First name" value={inputsValues.firstName}
                           required="required" name="firstName" onChange={handleInputChange}/>
                    <label className="opacity-50" htmlFor="firstName">First Name</label>
                </div>
                <div className="col-sm-6 form-floating">
                    <input type="text" className="form-control" id="lastName" placeholder="Last name" value={inputsValues.lastName}
                           required="required" name="lastName" onChange={handleInputChange}/>
                    <label className="opacity-50" htmlFor="lastName">Last Name</label>
                </div>
                <div className="col-12 form-floating">
                    <input type="text" className="form-control" id="company" placeholder="Company" value={inputsValues.company}
                           required="required" name="company" onChange={handleInputChange}/>
                    <label className="opacity-50" htmlFor="company">Company</label>
                </div>
                <div className="col-12 form-floating">
                    <input type="text" className="form-control" id="address" placeholder="Address" value={inputsValues.address}
                           required="required" name="address" onChange={handleInputChange}/>
                    <label className="opacity-50" htmlFor="address">Address</label>
                </div>
                <div className="col-12 form-floating">
                    <input type="text" className="form-control" id="apartments" placeholder="Apartment, suite, etc" value={inputsValues.apartments}
                           required="required" name="apartments" onChange={handleInputChange}/>
                    <label className="opacity-50" htmlFor="apartments">Apartment, suite, etc</label>
                </div>
                <div className="col-md-4 form-floating">
                    <select className="form-select form-control" id="country" required="required" name="country"
                            value={inputsValues.country} onChange={handleInputChange} >
                        <option value="">Select Country</option>
                        <option>United States</option>
                    </select>
                    <label htmlFor="country" className="form-label">Country</label>
                </div>
                <div className="col-md-4 form-floating">
                    <select className="form-select " id="city" required="required" placeholder="City" name="city"
                            value={inputsValues.city} onChange={handleInputChange}>
                        <option value="">Select City</option>
                        <option>United States</option>
                    </select>
                    <label htmlFor="city" className="form-label">City</label>
                </div>

                <div className="col-sm-4 form-floating">
                    <input type="text" className="form-control" id="zip" placeholder="Zip" value={inputsValues.zip}
                           required="required" name="zip" onChange={handleInputChange}/>
                    <label className="opacity-50" htmlFor="Zip">Zip</label>
                </div>
            </div>

            <div className="row g-3 mb-5">
                <h5>Payment Method</h5>
                <div className="list-group">
                    <div className="d-flex form-check list-group-item p-3 ps-5 justify-content-between align-items-center">
                        <div>
                            <input className="form-check-input" type="radio" name="paypal" id="paypal"
                                   checked={inputsValues.paymentMethod === 'paypal'} onChange={handleInputChange}/>
                            <label htmlFor="paypal">PayPal</label>
                        </div>
                        <img src={paypal} alt="PayPal"/>
                    </div>
                    <div className="d-flex form-check list-group-item p-3 ps-5 justify-content-between align-items-center">
                        <div>
                            <input className="form-check-input" type="radio" name="stripe" id="stripe" onChange={handleInputChange}
                                   checked={inputsValues.paymentMethod === 'stripe'}/>
                            <label htmlFor="stripe">Stripe</label>
                        </div>
                        <img src={stripe} alt="Stripe"/>
                    </div>
                </div>
            </div>
            <div className="d-block d-md-none mb-2 border-top pt-4 pt-md-0"><ProductList params={params}/></div>
            <div className="d-flex justify-content-between align-items-center flex-column-reverse flex-md-row">
                <div className="cursor-pointer d-flex col-md-4 col-12 align-items-center justify-content-center" onClick={() => navigate(-1)}>
                    <i className="bi bi-chevron-left"></i>
                    <p className="mb-0">Back to shopping</p>
                </div>
                <button className='btn payButton col-md-4 col-11 btn-dark p-2 mb-md-0 mb-2'>Pay now</button>
            </div>
        </form>
    );
};

export default FormCheckout