import React from 'react';
import ColorPickerModule from "./ColorPickerModule";
import SelectBackground from "./SelectBackground";
import ImgFill from "./ImgFill";
import {
    ChangeNodeFunction,
    ChangeStyleFunction,
    DeleteCustomStyleByNameFunction
} from "../../../service/editModalService";
import {CustomStyleField} from "../../../types/CustomStyleField";
import {MobileStyleRadioButton} from "../ui/MobileStyleRadioButton";
import {ImageNode} from "../../../types/PageNode";

export interface BackgroundSettingsProps {
    changeStyleFunction: ChangeStyleFunction,
    deleteStyleByName: DeleteCustomStyleByNameFunction,
    isMobileViewDimension: boolean,
    editableNode: ImageNode,
    changeNodeFunction: ChangeNodeFunction
}

const BackgroundSettings: React.FC<BackgroundSettingsProps> = ({
                                               changeStyleFunction,
                                               deleteStyleByName,
                                               isMobileViewDimension,
                                               editableNode,
                                               changeNodeFunction
                                                               }) => {
    let isMobileStyleBackground: boolean = false
    if (isMobileViewDimension) {
        isMobileStyleBackground = editableNode.isMobileStyleBackground
    }

    return (
        <>
            <div className="d-flex">
                <h5 className={'mt-3 mb-3 me-auto'}>Background</h5>
                <div className="mt-3 mb-3">
                    <MobileStyleRadioButton currentStatus={isMobileStyleBackground}
                                            onChange={(state) => {
                                                editableNode.isMobileStyleBackground = state;
                                                changeNodeFunction(editableNode)
                                            }}/>
                </div>
            </div>
            <div className="row mb-2 g-3">
                <div className="col-md-6 mb-3">
                    <SelectBackground
                                      editableNode={editableNode} isMobileViewDimension={isMobileViewDimension}
                                      deleteStyleByName={deleteStyleByName} isMobileStyle={isMobileStyleBackground}
                                      changeNodeFunction={changeNodeFunction}/>
                </div>
                <div className="col-md-6">
                    <ColorPickerModule changeNodeFunction={changeNodeFunction}
                                       editableNode={editableNode}
                                       isMobileStyle={isMobileStyleBackground}/>
                </div>
                <div className="col-md-3">
                    <ImgFill deleteStyleByName={deleteStyleByName} changeStyleFunction={changeStyleFunction}
                             labelName={"Cover / (Contain, Auto)"} styleName={CustomStyleField.BackgroundSize}
                             editableNode={editableNode} isMobileStyle={isMobileStyleBackground}/>
                </div>
            </div>
        </>
    )
}

export default BackgroundSettings;