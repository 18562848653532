import React, {useEffect, useState} from "react";
import {addNodeImage} from "../../../../../service/imageUploadService";
import ImageUploadButton from "./ImageUploadButton"
import ColorPickerModule from "../../../../EditModal/components/ColorPickerModule";
import ImgFill from "../../../../EditModal/components/ImgFill";
import ImgSettings from "../../../../EditModal/components/ImgSettings";
import {Tooltip} from 'bootstrap';
import {useSelector} from "react-redux";
import {RootState} from "../../../../../store";
import {
    ChangeBackClassesFunction,
    ChangeNodeFunction,
    ChangeStyleFunction,
    DeleteBackClassesByNameFunction,
    DeleteCustomStyleByNameFunction
} from "../../../../../service/editModalService";
import {CustomStyleField} from "../../../../../types/CustomStyleField";
import {MobileStyleRadioButton} from "../../../../EditModal/ui/MobileStyleRadioButton";
import {ImageNode, PageNode} from "../../../../../types/PageNode";

interface ImageUploadProps {
    editableNode: ImageNode,
    changeNodeFunction: ChangeNodeFunction,
    changeFormFunction: (e: any) => void,
    deleteStyleByName: DeleteCustomStyleByNameFunction,
    changeBackClassesByValue: ChangeBackClassesFunction,
    isMobileViewDimension: boolean,
    deleteBackClassesFunction: DeleteBackClassesByNameFunction
    changeStyleFunction: ChangeStyleFunction
}

const ImageUpload: React.FC<ImageUploadProps> = ({
                                                     editableNode,
                                                     changeNodeFunction,
                                                     changeFormFunction,
                                                     deleteStyleByName,
                                                     changeBackClassesByValue,
                                                     isMobileViewDimension,
                                                     deleteBackClassesFunction,
                                                     changeStyleFunction
                                                 }) => {

    const isAdvancedMode = useSelector((state: RootState) => state.appStorage.isAdvancedMode)

    let isMobileStyleImage: boolean = false
    if (isMobileViewDimension) {
        isMobileStyleImage = editableNode.isMobileStyleImage
    }


    const [altValue, setAltValue] = useState(isMobileStyleImage ? editableNode.altMobile ? editableNode.altMobile : '' : editableNode.alt ? editableNode.alt : '');
    useEffect(() => {
        let alt = isMobileStyleImage ? editableNode.altMobile ?? '' : editableNode.alt ?? ''
        setAltValue(alt);
    }, [editableNode.alt, editableNode.altMobile, isMobileStyleImage]);


    let loading
    if (isMobileStyleImage) {
        if (editableNode.loadingMobile === undefined) {
            editableNode.loadingMobile = 'lazy';
        }
        loading = editableNode.loadingMobile
    } else {
        if (editableNode.loading === undefined) {
            editableNode.loading = 'lazy';
        }
        loading = editableNode.loading
    }

    let imgType = editableNode.backClassesIndexed
    if (isMobileStyleImage) {
        imgType = editableNode.backClassesMobile
    }

    if (Object.keys(editableNode.backClassesIndexed).length === 0 && editableNode.tagName === 'img' && !isMobileStyleImage) {
        changeBackClassesByValue('imgFluid', 'img-fluid', isMobileStyleImage)
    }

    if (Object.keys(editableNode.backClassesMobile).length === 0 && editableNode.tagName === 'img' && isMobileStyleImage) {
        changeBackClassesByValue('imgFluid', 'img-fluid', isMobileStyleImage)
    }


    const handleChangeLoading = (node: React.ChangeEvent<HTMLInputElement>) => {
        if (!node.target.checked) {
            node.target.value = null
        }
        changeFormFunction(node)
    }
    const handleChangeImgFluid = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.checked) {
            e.target.value = null
        }
        changeBackClassesByValue(e.target.name, e.target.value, isMobileStyleImage)
        if (e.target.value === null) {
            deleteBackClassesFunction(e.target.name, isMobileStyleImage)
        }
    }
    const changeImageAction = (editedNode: PageNode) => {
        changeNodeFunction(editedNode);
    }

    let con = editableNode && editableNode.src
    if (isMobileStyleImage) {
        con = editableNode && editableNode.srcMobile
    }


    useEffect(() => {
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.map(function (tooltipTriggerEl: any) {
            return new Tooltip(tooltipTriggerEl);
        });
    }, []);

    return <>
        <div>
            <div className={'d-flex'}>
                <h5 className={'mb-3 me-auto'}>Image</h5>
                <MobileStyleRadioButton currentStatus={isMobileStyleImage}
                                        onChange={(state) => {
                                            editableNode.isMobileStyleImage = state;
                                            changeNodeFunction(editableNode)
                                        }}/>
            </div>
            <div className={`${isAdvancedMode ? 'mb-3' : ''} row gx-3`}>
                <div className={`${isAdvancedMode ? 'col-md-6' : 'col-12'}`}>
                    <ImageUploadButton addNodeImage={addNodeImage} editableNode={editableNode}
                                       changeFunctionImage={changeImageAction}
                                       con={con}
                                       changeNodeFunction={changeNodeFunction}
                                       deleteStyleByName={deleteStyleByName}
                                       isMobileStyle={isMobileStyleImage}
                                       isMobileViewDimension={isMobileViewDimension}

                    />
                </div>
                {isAdvancedMode && <>
                    <div className="col-md-6">
                        <ColorPickerModule
                            changeNodeFunction={changeNodeFunction}
                            editableNode={editableNode}
                            isMobileStyle={isMobileStyleImage}/>
                    </div>
                    <div className="col-md-3 mb-3 mb-md-0">
                        <ImgFill
                            labelName={"Fill / (Fit, Crop)"}
                            styleName={CustomStyleField.ObjectFit} changeStyleFunction={changeStyleFunction}
                            deleteStyleByName={deleteStyleByName} editableNode={editableNode}
                            isMobileStyle={isMobileStyleImage}/>
                    </div>
                    <div className="col-md-3">
                        <ImgSettings changeStyleFunction={changeStyleFunction}
                                     deleteStyleByName={deleteStyleByName}
                                     editableNode={editableNode}
                                     isMobileStyleImage={isMobileStyleImage} id={undefined}/>
                    </div>
                </>}
            </div>
            {isAdvancedMode && <>
                <div className="mt-2 mb-3">
                    <div className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckFluid"
                            checked={!!imgType['imgFluid']}
                            name={CustomStyleField.ImgFluid}
                            value="img-fluid"
                            onChange={handleChangeImgFluid}
                        />
                        <label className="form-check-label fs-7" htmlFor={'flexCheckFluid'}>
                            Full Size
                        </label>

                        <label className="btn p-0 border-0 form-check-label ms-2" data-bs-toggle="tooltip"
                               data-bs-placement="right" data-bs-title="full screen width"
                               data-bs-custom-class="tooltip-custom">
                            <i className="bi bi-question-circle text-blue"></i>
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckDefault"
                            checked={!!loading}
                            name={isMobileStyleImage ? CustomStyleField.LoadingMobile : CustomStyleField.Loading}
                            value="lazy"
                            onChange={handleChangeLoading}
                        />
                        <label className="form-check-label fs-7" htmlFor={'flexCheckDefault'}>
                            Lazy Load
                        </label>

                        <label className="btn p-0 border-0 form-check-label ms-2" data-bs-toggle="tooltip"
                               data-bs-placement="right" data-bs-title="load content only when it becomes visible"
                               data-bs-custom-class="tooltip-custom">
                            <i className="bi bi-question-circle text-blue"></i>
                        </label>
                    </div>
                </div>
                <div className="form-floating mb-3">
                    <input
                        id={"edit-block-modal-alt"}
                        onChange={(e) => {
                            if (isMobileStyleImage) {
                                editableNode.altMobile = e.target.value;
                            } else {
                                editableNode.alt = e.target.value;
                            }
                            setAltValue(e.target.value);
                            changeNodeFunction(editableNode);
                        }}
                        type="text"
                        placeholder={"alt"}
                        value={altValue}
                        className={"form-control"}
                    />
                    <label htmlFor="edit-block-modal-alt" className="text-background-mobile form-label">
                        Image alt
                    </label>
                </div>
            </>}
        </div>

    </>

}

export default ImageUpload
