import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Modal} from "bootstrap";
import {DivColNode, NodeType} from "../../../types/PageNode";
import {RootState} from "../../../store";
import {NodeRenderService} from "../../../service/NodeRenderService";
import Node from "../node";
import CustomStyles from "../../../service/CustomsStyles";
import {changeActiveModalNode} from "../../../reducers/node-modal";
import {findNodeWhichAreContainer} from "../../../service/EditBarService";
import {changeActiveContainerNode} from "../../../reducers/active-container-node";
import OnClickActionWrapper from "../Wrapper/OnClickActionWrapper";

interface InputProps {
    node: DivColNode
    isPreviewState: boolean
}

export const DivColNodeComponent: React.FC<InputProps> = ({node, isPreviewState}) => {
    let nodeToShow: DivColNode = {...node};
    const isAdvancedMode = useSelector((state: RootState) => state.appStorage.isAdvancedMode);
    const nodes = useSelector((state: RootState) => state.nodes.present.value);
    const isMobileViewDimension = useSelector((state: RootState) => state.appStorage.isMobileViewDimension)
    const activeNode = useSelector((state: RootState) => state.nodeModal.value)
    const activeContainerNode = useSelector((state: RootState) => state.activeContainerNode.activeContainerNode);

    const [div, setDiv] = useState(document.querySelector(`#blockContent${nodeToShow.id}`))

    useEffect(() => {
        if (div === null) {
            setDiv(document.querySelector(`#blockContent${nodeToShow.id}`))
        }
    }, [div, nodeToShow])

    let nodeStyle = NodeRenderService.getStyle(nodeToShow);

    const dispatch = useDispatch();

    let classes = NodeRenderService.getClasses(nodeToShow, activeNode, activeContainerNode, isPreviewState, isAdvancedMode);

    if (node.isMobileStyleContainerColSize) {
        classes.push('col-' + node.colSizeMobile)
    } else {
        classes.push('col-' + node.colSize)
    }
    classes.push('col-md-' + node.colSize)

    const className = classes.join(' ')

    // TODO: MOve to service
    function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (!isPreviewState) {
            event.preventDefault()
        }
        if (isAdvancedMode || !nodeToShow.isHidden) {
            event.stopPropagation();
            dispatch(changeActiveModalNode(nodeToShow));
            const parentNode = findNodeWhichAreContainer(nodeToShow, nodes[0], 'row');
            dispatch(changeActiveContainerNode(parentNode));
        }
    }

    function handleDoubleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        event.stopPropagation();
        Modal.getOrCreateInstance('#edit-block-modal').show();
    }

    let innerValue: React.JSX.Element | string = node.nodes.length === 0 && !isPreviewState ? NodeRenderService.getInnerValue(nodeToShow, dispatch) : ''

    return <>
        <div key={nodeToShow.id}

             onClick={e => handleClick(e)}
             onDoubleClick={handleDoubleClick}
             id={'blockContent' + nodeToShow.id}
             className={(className)}
             style={nodeStyle}
        >
            {innerValue}
            {(nodeToShow.nodes !== undefined && nodeToShow.nodes.length > 0) ? nodeToShow.nodes.map((childNode) => {
                if (childNode)
                    if (childNode.type === NodeType.DivColNode) {
                        return <OnClickActionWrapper nodeToShow={nodeToShow}>
                            <DivColNodeComponent
                                isPreviewState={isPreviewState}
                                node={childNode as DivColNode}/>
                        </OnClickActionWrapper>
                    } else {
                        return <Node isPreviewState={isPreviewState} key={childNode.id} node={childNode}/>;
                    }
                return '';
            }) : ''}
        </div>
        <CustomStyles node={node} isMobileViewDimension={isMobileViewDimension}/>
    </>
        ;

}
