import React from "react";
import {addNodeImage} from "../../../service/imageUploadService";
import {
    ChangeNodeFunction, changeStyleByValue,
    deleteCustomStyleByName,
    DeleteCustomStyleByNameFunction
} from "../../../service/editModalService";
import ImageUploadButton from "../../context-menu/block/modules/image-upload/ImageUploadButton";
import {CustomStyleField} from "../../../types/CustomStyleField";
import {ImageNode} from "../../../types/PageNode";

interface SelectBackgroundProps {
    editableNode: ImageNode,
    isMobileStyle: boolean,
    changeNodeFunction: ChangeNodeFunction
    deleteStyleByName: DeleteCustomStyleByNameFunction,
    isMobileViewDimension: boolean
}

const SelectBackground: React.FC<SelectBackgroundProps> = ({
                                                               editableNode,
                                                               isMobileStyle,
                                                               changeNodeFunction,
                                                               deleteStyleByName,
                                                               isMobileViewDimension
                                                           }) => {
    let nodeCustomStyle: CustomStyle | undefined;
    if (editableNode) {
        if (isMobileStyle) {
            nodeCustomStyle = editableNode.customStyleMobile
        } else {
            nodeCustomStyle = editableNode.customStyle
        }
    }

    const changeBackgroundStyle = (editedNode: ImageNode): void => {
        changeNodeFunction(editedNode)
        let tmpNode: ImageNode = editedNode
        if (isMobileStyle) {
            tmpNode = changeStyleByValue(
                CustomStyleField.BackgroundImage, `url(${tmpNode.srcMobile})`, tmpNode, isMobileStyle
            )
            if (Object.keys(tmpNode.customStyleMobile).includes(CustomStyleField.Background)) {
                tmpNode = deleteCustomStyleByName(CustomStyleField.Background, tmpNode, isMobileStyle)
            }
            if (Object.keys(tmpNode.customStyleMobile).includes(CustomStyleField.BackgroundColor)) {
                tmpNode = deleteCustomStyleByName(CustomStyleField.BackgroundColor, tmpNode, isMobileStyle)
            }
            if (tmpNode.srcMobile) {
                tmpNode.srcMobile = null
            }
        } else {
            tmpNode = changeStyleByValue(
                CustomStyleField.BackgroundImage, `url(${tmpNode.src})`, tmpNode, isMobileStyle
            )
            if (Object.keys(tmpNode.customStyle).includes(CustomStyleField.Background)) {
                tmpNode = deleteCustomStyleByName(CustomStyleField.Background, tmpNode, isMobileStyle)
            }
            if (Object.keys(tmpNode.customStyle).includes(CustomStyleField.BackgroundColor)) {
                tmpNode = deleteCustomStyleByName(CustomStyleField.BackgroundColor, tmpNode, isMobileStyle)
            }


        }
        changeNodeFunction(tmpNode)
    }

    let con = nodeCustomStyle && nodeCustomStyle[CustomStyleField.BackgroundImage]

    return (
        <>
            <ImageUploadButton addNodeImage={addNodeImage} editableNode={editableNode}
                               changeFunctionImage={changeBackgroundStyle}
                               con={con} isMobileViewDimension={isMobileViewDimension}
                               deleteStyleByName={deleteStyleByName} changeNodeFunction={changeNodeFunction}
                               isMobileStyle={isMobileStyle}/>
            {/*<label className="text-background-mobile mb-3">Support file formats: PNG, JPEG. </label>*/}
        </>
    )
}

export default SelectBackground