import React from "react";
import {containerColData} from "../../../data/colData";
import {MobileStyleRadioButton} from "../ui/MobileStyleRadioButton";
import {ChangeNodeFunction} from "../../../service/editModalService";
import ContainerColSizeItem from "./ContainerColSizeItem";
import {DivColNode} from "../../../types/PageNode";

interface ContainerColSizeProperty {
    editableNode: DivColNode,
    changeNodeFunction: ChangeNodeFunction,
}

export const ContainerColSize: React.FC<ContainerColSizeProperty> = ({
                                                                         editableNode,
                                                                         changeNodeFunction,
                                                                     }) => {
    const isMobileStyleContainerColSize = editableNode.isMobileStyleContainerColSize ? editableNode.isMobileStyleContainerColSize : false

    return (
        <div>
            <div className={'d-flex'}>
                <h5 className={'mb-3 me-auto'}>Container Size</h5>
                <MobileStyleRadioButton currentStatus={isMobileStyleContainerColSize}
                                        onChange={(state) => {
                                            editableNode.isMobileStyleContainerColSize = state;
                                            changeNodeFunction(editableNode)
                                        }}/>
            </div>

            <div className={'row pb-3 px-3'}>
                {containerColData.map(({id, containerColImg}) => (
                    <ContainerColSizeItem id={id} editableNode={editableNode} containerColImg={containerColImg}
                                          isMobileStyleContainerColSize={isMobileStyleContainerColSize}
                                          changeNodeFunction={changeNodeFunction}/>
                ))
                }
            </div>
        </div>
    )
}

export default ContainerColSize;