import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {changeIsExportState, changePreview} from "../../reducers/app";

function CantLoad({isExportState}) {
    const dispatch = useDispatch();
    useEffect(()=>{

        const blockContent = document.getElementById("mainBlock")
        if (blockContent !== null) {
            navigator.clipboard.writeText(blockContent.innerHTML).then(()=> {
                dispatch(changePreview(false))
                dispatch(changeIsExportState(false))
                alert("Copied to clipboard!");
            });
        } else {
            alert("Nothing to copy! Create something");
        }
    });
}

export default CantLoad;
