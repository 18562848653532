import {createSlice} from "@reduxjs/toolkit";
import {getCookie} from "../service/CookieAddService";
// @deprecated
export const shoppingModal = createSlice({
    name: 'shoppingModal',
    initialState: {
        value: [],
        personData: {}
    },
    reducers: {
        changeShoppingCart: (state) => {
            state.value = JSON.parse(getCookie("productList"));
        },
        setShoppingCart: (state, action) => {
            state.value = action.payload
        },
        setPersonData: (state, action) => {
            state.personData = action.payload
        }
    }
})
export const {changeShoppingCart, setShoppingCart, setPersonData} = shoppingModal.actions
export default shoppingModal.reducer;