// const TMP => () => {}
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeActiveModalNode} from "../../../../reducers/node-modal";

const ShowNodeChilds = ({activeNode, checkIsAdvancedMode}) => {
    const isAdvancedMode = useSelector((state) => state.appStorage.isAdvancedMode)
    const dispatch = useDispatch()

    // console.log(JSON.stringify(activeNode, null, 2));

    return (
        <>
            {(isAdvancedMode) &&
                <>

                    <h5 className={'mt-3'}>Inner tags:</h5>
                    <div className={'row pb-3 px-3'}>

                        <ul className={'ps-0'}>
                            {Object.values(activeNode.nodes).map((item, key, element) => (
                                <li key={key}
                                    onClick={() => {
                                        dispatch(changeActiveModalNode(item))
                                    }}
                                    className={'btn btn-outline-primary bg-btn-img-color me-1 mb-1'}
                                >{item.tagName} {item.classes.length ? `class="${item.classes.join(' ')}"`:
                                    (item.className ? `class="${item.className}"`: '')}</li>
                            ))}
                        </ul>

                    </div>
                </>
            }
        </>
    )
}

export default ShowNodeChilds
