import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeActiveModalNode} from "../../../../reducers/node-modal";
import {parentExistsFunc} from "../../../../service/EditBarService";
import {RootState} from "../../../../store";
import {PageNode} from "../../../../types/PageNode";

// const TMP => () => {}
interface ElementsNavProps {
    isModal: boolean
}

const ElementsNav: React.FC<ElementsNavProps> = ({isModal}) => {
    const activeNode:PageNode = useSelector((state: RootState) => state.nodeModal.value)
    const nodes = useSelector((state: RootState) => state.nodes.present.value);
    const isAdvancedMode = useSelector((state: RootState) => state.appStorage.isAdvancedMode)
    const dispatch = useDispatch()

    let elements: Array<PageNode> = [];
    let parentExists = true;

    parentExistsFunc(activeNode, nodes, parentExists, elements)

    return <> {
        elements.length >= 0 && isAdvancedMode &&
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb align-items-center">
                <span className={'pe-1'}>{isModal !== false ? 'Choose Active:' : ''} </span>
                {
                    elements.map((element: PageNode) => {
                        if (element.isHidden && !isAdvancedMode) {
                            return <></>
                        }

                        let braces: React.JSX.Element = <></>;

                        if (element.classes.length && element.classes && !element.classes.some(el => el === '')) {
                            braces = <>({element.classes.join(' ')})</>;
                        } else if (element.className) {
                            braces = <>({element.className})</>;
                        }
                        return <li key={element.id} className="breadcrumb-item" onClick={() => {
                            dispatch(changeActiveModalNode(element))
                        }}>
                            <span className="btn btn-link p-0">{element.tagName} {braces}</span>
                        </li>
                    })
                }
                {activeNode && activeNode.tagName ?
                    <li className="breadcrumb-item active"
                        aria-current="page">{activeNode.tagName} {activeNode.classes.join(' ') || activeNode.className}</li> :
                    <></>}

            </ol>
        </nav>
    }
    </>
}

export default ElementsNav
