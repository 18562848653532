import React from "react";
import EditBlockModal from "../block/edit-block-modal";

// const TMP => () => {}
interface ContextMenuModalsProperty {
    isAdvancedMode: boolean
}
const ContextMenuModals: React.FC<ContextMenuModalsProperty> = ({isAdvancedMode}) => {
    return <>
        <EditBlockModal isAdvancedMode={isAdvancedMode}/>
        {/*{activeModal === 'container-to-add-modal' && }*/}
    </>
}

export default ContextMenuModals