import React, {useRef} from "react";

interface FileUploaderProps {
    allowedFileTypes: string[];
    onSuccess: (file: File) => void;
    disabled?: boolean;
}

const FileUploadButton: React.FC<FileUploaderProps> = ({allowedFileTypes, onSuccess, disabled}) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if ((file && allowedFileTypes.map(el => file.type.includes(el.toLowerCase().slice(1)))) || (allowedFileTypes.length === 1 && allowedFileTypes[0] === '*')) {
            onSuccess(file);
            event.target.value = '';
        } else {
            alert('File type not allowed');
        }
    };

    return <>
        <div className="btn btn-primary text-white w-100 fw-bold p-2 py-md-3" onClick={handleButtonClick}><i
            className="bi bi-download"></i> Upload New File
        </div>
        <input
            type="file"
            className='d-none'
            ref={fileInputRef}
            onChange={handleFileChange}
            accept={allowedFileTypes.join(',')}
            disabled={disabled}/>
    </>
}
export default FileUploadButton;