/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddTemplateDocument = gql`
    mutation AddTemplate($content: String!, $img: String!, $type: Int!, $pageId: Int!) {
  addTemplate(content: $content, img: $img, type: $type, pageId: $pageId) {
    content
    img
  }
}
    `;
export type AddTemplateMutationFn = Apollo.MutationFunction<AddTemplateMutation, AddTemplateMutationVariables>;

/**
 * __useAddTemplateMutation__
 *
 * To run a mutation, you first call `useAddTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTemplateMutation, { data, loading, error }] = useAddTemplateMutation({
 *   variables: {
 *      content: // value for 'content'
 *      img: // value for 'img'
 *      type: // value for 'type'
 *      pageId: // value for 'pageId'
 *   },
 * });
 */
export function useAddTemplateMutation(baseOptions?: Apollo.MutationHookOptions<AddTemplateMutation, AddTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTemplateMutation, AddTemplateMutationVariables>(AddTemplateDocument, options);
      }
export type AddTemplateMutationHookResult = ReturnType<typeof useAddTemplateMutation>;
export type AddTemplateMutationResult = Apollo.MutationResult<AddTemplateMutation>;
export type AddTemplateMutationOptions = Apollo.BaseMutationOptions<AddTemplateMutation, AddTemplateMutationVariables>;
export type AddTemplateMutationVariables = Types.Exact<{
  content: Types.Scalars['String']['input'];
  img: Types.Scalars['String']['input'];
  type: Types.Scalars['Int']['input'];
  pageId: Types.Scalars['Int']['input'];
}>;


export type AddTemplateMutation = { __typename?: 'Mutation', addTemplate: { __typename?: 'GraphTemplate', content?: string | null, img?: string | null } };
